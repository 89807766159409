import styled from "@emotion/styled";
import { DeleteTwoTone, PhotoLibraryTwoTone } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { POST_DATA, POST_DATA_FILE, UploadFileAzure } from '../../../services/API';
import { useDispatch, useSelector } from 'react-redux';
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import UploadImagemDesign from "../../../components/UploadImagemDesign";
import { atualizarOnboardStore } from "../../../services/utils/functions";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useNavigate } from "react-router";

const UploadTesteiraContainer = styled.label`
  min-width: 350px;

  max-height: 500px;
  border-radius: 7px;
  overflow: hidden;
  padding: 1rem;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadTesteira = ({ isEditing }) => {
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const onBoard = useSelector((store) => store.onBoard);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [imgData, setImgData] = useState(null);
    const [usuarioEmpresa, setUsuarioEmpresa] = useState();
    const [isLoadingUsuarioEmpresa, setIsLoadingUsuarioEmpresa] = useState(false);

    const [imgLogoEmail, setImgLogoEmail] = useState(null);

    const fetchUsuarioEmpresa = async () => {
        try {
            setIsLoadingUsuarioEmpresa(true);

            const result = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
            setUsuarioEmpresa(result);

            if (result.sPathlogo != null) {
                const obj = {
                    id: 'logo_emial',
                    img: null,
                    url: result.sPathlogo
                }
                setImgLogoEmail(obj);
            }

            setIsLoadingUsuarioEmpresa(false);
        }
        catch (err) {
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err.message,
            });

            setIsLoadingUsuarioEmpresa(false);
        }
    };

    const handleUpdateTesteira = async () => {
        try {
            setIsLoadingUsuarioEmpresa(true);

            if (imgData !== null) {
                const extensao = imgData.name.split('.').pop();
                const pasta = `2024/UserEmpresa_${usuarioEmpresa.IdUsuario}`;
                const nommeArquivo = `testeiraEmail.${extensao}`;

                usuarioEmpresa.sPathBanner = await UploadFileAzure(imgData, pasta, nommeArquivo, `image/${extensao}`);

                await POST_DATA('UsuarioEmpresa/UpdateUsuarioEmpresa', usuarioEmpresa);
            }

            if (imgLogoEmail?.img != null && imgLogoEmail?.img != undefined) {
                const data = new FormData();
                data.append(`imagem`, imgLogoEmail.img);

                await POST_DATA_FILE(`UsuarioEmpresa/UpdateUsuarioEmpresaLogo?idUsuarioEmpresa=${usuarioEmpresa.IdUsuarioEmpresa}`, data);
            }

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: "Dados salvos com sucesso.",
            });

            fetchUsuarioEmpresa();

            setIsLoadingUsuarioEmpresa(false);

            if (usuarioEmpresa.EmailRemetente) {
                if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 6)[0].FlagStatus) { //Configure a comunicação da loja
                    const objOnboard = { ...onBoard };
                    objOnboard.IdUltimoOnboardingStoreItem = 6;

                    const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 6)[0];
                    itemOnboard.FlagStatus = true;

                    atualizarOnboardStore(objOnboard, itemOnboard);

                    dispatch({
                        type: SET_DADOS_ON_BOARD,
                        data: objOnboard
                    });

                    navigate('/on-board');
                }
            }
            else {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Para finalizar o processo, é necessário preencher o nome e e-mail do remetente',
                });
            }
        }
        catch (err) {
            setIsLoadingUsuarioEmpresa(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err.message,
            });
        }
    };

    useEffect(() => {
        (async () => {
            await fetchUsuarioEmpresa();
        })();
    }, []);

    return (
        <Card sx={{ width: "100%", p: 2 }}>
            {isLoadingUsuarioEmpresa ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={4}
                    justifyContent="center"
                >
                    <CircularProgress />
                    <Typography>Carregando...</Typography>
                </Stack>
            ) : (
                <>
                    {usuarioEmpresa && usuarioEmpresa?.sPathBanner ? (
                        <Stack direction="column" mb={1}>
                            <Typography variant="h6">Testeira atual:</Typography>
                            <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                <img
                                    style={{ width: '100%' }}
                                    src={usuarioEmpresa?.sPathBanner}
                                    alt="Digital banner"
                                />

                            </Box>
                        </Stack>
                    ) : (
                        <Alert severity="warning" sx={{ marginBottom: "1rem" }}>
                            <AlertTitle>Sem testeira</AlertTitle>
                            Você ainda não definiu nenhuma testeira para o envio de e-mails.
                            Faça o upload da imagem abaixo.
                        </Alert>
                    )}

                    <UploadTesteiraContainer htmlFor="file-upload">
                        <Typography variant="h6">Enviar imagem</Typography>
                        {imgData ? (
                            <Stack
                                direction="column"
                                justifyContent={"center"}
                                alignItems="center"
                            >
                                <img
                                    alt="not found"
                                    width={"100%"}
                                    style={{ height: "300px", borderRadius: "10px" }}
                                    src={isEditing ? imgData : URL.createObjectURL(imgData)}
                                />
                                <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                    <Chip
                                        label={`Arquivo: ${imgData.name}`}
                                        sx={{ mr: 1 }}
                                    ></Chip>
                                    <IconButton size="large" onClick={() => setImgData(null)}>
                                        <DeleteTwoTone />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        ) : (
                            <>
                                <PhotoLibraryTwoTone
                                    sx={{
                                        fontSize: "5rem",
                                        color: "lightpink",
                                        margin: "1rem 0",
                                    }}
                                />
                                <Typography variant="body2" textAlign={"center"}>
                                    Clique aqui para fazer o upload da testeira (1200x366px)
                                </Typography>
                                <input
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg, image/webp"
                                    id="file-upload"
                                    name="myImage"
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                        setImgData(event.target.files[0]);
                                    }}
                                    onClick={(e) => ((e.target).value = null)}
                                />
                            </>
                        )}
                    </UploadTesteiraContainer>

                    <Box style={{ width: 350, marginTop: 15 }}>
                        <Typography style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 2, marginTop: 5, textAlign: 'center' }}>Logo rodapé do email</Typography>
                        <UploadImagemDesign
                            dados={imgLogoEmail}
                            isEditing={true}
                            mobile={false}
                            key={'logo_email'}
                            id={'logo_emial'}
                            idComponent={`logo_email`}
                            dimensaoWidthImg={500}
                            dimensaoHeightImg={100}
                            imgData={imgLogoEmail?.url}
                            onChange={(value) => {
                                setImgLogoEmail(value);
                            }}
                            borderRadiusImg={0}
                            paddingTopImg={0}
                            widthImg='100%'
                            heightImg={140}
                            style={{ height: 'auto' }}
                            descricao={'Tamanho máximo 500 x 100'}
                        />
                    </Box>

                    <Stack direction="row" alignItems="center" gap={1} mt={1}>
                        <Button
                            variant="contained"
                            disabled={!imgData && imgLogoEmail?.img === null}
                            onClick={handleUpdateTesteira}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </>
            )}
        </Card>
    );
};

export default UploadTesteira;
