import {
    TEMA_LOJA_ADD_CARROSSEL_IMAGEM,
    TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG,
    TEMA_LOJA_GET_NEW,
    TEMA_LOJA_REMOVE_CARROSSEL_IMAGEM,
    TEMA_LOJA_SET,
    TEMA_LOJA_SET_ABOUT_US,
    TEMA_LOJA_SET_CAMPAIGNS,
    TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO,
    TEMA_LOJA_SET_CONFIGURACAO_ITEM,
    TEMA_LOJA_SET_DETALHES_PAGINA_PRODUTOS,
    TEMA_LOJA_SET_EXIBICAO_ITENS,
    TEMA_LOJA_SET_EXIBICAO_ITENS_MENU,
    TEMA_LOJA_SET_FALE_CONOSCO,
    TEMA_LOJA_SET_FOOTER,
    TEMA_LOJA_SET_GOOGLE_ANALITICS,
    TEMA_LOJA_SET_HEADER_MENSAGENS,
    TEMA_LOJA_SET_IMAGEM_PRODUTO,
    TEMA_LOJA_SET_LOGO,
    TEMA_LOJA_SET_MARKETING_SEO,
    TEMA_LOJA_SET_MENU,
    TEMA_LOJA_SET_OPTION_SITE,
    TEMA_LOJA_SET_POLITICAS,
    TEMA_LOJA_SET_THEME,
    TEMA_LOJA_SET_TIPO_LOGO,
    TEMA_LOJA_SET_TOP_PRODUTOS,
    TEMA_TEMA_SELECIONADO
} from './types';

export const INITIAL_STATE = null;

const temaLojaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEMA_LOJA_GET_NEW:
            return INITIAL_STATE;
        case TEMA_LOJA_SET:
            return action.data;
        case TEMA_LOJA_SET_THEME: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].theme = action.theme;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_CONFIGURACAO_ITEM: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].configuracaoItem = action.item;

            return newThemeStorage;
        }
        case TEMA_LOJA_SET_MENU: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].menu = action.opcoesMenu;

            return newThemeStorage;
        }
        case TEMA_LOJA_SET_LOGO: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].logo = action.logo;

            return newThemeStorage;
        }
        case TEMA_LOJA_SET_TIPO_LOGO: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].logo.tipo_logo = action.tipo_logo;

            return newThemeStorage;
        }
        case TEMA_LOJA_ADD_CARROSSEL_IMAGEM: {
            if (action.imagem === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.imagem !== undefined && action.imagem.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage][action.property] = action.imagem;
                return newThemeStorage;
            }

            const imagem = newThemeStorage.themes_store[indexThemeStorage][action.property].filter(a => a.id === action.imagem.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage][action.property].indexOf(imagem);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage][action.property][index] = action.imagem;
            } else {
                newThemeStorage.themes_store[indexThemeStorage][action.property].push(action.imagem);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
          
            const config = newThemeStorage.themes_store[indexThemeStorage][action.property].filter(a => a.type === 'config')[0];
            const index = newThemeStorage.themes_store[indexThemeStorage][action.property].indexOf(config);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage][action.property][index] = action.value;
            } else {
                newThemeStorage.themes_store[indexThemeStorage][action.property].push(action.value);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_REMOVE_CARROSSEL_IMAGEM: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].carrossel_images = newThemeStorage.themes_store[indexThemeStorage].carrossel_images.filter(a => a.id !== action.imagem.id);

            return newThemeStorage;
        }
        case TEMA_LOJA_SET_OPTION_SITE: {
            if (action.item === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.item !== undefined && action.item.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage].opcoes_site = action.item;
                return newThemeStorage;
            }

            const item = newThemeStorage.themes_store[indexThemeStorage].opcoes_site.filter(a => a.id === action.item.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage].opcoes_site.indexOf(item);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage].opcoes_site[index] = action.item;
            } else {
                newThemeStorage.themes_store[indexThemeStorage].opcoes_site.push(action.item);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_TOP_PRODUTOS: {
            if (action.produto === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.produto !== undefined && action.produto.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage].top_produtos = action.produto;
                return newThemeStorage;
            }

            const imagem = newThemeStorage.themes_store[indexThemeStorage].top_produtos.filter(a => a.id === action.produto.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage].top_produtos.indexOf(imagem);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage].top_produtos[index] = action.imagem;
            } else {
                newThemeStorage.themes_store[indexThemeStorage].top_produtos.push(action.imagem);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_EXIBICAO_ITENS: {
            if (action.item === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.item !== undefined && action.item.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage].exibicao_itens = action.item;
                return newThemeStorage;
            }

            const item = newThemeStorage.themes_store[indexThemeStorage].exibicao_itens.filter(a => a.id === action.item.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage].exibicao_itens.indexOf(item);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage].exibicao_itens[index] = action.item;
            } else {
                newThemeStorage.themes_store[indexThemeStorage].exibicao_itens.push(action.item);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_EXIBICAO_ITENS_MENU: {
            if (action.item === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.item !== undefined && action.item.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage].exibicao_itens_tags = action.item;
                return newThemeStorage;
            }

            const item = newThemeStorage.themes_store[indexThemeStorage].exibicao_itens_tags.filter(a => a.id === action.item.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage].exibicao_itens_tags.indexOf(item);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage].exibicao_itens_tags[index] = action.item;
            } else {
                newThemeStorage.themes_store[indexThemeStorage].exibicao_itens_tags.push(action.item);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_CAMPAIGNS: {
            if (action.campaign === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.campaign !== undefined && action.campaign.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage].campaigns = action.campaign;
                return newThemeStorage;
            }

            const item = newThemeStorage.themes_store[indexThemeStorage].campaigns.filter(a => a.id === action.campaign.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage].campaigns.indexOf(item);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage].campaigns[index] = action.campaign;
            } else {
                newThemeStorage.themes_store[indexThemeStorage].campaigns.push(action.campaign);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_FALE_CONOSCO: {
            if (action.faleConosco === undefined && action.faleConosco !== null)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            newThemeStorage.themes_store[indexThemeStorage].faleConosco = action.faleConosco;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_ABOUT_US: {
            if (action.aboutUs === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            newThemeStorage.themes_store[indexThemeStorage].aboutUs = action.aboutUs;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_DETALHES_PAGINA_PRODUTOS: {
            if (action.detalhesPaginaProdutos === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            newThemeStorage.themes_store[indexThemeStorage].detalhesPaginaProdutos = action.detalhesPaginaProdutos;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_FOOTER: {
            if (action.data === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            if (action.data !== undefined && (action.property === undefined || action.property === null)) {
                newThemeStorage.themes_store[indexThemeStorage].footer = action.data;
                return newThemeStorage;
            }
            else if (action.data.length > 0) {
                newThemeStorage.themes_store[indexThemeStorage].footer[action.property] = action.data;
                return newThemeStorage;
            }

            const item = newThemeStorage.themes_store[indexThemeStorage].footer[action.property].filter(a => a.id === action.data.id)[0];
            const index = newThemeStorage.themes_store[indexThemeStorage].footer[action.property].indexOf(item);

            if (index >= 0) {
                newThemeStorage.themes_store[indexThemeStorage].footer[action.property][index] = action.data;
            } else {
                newThemeStorage.themes_store[indexThemeStorage].footer[action.property].push(action.data);
            }
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_HEADER_MENSAGENS: {
            if (action.headersMensagem === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            newThemeStorage.themes_store[indexThemeStorage].headersMensagem = action.headersMensagem;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_POLITICAS: {
            if (action.politicas === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            newThemeStorage.themes_store[indexThemeStorage].politicas = action.politicas;
            return newThemeStorage;
        }
        case TEMA_TEMA_SELECIONADO: {
            if (action.headersMensagem === undefined)
                return state;

            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);

            newThemeStorage.themes_store[indexThemeStorage] = action.data;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_IMAGEM_PRODUTO: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].imagemProduto = action.imagemProduto;
            return newThemeStorage;
        }
        case TEMA_LOJA_SET_MARKETING_SEO: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].marketingSEO = action.marketingSEO;

            return newThemeStorage;
        }
        case TEMA_LOJA_SET_GOOGLE_ANALITICS: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].googleAnalytics = action.googleAnalytics;

            return newThemeStorage;
        }
        case TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO: {
            const newThemeStorage = { ...state };
            const _themeStorage = newThemeStorage.themes_store.filter(item => item.id === action.themeStorageId)[0];
            const indexThemeStorage = state.themes_store.indexOf(_themeStorage);
            newThemeStorage.themes_store[indexThemeStorage].cardsProdutoFavorito = action.cardsProdutoFavorito;

            return newThemeStorage;
        }
        default:
            return state;
    }
}

export default temaLojaReducer;
