import { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { TEMA_LOJA_SET_EXIBICAO_ITENS_MENU } from '../../store/TemaLojaReducer/types';
import UploadImagemDesign from '../UploadImagemDesign';
import { POST_DATA } from '../../services/API';

const ExibicaoItensMenu = () => {
    const dispatch = useDispatch();
    const themeStorage = useSelector((store) => store.temaLoja);
    const [themeStoreSelected, setThemeStoreSelected] = useState(null);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store) {
            const themeStore = themeStorage.themes_store.filter(a => a.id === Number(themeStorage.idThemeStoreSelected))[0];
            setThemeStoreSelected(themeStore);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeStorage]);

    const handleOnChangeImagem = (id, dataImagem) => {
        const itensExibicao = themeStoreSelected.exibicao_itens_tags.filter(item => item.id === id)[0];
        itensExibicao.imagem = dataImagem;

        dispatch({
            type: TEMA_LOJA_SET_EXIBICAO_ITENS_MENU,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: itensExibicao
        });
    }

    const handleOnChangeCategoria = (id, tag) => {
        const itensExibicao = themeStoreSelected.exibicao_itens_tags.filter(item => item.id === id)[0];
        itensExibicao.tag = tag;

        dispatch({
            type: TEMA_LOJA_SET_EXIBICAO_ITENS_MENU,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: itensExibicao
        });
    }

    return (
        themeStoreSelected &&
        <Container>
            <Grid container spacing={2} style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Itens a serem exibidos</Typography>
                    <Typography style={{ fontSize: '16px', color: 'gray' }}>Favor informar a imagem e descrição dos 3 principais itens a serem exibidos</Typography>
                </Grid>
                {themeStoreSelected.exibicao_itens_tags && themeStoreSelected.exibicao_itens_tags.map((item, index) => {
                    return (
                        <Grid key={item.id} item xs={4} style={{ maxWidth: '450px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                            <UploadImagemDesign
                                dados={item.imagem}
                                isEditing={true}
                                key={item.id}
                                id={item.id}
                                idComponent={`exibicao_item_menu_${item.id}`}
                                imgData={item.imagem.url}
                                onChange={(value) => handleOnChangeImagem(item.id, value)}
                                borderRadiusImg={0}
                                paddingTopImg={0}
                                widthImg='100%'
                                heightImg={150}
                                style={{ height: 240 }}

                                dimensaoWidthImg={846}
                                dimensaoHeightImg={550}
                                descricao={'Tamanho máximo 846 x 550'}
                            />

                            {/* <TextField
                                style={{ width: '100%', marginTop: '10px' }}
                                variant="outlined"
                                label='Descrição'
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                                value={item.title}
                                onChange={(e) => handleOnChangeTitle(item.id, e.target.value)}
                            /> */}
                            <Box>
                                <FormControlLabel
                                    fullWidth
                                    labelPlacement="top"
                                    style={{ paddingTop: 10 }}
                                    control={
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            labelId={item.id}
                                            id="label-select"
                                            value={item.tag}
                                            label="Categoria"
                                            defaultValue={'nenhum'}
                                            onChange={(e) => handleOnChangeCategoria(item.id, e.target.value)}
                                            style={{ marginTop: 10, width: '100%', minWidth: 220 }}
                                            MenuProps={{
                                                disablePortal: true,
                                                PaperProps: {
                                                    style: {
                                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={'nenhum'}>Nenhum(a)</MenuItem>
                                            <MenuItem value={'maisVendido'}>Produtos mais vendidos</MenuItem>
                                            <MenuItem value={'produtosNovos'}>Produtos novos</MenuItem>
                                        </Select>}
                                        label="Categoria"
                                />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    );
}

export default ExibicaoItensMenu;
