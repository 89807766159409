import axios from "axios";
import tratarErro from "../utils/tratarError";
import { getStorage, removeStorage } from "../../hooks/useStorage";
import * as Sentry from "@sentry/react";
import * as moment from "moment";

const AUTH_HEADER = () => {
   return {
      auth: {
         username: process.env.REACT_APP_API_AUTH_USERNAME,
         password: process.env.REACT_APP_API_AUTH_PASSWORD,
      },
      headers: {
         Authorization:
            "Basic QWNjZXNzTGVhcFN0eWxlREVWQVBJOk1qQk1SV0Z3VTNSNVRHVlNiMk5yZEZOMGRXUnBiekl4",
         "Content-Type": "application/json",
         "Access-Control-Allow-Origin": "*",
      },
   };
};

const AUTH_HEADER_SERVICOS_ROBO = (token = 1) => {
   return {
      //1 = Token para a Brevo
      //2 = Token outros

      headers: {
         Authorization: token === 1? `Basic ${process.env.REACT_APP_API_TOKEN_SERVICOS_ROBO}` : `Basic ${process.env.REACT_APP_API_TOKEN_SERVICOS_ROBO_2}`,
         "Content-Type": "application/json",
         "Access-Control-Allow-Origin": "*",
      },
   };
};

const AUTH_HEADER_FILE = () => {
   return {
      auth: {
         username: process.env.REACT_APP_API_AUTH_USERNAME,
         password: process.env.REACT_APP_API_AUTH_PASSWORD,
      },
      headers: {
         "Content-Type": "multipart/form-data",
         "Access-Control-Allow-Origin": "*",
      },
   };
};

const AUTH_HEADER_AZURE = () => {
   return {
      headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
         "Access-Control-Allow-Origin": "*",
         Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
      },
   };
};

const AUTH_HEADER_FILE_AZURE = () => {
   return {
      headers: {
         type: "formData",
         "Content-Type": "multipart/form-data",
         Accept: "application/json",
         "Access-Control-Allow-Origin": "*",
         Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
      },
   };
};

export const PUT_AUTH = async (email, senha) => {
   try {
      const endpoint = `${process.env.REACT_APP_API
         }/Usuario/Login?email=${email}&senha=${encodeURIComponent(
            senha
         )}&idUsuarioLoja=188`;

      const response = await axios.put(endpoint, {}, AUTH_HEADER());
      if (response.status >= 200 && response.status <= 299)
         return response?.data;

      return null;
   } catch (err) {
      SalvarLogSentry(err);
      throw tratarErro(err);
   }
};

export const POST_AUTH = async (obj = {}) => {
   try {
      const endpoint = process.env.REACT_APP_API_DESIGN.replace(
         "/api",
         "/authentication"
      );
      const response = await axios.post(endpoint, obj);
      if (response.status >= 200 && response.status <= 299)
         return response?.data;

      return null;
   } catch (err) {
      throw tratarErro(err);
   }
};

const logout = () => {
   removeStorage('usrin');
   removeStorage('onBoard');
   removeStorage('superUsrin');
   removeStorage('usrLeapVesti');
   removeStorage('usrLeapLovers');
}

export const POST_DATA = async (endpoint, obj = {}) => {
   try {
      const response = await axios.post(
         `${process.env.REACT_APP_API}/${endpoint}`,
         obj,
         AUTH_HEADER()
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      if (err?.response?.status === 401) {
         //Não autorizado
         logout();

         alert("Sessão expirada!\n Favor realizar o login novamente");
         window.location.href = "/login";
      }
      throw tratarErro(err);
   }
};

export const DELETE_DATA = async (endpoint) => {
   try {
      const response = await axios.delete(
         `${process.env.REACT_APP_API}/${endpoint}`,
         AUTH_HEADER()
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      if (err?.response?.status === 401) {
         //Não autorizado
         logout();

         alert("Sessão expirada!\n Favor realizar o login novamente");
         window.location.href = "/login";
      }
      throw tratarErro(err);
   }
};

export const POST_DATA_SERVICOS_ROBO = async (endpoint, obj = {}, opcaoToken = 1, headersAdd = {}) => {
   try {
      let header = AUTH_HEADER_SERVICOS_ROBO(opcaoToken);

      header.headers = {
         ...header.headers,
         ...headersAdd
      }

      const response = await axios.post(
         `${process.env.REACT_APP_API_SERVICOS_ROBO}${endpoint}`,
         obj,
         header
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      SalvarLogSentry(err, endpoint);
      throw err;
   }
};

export const GET_DATA_SERVICOS_ROBO = async (endpoint, opcaoToken = 1, headersAdd = {}) => {
   try {
      let header = AUTH_HEADER_SERVICOS_ROBO(opcaoToken);

      header.headers = {
         ...header.headers,
         ...headersAdd
      }

      const response = await axios.get(
         `${process.env.REACT_APP_API_SERVICOS_ROBO}${endpoint}`,
         header
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      SalvarLogSentry(err, endpoint);
      throw err;
   }
};

export const POST_DATA_DESING = async (endpoint, token, obj = {}) => {
   try {
      const response = await axios.post(
         `${process.env.REACT_APP_API_DESIGN}/${endpoint}`,
         obj,
         {
            headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               Authorization: `Bearer ${token}`,
            },
         }
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      SalvarLogSentry(err, endpoint);
      throw tratarErro(err);
   }
};

export const POST_DATA_FORM_DESING = async (endpoint, token, obj = {}) => {
   try {
      const response = await axios.post(
         `${process.env.REACT_APP_API_DESIGN}/${endpoint}`,
         obj,
         {
            headers: {
               "Content-Type": "multipart/form-data",
               "Access-Control-Allow-Origin": "*",
               Authorization: `Bearer ${token}`,
            },
         }
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      SalvarLogSentry(err, endpoint);
      throw tratarErro(err);
   }
};

export const POST_DATA_FILE = async (endpoint, obj = {}) => {
   try {
      const response = await axios.post(
         `${process.env.REACT_APP_API}/${endpoint}`,
         obj,
         AUTH_HEADER_FILE()
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      if (err?.response?.status === 401) {
         //Não autorizado
         logout();

         alert("Sessão expirada!\n Favor realizar o login novamente");
         window.location.href = "/login";
      }
      throw err?.response?.data;
   }
};

export const PUT_DATA_FILE = async (endpoint, obj = {}) => {
   try {
      const response = await axios.put(
         `${process.env.REACT_APP_API}/${endpoint}`,
         obj,
         AUTH_HEADER_FILE()
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      if (err?.response?.status === 401) {
         //Não autorizado
         logout();

         alert("Sessão expirada!\n Favor realizar o login novamente");
         window.location.href = "/login";
      }
      throw err?.response?.data;
   }
};

export const PUT_DATA = async (endpoint, obj = {}) => {
   try {
      if (endpoint === 'Imagem/GetListImagemFilterPaginationCRM') {
         try {
            const response = await axios.post(`${process.env.REACT_APP_API_AZURE_FILE}/leappublish_pagination_web`, obj, {
               headers: {
                  "type": "formData",
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Basic ${process.env.REACT_APP_API_AZURE_FILE_TOKEN}`
               }
            });

            if (response.status >= 200 && response.status <= 299) return response?.data?.objetoRetorno;

            return null;
         }
         catch {
            const response = await axios.put(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER());
            if (response.status >= 200 && response.status <= 299) return response?.data;
            return null;
         }
      }

      const response = await axios.put(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER());
      if (response.status >= 200 && response.status <= 299) return response?.data;
      return null;
   }
   catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      if (err?.response?.status === 401) {
         //Não autorizado
         logout();

         alert("Sessão expirada!\n Favor realizar o login novamente");
         window.location.href = "/login";
      }

      throw tratarErro(err);
   }
};

export const PUT_DATA_DESIGN = async (endpoint, token, obj = {}) => {
   try {
      const response = await axios.put(
         `${process.env.REACT_APP_API_DESIGN}/${endpoint}`,
         obj,
         {
            headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               Authorization: `Bearer ${token}`,
            },
         }
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      throw tratarErro(err);
   }
};

export const GET_DATA = async (endpoint) => {
   try {
      const response = await axios.get(
         `${process.env.REACT_APP_API}/${endpoint}`,
         AUTH_HEADER()
      );
      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      if (err?.response?.status !== 406 || err?.response?.status !== 401) {
         SalvarLogSentry(err, endpoint);
      }

      if (err?.response?.status === 401) {
         //Não autorizado
         logout();

         alert("Sessão expirada!\n Favor realizar o login novamente");
         window.location.href = "/login";
      }
      throw tratarErro(err);
   }
};

export const GET_DATA_DESIGN = async (endpoint, token) => {
   try {
      const response = await axios.get(
         `${process.env.REACT_APP_API_DESIGN}/${endpoint}`,
         {
            headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               Authorization: `Bearer ${token}`,
            },
         }
      );

      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      SalvarLogSentry(err, endpoint);
      throw tratarErro(err);
   }
};

export const UploadFileAzure = async (file, diretorio, nomeArquivo, contentType = `image/jpg`, raiz = 'cms') => {
   try {
      if (file === null || file === undefined) return file;

      const obj = {
         raiz: raiz,
         pasta: diretorio,
         nomeArquivo: nomeArquivo,
         contentType: contentType
      }

      let data = new FormData();
      data.append("Imagem", file);
      data.append("objImagem", JSON.stringify(obj));

      const result = await axios.post(
         process.env.REACT_APP_API_AZURE,
         data,
         AUTH_HEADER_FILE_AZURE()
      );

      if (
         result.data.caminho &&
         !contentType.includes("webp") &&
         !contentType.includes("gif") &&
         !contentType.includes("mp4")
      ) {
         return await saveImagemConvertedWebp(
            nomeArquivo,
            diretorio,
            result.data.caminho
         );
      }

      return result.data.caminho;
   } catch (err) {
      SalvarLogSentry(err);
      throw tratarErro(err);
   }
};

export const saveImagemConvertedWebp = async (
   nomeArquivo,
   diretorio,
   urlImage
) => {
   try {
      const convert = await POST_DATA_AZURE_FILE("ConvertToWebp", {
         imageUrl: urlImage,
      });
      const data = new FormData();
      data.append(
         "objImagem",
         JSON.stringify({
            raiz: "cms",
            pasta: diretorio,
            nomeArquivo: `${nomeArquivo}.webp`,
            contentType: "image/webp",
            base64: convert.base64,
         })
      );

      const result = await axios.post(
         process.env.REACT_APP_API_AZURE,
         data,
         AUTH_HEADER_FILE_AZURE()
      );
      return result.data.caminho;
   } catch {
      return urlImage;
   }
};

const POST_DATA_AZURE_FILE = async (endpoint, obj = {}) => {
   try {
      const response = await axios.post(
         `${process.env.REACT_APP_API_AZURE_FILE}/${endpoint}`,
         obj,
         {
            headers: {
               type: "formData",
               "Content-Type": "application/json",
               Accept: "application/json",
               "Access-Control-Allow-Origin": "*",
               Authorization: `Basic ${process.env.REACT_APP_API_AZURE_FILE_TOKEN}`,
            },
         }
      );

      if (response.status >= 200 && response.status <= 299)
         return response?.data;
      return null;
   } catch (err) {
      SalvarLogSentry(err, endpoint);
      throw err;
   }
};

export const UploadFileAzureText = async (file, diretorio, nomeArquivo, contentType = `text/csv`, raiz = 'cms') => {
   try {
      if (file === null || file === undefined) return file;

      const obj = {
         raiz: raiz,
         pasta: diretorio,
         nomeArquivo: nomeArquivo,
         contentType: contentType,
         fileBase64: file
      }

      const result = await POST_DATA_AZURE_FILE("UploadFileForm", obj);

      if (result.success) {
         return result.caminho;
      } else {
         throw result;
      }
   } catch (err) {
      SalvarLogSentry(err);
      throw tratarErro(err);
   }
};

//Vesti
export const INTEGRA_VESTI = async (
   metodo = "GET",
   endPoint,
   company,
   apiKey,
   dados = null
) => {
   try {
      const obj = {
         sUrl: `${process.env.REACT_APP_API_VESTI}/v1/${endPoint}`,
         sHttp: metodo,
         sCompany: company,
         sApiKey: apiKey,
         body: dados,
      };

      const result = await PUT_DATA(`FornecedoresVesti/ConsutaVesti`, obj);
      return result.objetoRetorno;
   } catch (err) {
      SalvarLogSentry(err, endPoint);
      throw tratarErro(err);
   }
};

//Fim Vesti

export const SalvarLogSentry = (err, endpoint = "") => {
   const user = JSON.parse(getStorage("usrin"));
   const userLogadoStr = user ? `Usuário logado: ${user?.Email} - Empresa Id: ${user?.IdUsuarioEmpresa}` : 'Sem dados do usuário';

   const msgErr = `${userLogadoStr} ${(endpoint.length > 0 ? ` - Endpoint: ${endpoint}` : "")}\nMessage: ${err.message}\nStack trace: ${err?.stack}`;
   Sentry.captureException(msgErr, {
      tags: { feature: endpoint },
   });

   const obj = {
      Descricao: err.message,
      Erro: msgErr,
      Controller: 'CRM error',
      DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
   };
   PUT_DATA(`/LogErro/CadastroLogErro`, obj);
}

export const SalvarLogSentryLogin = (err, nome, email, endpoint = "") => {
   const msgErr = `Usuário logado: ${nome} - ${email} ${(endpoint.length > 0 ? ` - Endpoint: ${endpoint}` : "")}\nMessage: ${err.message}\nStack trace: ${err?.stack}`;
   Sentry.captureException(msgErr, {
      tags: { feature: endpoint },
   });

   const obj = {
      Descricao: err.message,
      Erro: msgErr,
      Controller: 'CRM error',
      DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
   };
   PUT_DATA(`/LogErro/CadastroLogErro`, obj);
}

export const SalvarLogEventoSentry = (evento) => {
   const user = JSON.parse(getStorage('usrin'));
   const msgErr = `${evento}\nUsuário logado: ${user?.Email} - Empresa Id: ${user?.IdUsuarioEmpresa}`;

   Sentry.captureEvent({
      message: msgErr,
      user: { id: user?.IdUsuario, username: user?.NomeFantasia, email: user?.Email },
      level: "log",
      platform: "crm",
      //transaction: JSON.stringify(req.body),
      release: process.env.REACT_APP_AMBIENTE,
      //environment: dbName,
      //url: "leappublish",
      //logger: JSON.stringify(req.body)
   });
}

export const handleTrack = async (idUsuario, origem, mensagem) => {
   try {
      await POST_DATA(`Log/InsertLogModulosUsuario?idUsuario=${idUsuario}&sModulo=${origem}&sDescricao=${mensagem}`);
      SalvarLogEventoSentry(`Onboard => ${mensagem}`);
   }
   catch (err) {
      SalvarLogSentry(err, `handleTrack ${origem}`);
   }
}

export const handleDownload = async (urlFile) => {
   return fetch(urlFile)
      .then(response => {
         if (!response.ok) {
            throw new Error('Falha ao carregar o JSON');
         }
         return response.json();
      })
      .then(data => {
         return data;
      })
      .catch(error => console.error('Erro:', error));
}

export const salvarArquivoDominios = async (userLogeedIn, dominio) => {
   try {
      let urlDominiosJson = "https://leapstyle.blob.core.windows.net/cms/config/dominios.json";
      if (process.env.REACT_APP_AMBIENTE === 'DEV')
         urlDominiosJson = "https://leapstyle.blob.core.windows.net/cms/config/homolog/dominios.json";

      const objDominios = await handleDownload(urlDominiosJson);

      let encontrou = false;
      const codigoLoja = `${moment(userLogeedIn.DataCadastroEmpresa).format('YYYYMMDDHHmmss')}${userLogeedIn.IdUsuario}`;
      objDominios.forEach((item) => {
         if (item.idUsuarioEmpresa == dominio.IdUsuarioEmpresa) {
            item.cnpj = userLogeedIn.Cnpj.replaceAll(".", "").replace("/", "").replace("-", "");
            item.dominio = dominio.NomeSiteLeap;

            //item.dominioGeral = dominio.NomeSiteDominioGeral;
            item.dominioGeral = dominio.NomeSiteLeap;

            item.dataAlteracao = moment().format("YYY-MM-DD HH:mm:ss");
            item.codigoLoja = codigoLoja;
            encontrou = true;
         }
      });

      if (!encontrou) {
         objDominios.push({
            "cnpj": userLogeedIn.Cnpj.replaceAll(".", "").replace("/", "").replace("-", ""),
            "idUsuarioEmpresa": userLogeedIn.IdUsuarioEmpresa,
            "dominio": dominio.NomeSiteLeap,

            //"dominioGeral": dominio.NomeSiteDominioGeral,
            "dominioGeral": dominio.NomeSiteLeap,

            "codigoLoja": codigoLoja,
            "dataAlteracao": moment().format("YYY-MM-DD HH:mm:ss")
         })
      }

      const base64 = btoa(JSON.stringify(objDominios));

      await UploadFileAzureText(base64, 'config', process.env.REACT_APP_AMBIENTE === 'DEV' ? 'homolog/dominios.json' : 'dominios.json', 'text/plain');
   }
   catch (err) {
      SalvarLogSentry(err);
   }
}


//Academy
export const GetModulo = async (categoryId) => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModulo/GetAllByIdCategoriaModulo?idCategoriaModulo=${categoryId}`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return { status: response.status, data: response.data };
      } else {
         return response.status;
      }
   } catch (err) {
      SalvarLogSentry(err);
   }
};

export const GetAulas = async (categoryId) => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModuloCurso/GetAllByIdModulo?idModulo=${categoryId}`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return { status: response.status, data: response.data };
      } else {
         return response.status;
      }
   } catch (err) {
      SalvarLogSentry(err);
   }
};

export const GetAllModuloCRUD = async () => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModulo/GetAll`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const GetEADCategoriaModulo = async () => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADCategoriaModulo/GetAll`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const InsertEADModulo = async (data) => {
   try {
      let config = {
         method: "post",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModulo/Insert`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
         data: data
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const GetModuloById = async (id) => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModulo/GetById?id=${id}`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const UpdateModuloById = async (data) => {
   try {
      let config = {
         method: "put",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModulo/Update`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
         data: data
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const GetAllEadModuloCurso = async () => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModuloCurso/GetAll`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         }
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const GetModuloCursoById = async (id) => {
   try {
      let config = {
         method: "get",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModuloCurso/GetById`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
         params: {
            id: id
         }
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const UpdateCursoById = async (data) => {
   try {
      let config = {
         method: "put",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModuloCurso/Update`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
         data: data
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};

export const InsertEADModuloCurso = async (data) => {
   try {
      let config = {
         method: "post",
         maxBodyLength: Infinity,
         url: `${process.env.REACT_APP_API}/EADModuloCurso/Insert`,
         headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`,
         },
         data: data
      };

      const response = await axios.request(config);

      if (response.status >= 200 && response.status <= 299) {
         return response.data
      } else {
         return response.status;
      }
   } catch (error) {
      console.log(error);
   }
};