import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)`
    padding-bottom: 150px;
`;

export const AlertError = styled(Typography)`
    padding-top: 10px;
    color: #e15126;
    font-size: 11px;
    font-weight: bold;
`;

export const Title = styled(Typography)`
    color: #757575;
    font-size: 16px !important;
    font-weight: bold;
`;

export const Descricao = styled(Typography)`
    color: rgb(247, 147, 30);
    font-size: 24px !important;
    font-weight: 700 !important;
    padding-top: 10px;
`;

export const ModalContainer = styled(Box)`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 30px;
  border-radius: 0.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  
  min-height: 200px;
  height: auto;

  max-height: 300px;
  max-width: 400px;
  
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.51);
  @media screen and (max-width: 600px) {
    width: 90%;
  }

  //animation
  animation: fade-in 300ms ease-out forwards;  
`;