import { Fragment, useEffect, useState } from "react";
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA, PUT_DATA, SalvarLogSentry } from "../../services/API";
import { Button, CircularProgress, IconButton, InputAdornment, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { ImagemSemResultado, isNumber } from "../../services/utils/functions";
import { useDispatch } from "react-redux";
import { USUARIO_LOGADO_SET_DADOS } from "../../store/UsuarioLogadoReducer/types";
import { setStorage } from "../../hooks/useStorage";
import * as moment from "moment";
import { useNavigate } from "react-router";
import { SET_DADOS_ON_BOARD } from "../../store/OnBoard/types";

import ClearIcon from '@mui/icons-material/Clear';
import { TEMA_LOJA_SET } from "../../store/TemaLojaReducer/types";

const Lojas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [lojas, setLojas] = useState([]);
    const [loading, setLoading] = useState([]);

    const [tabSelected, setTabSelected] = useState(0);

    const [search, setSearch] = useState('');
    const [pageLojas, setPageLojas] = useState(0);
    const [rowsLojas, setRowsLojas] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            await fetchLojas();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await fetchLojas();
        })();
    }, [tabSelected]);

    const columns = [
        <TableCell>Id</TableCell>,
        <TableCell>Data de cadastro</TableCell>,
        <TableCell>Nome empresa</TableCell>,
        <TableCell>E-mail</TableCell>,
        <TableCell>Telefone 1</TableCell>,
        <TableCell>Telefone 2</TableCell>,
        <TableCell align="left">CNPJ</TableCell>,
        <TableCell align="left">Status</TableCell>,
        <TableCell />
    ];

    function Row({ usuario }) {
        return (
            <Fragment>
                <TableRow>
                    <TableCell component="th" scope="row">{usuario.IdUsuario}</TableCell>
                    <TableCell align="left">{moment(usuario.DataCadastro).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                    <TableCell align="left">{usuario.NomeFantasia}</TableCell>
                    <TableCell align="left">{usuario.Usuario.Email}</TableCell>
                    <TableCell align="left">{usuario?.Usuario?.Celular}</TableCell>
                    <TableCell align="left">{usuario?.Telefone}</TableCell>
                    <TableCell align="left">{usuario.Cnpj}</TableCell>
                    <TableCell align="left">{usuario.FlgAtivo ? "Sim" : "Não"}</TableCell>
                    <TableCell align="center">
                        <Button
                            variant="contained"
                            onClick={async () => {
                                const user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${usuario.IdUsuario}&join=true`);
                                const usuarioEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${usuario.IdUsuario}`);
                                const usuarioLogado = {
                                    IdUsuario: user.IdUsuario,
                                    IdEndereco: user._Endereco[0]?.END_Id,
                                    Endereco: user._Endereco[0],
                                    Celular: user.Celular,
                                    NomeFantasia: usuarioEmpresa?.NomeFantasia,
                                    IdUsuarioEmpresa: usuarioEmpresa.IdUsuarioEmpresa,
                                    IdTema: user.IdTema,
                                    Email: user.Email,
                                    Cnpj: usuarioEmpresa.Cnpj,
                                    MetaFaturamento: usuarioEmpresa.metaFaturamento,
                                    MetaVenda: usuarioEmpresa.metaVenda,
                                    PlanoEmpresa: usuarioEmpresa.UsuarioEmpresaPlano,
                                    TipoAssinaturaPlano: usuarioEmpresa.TipoAssinaturaPlano,
                                    FlgCodigoAcesso: user.FlgCodigoAcesso,
                                    CodigoIndicado: user.CodigoIndicado,
                                    FlgLiberadaLojaOnline: usuarioEmpresa.FlgLiberadaLojaOnline,
                                    DataCadastroEmpresa: usuarioEmpresa.DataCadastro,
                                    Time: moment().add(4, 'hours')
                                };

                                const obj = {
                                    userName: process.env.REACT_APP_LOGIN_DESIGN,
                                    password: process.env.REACT_APP_SENHA_DESIGN
                                };
                                const loginDesign = await POST_AUTH(obj);
                                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${usuarioLogado.IdUsuario}`, loginDesign.token);
                                if (construtorSite?.data?.id > 0 && construtorSite?.data?.arquivo?.idLoja > 0) {
                                    dispatch({
                                        type: TEMA_LOJA_SET,
                                        data: construtorSite?.data?.arquivo
                                    });
                                }

                                const onboardResult = await POST_DATA(`OnboardingStore/GetAllOnboardingStoreByValorExato?strValorExato=${usuarioEmpresa.IdUsuarioEmpresa}&ColunaParaValorExato=OS.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=OS.IdOnboardingStore&Log=`);
                                let objOnboard = null;

                                if (onboardResult.length === 0) //Salvar no banco um novo onBoard
                                {
                                    const newOnboard = {
                                        IdUsuarioEmpresa: usuarioEmpresa.IdUsuarioEmpresa,
                                        IdUltimoOnboardingStoreItem: 0,
                                        FlagCompletado: false,
                                        FlagAtivo: true
                                    }

                                    objOnboard = await PUT_DATA(`OnboardingStore/CadastroOnboardingStore`, newOnboard);
                                }
                                else {
                                    objOnboard = onboardResult[0];
                                }

                                dispatch({
                                    type: SET_DADOS_ON_BOARD,
                                    data: objOnboard
                                });

                                setStorage('usrin', JSON.stringify(usuarioLogado));
                                dispatch({
                                    type: USUARIO_LOGADO_SET_DADOS,
                                    data: usuarioLogado
                                });

                                if (!objOnboard.FlagCompletado)
                                    navigate('/on-board');
                                else
                                    navigate('/dashboard');
                            }}
                        >
                            Acessar
                        </Button>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPageLojas(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPageLojas(0);
    };

    const fetchLojas = async () => {
        try {
            setLoading(true);
            const url = tabSelected === 0 ? `Usuario/GetAllUsuarioEmpresa?FlgLiberadoShopLeap=false&FlgBrecho=false&maxInstances=10000&order_by=IdUsuario` : `Usuario/GetAllUsuarioEmpresa?FlgLiberadoShopLeap=false&FlgBrecho=true&maxInstances=10000&order_by=IdUsuario`;
            const resultLojas = await POST_DATA(url);

            let lojasFiltradas = [];

            //Colocar FlgCodigoAcesso
            resultLojas.filter(a => a.Usuario.FlgCodigoAcesso).sort((a, b) => new Date(b.DataCadastro) - new Date(a.DataCadastro)).forEach((item) => {
                if (lojasFiltradas.filter(a => a.IdUsuario === item.IdUsuario).length > 0) {
                    return;
                }
                lojasFiltradas.push(item);
            });

            setRowsLojas(lojasFiltradas);
            setLojas(lojasFiltradas);
            setLoading(false);
        }
        catch (err) {
            SalvarLogSentry(err);
        }
    }

    const filtrarLojas = (filtroStr = null) => {
        filtroStr = filtroStr !== null ? filtroStr : search;

        setLoading(true);
        if (filtroStr.length > 0) {
            const filtro = filtroStr.trim();
            const cnpj = filtro.replace(".", "").replace("-", "").replace("/", "").replace(".", "").trim();
            const ehCnpj = isNumber(cnpj);

            if (ehCnpj && cnpj.length >= 11) {
                const result = lojas.filter(a => a.Cnpj?.replace(".", "").replace("-", "").replace("/", "").replace(".", "") == cnpj);
                setRowsLojas(result);
            }
            else if(ehCnpj) {
                const result = lojas.filter(a => a.IdUsuario == cnpj);
                setRowsLojas(result);
            }
            else {
                const result = lojas.filter(a => a.NomeFantasia?.toUpperCase()?.includes(filtro.toUpperCase()));
                setRowsLojas(result);
            }
        }
        else {
            setRowsLojas(lojas);
        }
        setLoading(false);
    }

    const handleChangeTab = (event, newValue) => {
        setTabSelected(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Stack direction="row" gap={2}>
                <TextField
                    label="Pesquisar"
                    placeholder='Nome/Id/Cnpj'
                    size="small"
                    sx={{ width: 250 }}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    onKeyDownCapture={(e) => {
                        if (e.key === 'Enter') {
                            filtrarLojas();
                        }
                    }}
                    InputProps={{
                        endAdornment: search.length > 0 && <InputAdornment
                            style={{ cursor: 'pointer' }}
                            position="end"
                            onClick={() => {
                                setSearch(() => '');
                                filtrarLojas('');
                            }}
                        >
                            <ClearIcon />
                        </InputAdornment>
                    }}
                />
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ height: 43 }}
                    onClick={() => {
                        filtrarLojas();
                    }}
                >
                    Pesquisar
                </Button>
            </Stack>

            <Tabs value={tabSelected} onChange={handleChangeTab} aria-label="basic tabs example" style={{ marginTop: 15 }}>
                <Tab style={{ color: 'black', fontSize: 14 }} label="Lojas" {...a11yProps(0)} />
                <Tab style={{ color: 'black', fontSize: 14 }} label="Brechos" {...a11yProps(1)} />
            </Tabs>

            {loading ? (
                <Stack
                    sx={{ height: "500px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando vendas</Typography>
                </Stack>
            ) : (

                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => column)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsLojas && rowsLojas.slice(pageLojas * rowsPerPage, pageLojas * rowsPerPage + rowsPerPage)?.map((usuario, i) => <Row key={i} usuario={usuario} />)}
                            </TableBody>
                        </Table>
                        {rowsLojas.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsLojas.length}
                        rowsPerPage={rowsPerPage}
                        page={pageLojas}
                        labelRowsPerPage={
                            search
                                ? `${rowsLojas?.length} resultados encontrados para pesquisa "${search}" - Produtos por página: `
                                : "Produtos por página"
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}
        </>
    )

}

export default Lojas;