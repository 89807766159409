import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Modal, Pagination, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Container, ModalContainer } from "./styles";
import { useEffect, useRef, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ImagemSemResultado, formatDateSimples } from "../../../services/utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA, PUT_DATA } from "../../../services/API";
import { getStorage, removeStorage, setStorage } from "../../../hooks/useStorage";
import { SHOPING_CART_SET } from "../../../store/ShoppingCartReducer/types";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO } from "../../../store/ControleMensagemReducer/types";

import { Add, Remove } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';

import { PIX } from "react-qrcode-pix";

import * as moment from "moment";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "dayjs/locale/pt";
import { pt } from 'date-fns/locale';
import BootstrapDialogTitle from "../../../components/Modal/BootstrapDialogTitle";
import { SET_MENU } from "../../../store/Customization/types";
import { Navigate, useNavigate } from "react-router";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

const columnsProdutosDisponiveis = [
    { id: "imagem", label: "Foto", minWidth: 120 },
    { id: "produto", label: "Produto", minWidth: 170, maxWidth: 170 },
    { id: "sku", label: "Sku", minWidth: 130, maxWidth: 130 },
    { id: "preco", label: "Preço", minWidth: 120, maxWidth: 120 },
    { id: "acao", label: "", minWidth: 80, maxWidth: 80 }
];

const columnsProdutosSelecionados = [
    { id: "produto", label: "Produto", minWidth: 170, maxWidth: 170 },
    { id: "sku", label: "Sku", minWidth: 100, maxWidth: 100 },
    { id: "qtd", label: "Qtd", minWidth: 100, maxWidth: 100 },
    { id: "preco", label: "Preço", minWidth: 120, maxWidth: 120 },
    { id: "acao", label: "", minWidth: 30, maxWidth: 30 }
];

const columnsClientes = [
    { id: "nome", label: "Nome", minWidth: 120 },
    { id: "cpf", label: "CPF", minWidth: 170, maxWidth: 170 },
    { id: "contato", label: "Contato", align: 'left', minWidth: 150, maxWidth: 150 },
    { id: "acao", label: "", minWidth: 80, maxWidth: 80 }
];

const INITIAL_SALE = {
    idCliente: 0,
    nomeCliente: '',
    dataVenda: null,
    idVendedor: 0,
    nomeVendedor: '',
    observacao: '',
    idFormaPagamento: 0,
    quantidadeParcelas: 1,

    acrescimo: 0,
    desconto: 0,
    quantidadeTotal: 0,
    valorProdutos: 0,
    valorTotal: 0,

    vendaFinalizada: false,
    idAcaoLiberada: 1,
    idAcaoExecutada: 1,

    cupom: '',

    cliente: null,
    produtos: [],

    formaPagamento: {
        idPedido: 0,
        dinheiro: 0,
        pix: 0,
        cartaoDebito: 0,
        cartaoCredito: 0,

        troco: 0,
        dadosPix: ''
    }
};

const CaixaPDV = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    const [loadingClientes, setLoadingClientes] = useState(false);
    const [openModalPesquisaCliente, setOpenModalPesquisaCliente] = useState(false);
    const [searchCliente, setSearchCliente] = useState('');

    const refCarregouDados = useRef(false);

    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();
    const [vendaNaoConcluida, setVendaNaoConcluida] = useState(null);

    const [loadingFinalizar, setLoadingFinalizar] = useState(false);
    const [loadingFinalizarVenda, setLoadingFinalizarVenda] = useState(false);
    const [openModalFinalizarVenda, setOpenModalFinalizarVenda] = useState(false);
    const [vendaFinalizada, setVendaFinalizada] = useState(false);
    const [acaoExecutadaFinalizarVenda, setAcaoExecutadaFinalizarVenda] = useState(0);

    const [idAcaoLiberada, setIdAcaoLiberada] = useState(1);
    const [idAcaoExecutada, setIdAcaoExecutada] = useState(1);

    const [loadingProdutos, setLoadingProdutos] = useState(false);
    const [tipoFiltro, setTipoFiltro] = useState(1);
    const [qtdProduto, setQtdProduto] = useState(1);
    const [searchProduto, setSearchProduto] = useState('');

    const [produtosDisponiveis, setProdutosDisponiveis] = useState([]);
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [clientes, setClientes] = useState([]);

    const [dadosVenda, setDadosVenda] = useState(INITIAL_SALE);

    const [page, setPage] = useState(1);
    const [randomSearch, setRandomSearch] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [quantidadeTotal, setQuantidadeTotal] = useState(0);
    const [valorTotal, setValorTotal] = useState('');

    const [dadosUsuarioLoja, setDadosUsuarioLoja] = useState(null);
    const [dadosBancarios, setDadosBancarios] = useState(null);
    const [qrCode, setQrCode] = useState('');
    const [rawPix, setRawPix] = useState('');

    const [cupomDesconto, setCupomDesconto] = useState(null);

    useEffect(() => {
        (async () => {
            if (userLoggedIn === null) return;

            if (userLoggedIn) {
                const carrinhoSessao = JSON.parse(getStorage('shoppingCart'));

                if (carrinhoSessao && carrinhoSessao?.produtos?.length > 0) {
                    setVendaNaoConcluida(carrinhoSessao);
                }

                await fetchProdutos();
                await fetchClientes();

                const dataVenda = moment();
                const objVenda = { ...dadosVenda, idVendedor: userLoggedIn.IdUsuario, nomeVendedor: userLoggedIn.NomeFantasia, dataVenda: new Date(dataVenda) };
                setDadosVenda(objVenda);
                dispatch({ type: SHOPING_CART_SET, value: objVenda });
                setStorage('shoppingCart', JSON.stringify(objVenda));

                await fetchDadosBancarios();

                dispatch({ type: SET_MENU, opened: false });

                if (carrinhoSessao && carrinhoSessao?.produtos?.length > 0) {
                    getConfirmation();
                }

                refCarregouDados.current = true;
            }
        })();
    }, [userLoggedIn]);

    useEffect(() => {
        (async () => {
            await fetchProdutos();
            await fetchClientes();
        })();
    }, [page, randomSearch]);

    useEffect(() => {
        if (cupomDesconto !== null)
            handleAtualizarValoresFormaPagamento('cupom', null);
        else
            handleAtualizarValoresFormaPagamento('acrescimo', (dadosVenda.formaPagamento?.acrescimo || 0));
    }, [cupomDesconto]);

    useEffect(() => {
        if (refCarregouDados.current && produtosSelecionados.length === 0) {
            removeStorage('shoppingCart');
        }
    }, [produtosSelecionados, refCarregouDados.current]);

    useEffect(() => {
        let qtdTotal = 0;
        let vTotal = 0;

        produtosSelecionados.forEach(produto => {
            produto._Etiquetas.forEach(etiqueta => {
                if (etiqueta.EtiquetaComplementoSelecionada !== null && etiqueta.EtiquetaComplementoSelecionada !== undefined) {
                    qtdTotal += etiqueta.EtiquetaComplementoSelecionada?.iQuantidadeVendida;
                    vTotal += etiqueta.EtiquetaComplementoSelecionada?.iQuantidadeVendida * (etiqueta.mValor - (etiqueta.mValor * ((etiqueta?.mPorcentoDesconto || 0) / 100)));
                }

            });
        });

        setQuantidadeTotal(qtdTotal);
        setValorTotal(vTotal.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        }));

        setDadosVenda({ ...dadosVenda, valorTotal: vTotal, valorProdutos: vTotal, quantidadeTotal: qtdTotal, produtos: produtosSelecionados });

    }, [produtosSelecionados]);

    const fetchDadosBancarios = async () => {
        const resultUser = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
        setDadosUsuarioLoja(resultUser);

        const resultDadosBancario = await POST_DATA(`UsuarioDadosBancario/GetUsuarioDadosBancarioByIdPessoa?IdPessoa=${userLoggedIn.IdUsuario}`);
        setDadosBancarios(resultDadosBancario);
    }

    const fetchProdutos = async (addProduto = false) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;

        setLoadingProdutos(true);

        let filtroApi = {
            idUsuario: userLoggedIn?.IdUsuario,
            idUsuarioVendedor: userLoggedIn?.IdUsuario,
            idUsuarioComprador: 0,
            iPagina: page,
            Sexo: '0,1,2',
            FlagKids: false,
            Categoria: null,
            Peca: null,
            Tamanho: "",
            Marca: "",
            HashTags: null,
            ValorMinimo: 0,
            ValorMaximo: 0,
            FlgUsado: false,
            FlgFavoritos: false,
            order_by: '_Etiquetas.sDescricao asc',

            iDisponibilidade: "1,2",

            FlgAtivo: true,

            Log: {
            }
        }

        let filtroStr = searchProduto;
        let endPoint = `Imagem/GetListImagemFilterPaginationCRM`;

        if (filtroStr.trim().length > 0 && tipoFiltro == 2)
            filtroApi = { ...filtroApi, Sku: filtroStr.trim() }
        else if (filtroStr.trim().length > 0 && tipoFiltro == 1)
            filtroApi = { ...filtroApi, sDescricaoEtiqueta: filtroStr.trim() }

        const result = await PUT_DATA(endPoint, filtroApi);
        let produtoAdd = null;
        let etiquetaComplementoAddProduto = null; //Variável para add produto
        const imagens = [];
        result._Imagens.map(produto => {
            produto._Etiquetas = produto._Etiquetas?.map(et => {
                if (filtroStr.trim().length > 0 && tipoFiltro == 2) {
                    et._EtiquetaComplemento = et._EtiquetaComplemento.filter(a => a.Sku === filtroStr).map(etc => {
                        produtoAdd = produto;
                        etiquetaComplementoAddProduto = { ...etc, IdEtiqueta: et.IdEtiqueta };
                        return etc;
                    });
                }

                return et;
            });

            imagens.push(produto);
        });

        setTotalPages(result.iTotalPagina);
        setProdutosDisponiveis(imagens);
        setLoadingProdutos(false);

        if (addProduto && produtoAdd != null && etiquetaComplementoAddProduto != null) {
            handleSelecionarProduto(produtoAdd, etiquetaComplementoAddProduto.IdEtiqueta, etiquetaComplementoAddProduto.IdEtiquetaComplemento, 'add', true, qtdProduto);
        }
    }

    const fetchClientes = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoadingClientes(true);

        let filtroStr = searchCliente || null;
        if (filtro !== null) filtroStr = filtro;

        let endpoint = `UsuarioEmpresa/GetAllUsuarioByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuario}`;
        if (filtroStr !== null && filtroStr.length > 0)
            endpoint = `UsuarioEmpresa/GetAllUsuarioByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuario}&strFiltro=${filtroStr}`;

        const result = await POST_DATA(endpoint);

        setClientes(result || []);
        setLoadingClientes(false);
    }

    const handleCreateEtiquetaComplemento = (produto, idEt, idEtc) => {
        produto._Etiquetas = produto._Etiquetas.map((etq) => {
            etq.idImagem = produto.idImagem;
            if (etq.IdEtiqueta === idEt) {
                etq.EtiquetaComplementoSelecionada = etq._EtiquetaComplemento.filter(ec => ec.IdEtiquetaComplemento === idEtc)[0];
                etq.EtiquetaComplementoSelecionada.iQuantidadeVendida = 0;
            }
            return etq;
        });

        return produto;
    }

    const handleAtualizaQuantidadeEtiquetaComplemento = (produto, idEt, acao) => {
        const etiqueta = produto._Etiquetas.filter(etq => etq.IdEtiqueta === idEt)[0];

        if (acao === 'add') {
            if ((etiqueta?.EtiquetaComplementoSelecionada?.iQuantidadeVendida || 0) < etiqueta.EtiquetaComplementoSelecionada?.iQuantidade) {
                etiqueta.EtiquetaComplementoSelecionada.iQuantidadeVendida += 1;
                handleSelectedEtiquetas(produto, etiqueta, 'add');
            }
        }
        else if (acao === 'removeTroca') {
            etiqueta.EtiquetaComplementoSelecionada.iQuantidadeVendida = 0;
            handleSelectedEtiquetas(produto, etiqueta, 'remove');
        }
        else if (etiqueta.EtiquetaComplementoSelecionada?.iQuantidadeVendida > 0) {
            etiqueta.EtiquetaComplementoSelecionada.iQuantidadeVendida -= 1;
            handleSelectedEtiquetas(produto, etiqueta, 'remove');
        }
    }

    const handleSelectedEtiquetas = (produto, selectedEtq, action) => {
        const produtos = [...produtosSelecionados];
        if (action === "add") {
            selectedEtq._Imagem = { LocalIIS: produto.LocalIIS };

            //Pegar somente a etiqueta complemento selecionada
            selectedEtq._EtiquetaComplemento = selectedEtq._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === selectedEtq?.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento);


            if (produtos.length > 0) {
                const idxProdutos = produtos.findIndex(a => a.idImagem === produto.idImagem);
                if (idxProdutos >= 0) {
                    const idxEtiqueta = produtos[idxProdutos]._Etiquetas.findIndex(a => a.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento === selectedEtq?.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento);
                    if (idxEtiqueta >= 0) {
                        produtos[idxProdutos]._Etiquetas[idxEtiqueta] = selectedEtq;
                        setProdutosSelecionados(produtos);
                        return;
                    }
                }
            }
            setProdutosSelecionados([...produtos, produto]);
        }
        else if (action === "remove" && selectedEtq.EtiquetaComplementoSelecionada?.iQuantidadeVendida > 0) {
            selectedEtq._Imagem = { LocalIIS: produto.LocalIIS };

            //Pegar somente a etiqueta complemento selecionada
            selectedEtq._EtiquetaComplemento = selectedEtq._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === selectedEtq?.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento);

            if (produtosSelecionados.length > 0) {
                produtosSelecionados.map((item, i) => {
                    item._Etiquetas.map((etiqueta, j) => {
                        if (etiqueta.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento === selectedEtq?.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento) {
                            produtos[i]._Etiquetas[j] = selectedEtq;
                            setProdutosSelecionados(produtos);
                        }
                    });
                });
            }
        }
        else if (action === "remove" && selectedEtq.EtiquetaComplementoSelecionada?.iQuantidadeVendida === 0) {
            setProdutosSelecionados(produtos.filter((prod) => prod.IdImagem === produto.IdImagem && prod._Etiquetas[0].EtiquetaComplementoSelecionada?.IdEtiquetaComplemento !== selectedEtq.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento));
        }
    };

    const handleSelecionarProduto = async (produto, idEt, idEtc, acao = 'add', buscarProdutos = false, repetir = 1) => {
        let possuiItemCarrinho = false;

        //Verificar se produto existe no produtos selecionados
        produtosSelecionados.forEach((item) => {
            if (!possuiItemCarrinho)
                possuiItemCarrinho = item._Etiquetas.filter(etiqueta => etiqueta?.EtiquetaComplementoSelecionada?.IdEtiquetaComplemento === idEtc).length > 0;
        });

        if (!possuiItemCarrinho) {
            //Cria a property EtiquetaComplementoSelecionada com os dados da etiqueta complemento
            produto = handleCreateEtiquetaComplemento(produto, idEt, idEtc);
        }
        else {
            const produtoProdutoSelecionado = produtosSelecionados.filter(a => a._Etiquetas.filter(etq => etq.IdEtiqueta === idEt).length > 0)[0];
            produto = produtoProdutoSelecionado;
        }

        if (repetir > 1) {
            for (let index = 0; index < repetir; index++) {
                handleAtualizaQuantidadeEtiquetaComplemento(produto, idEt, acao);
            }
        }
        else {
            handleAtualizaQuantidadeEtiquetaComplemento(produto, idEt, acao);
        }

        setSearchProduto(() => '');
        setQtdProduto(1);

        if (buscarProdutos) setRandomSearch(Math.random());
    }

    const calculateTotalEtiqueta = (etq) => {
        const valorEtiqueta = (etq?.EtiquetaComplementoSelecionada?.iQuantidadeVendida || 1) * (etq.mValor - (etq.mValor * ((etq?.mPorcentoDesconto || 0) / 100)));
        return valorEtiqueta;
    };

    const handleFinalizarVenda = async () => {
        const valorDinheiro = Number(dadosVenda.formaPagamento.dinheiro);
        const valorPix = Number(dadosVenda.formaPagamento.pix);
        const valorCartaoCredito = Number(dadosVenda.formaPagamento.cartaoCredito);
        const valorCartaoDebito = Number(dadosVenda.formaPagamento.cartaoDebito);

        setLoadingFinalizar(true);
        let objVenda = {
            IdPedido: 0,
            IdCarrinho: null,
            IdTipoPagamento: 5, //Multiplos
            IdUsuarioComprador: dadosVenda.idCliente,
            IdUsuarioVendedor: userLoggedIn.IdUsuario,
            IdServicoEntrega: 3, //Combinar
            IdAgenciaEntrega: null,
            NomeServicoEntrega: "Combinar",
            NomeAgenciaEntrega: "",
            FlgLoja: true,
            FlgCompraPresencial: true,
            NomeVendedorPresencial: dadosVenda.nomeVendedor,
            Observacao: dadosVenda.observacao,
            DataVenda: dadosVenda.dataVenda,
            Cupom: dadosVenda.cupom,
            ValorDescontoTela: dadosVenda.formaPagamento.desconto || 0,
            ValorAcrescimoTela: dadosVenda.formaPagamento.acrescimo || 0,
            valorTotal: dadosVenda.valorTotal,
            _TipoEntregaValores: {
                Id: 3,
                TipoEntrega: 'Combinar',
                ValorEntrega: 0,
                DataEntrega: null,
            },
            _Etiquetas: dadosVenda.produtos.map((produto) => {
                return produto._Etiquetas.map((etq) => {
                    return {
                        IdEtiqueta: etq.IdEtiqueta,
                        IdImagem: etq.idImagem,
                        iQuantidadeVendida: etq.EtiquetaComplementoSelecionada?.iQuantidadeVendida,
                        mValor: calculateTotalEtiqueta(etq),
                        _EtiquetaComplemento: [etq.EtiquetaComplementoSelecionada]
                    };
                })[0];
            }),
        };

        const formasPagamento = [];
        if (valorCartaoCredito > 0) {
            formasPagamento.push({ IdTipoPagamento: 1, QtdParcelas: 0, Valor: valorCartaoCredito, QtdParcelas: dadosVenda.quantidadeParcelas, FlgAtivo: true });
        }
        if (valorPix > 0) {
            formasPagamento.push({ IdTipoPagamento: 2, QtdParcelas: 0, Valor: valorPix, FlgAtivo: true });
        }
        if (valorDinheiro > 0) {
            formasPagamento.push({ IdTipoPagamento: 3, QtdParcelas: 0, Valor: valorDinheiro, FlgAtivo: true });
        }
        if (valorCartaoDebito > 0) {
            formasPagamento.push({ IdTipoPagamento: 6, QtdParcelas: 0, Valor: valorCartaoDebito, FlgAtivo: true });
        }

        objVenda = { ...objVenda, _MultiplosPagamentos: formasPagamento };

        try {
            const result = await PUT_DATA(`Pedido/InserirPedido`, objVenda);
            if (result.statusRetorno !== '200') {
                dispatch({
                    type: CONTROLEMENSAGEM_ERRO,
                    tipoComponente: 'alert',
                    titulo: 'Erro',
                    message: result.mensagem,
                });
            }
            else {
                const objVenda = { ...dadosVenda, idPedido: result.objetoRetorno.IdPedido, idAcaoExecutada: 4, idAcaoLiberada: 4, vendaFinalizada: true };
                setStorage('shoppingCart', JSON.stringify(objVenda));
                dispatch({ type: SHOPING_CART_SET, value: objVenda });
                setIdAcaoExecutada(4);
                setIdAcaoLiberada(4);
                setVendaFinalizada(true);
                setQrCode('');
                setRawPix('');

                await POST_DATA(`Pedido/UpdateStatusPedido?idPedido=${result.objetoRetorno.IdPedido}&idStatus=4`); //4 = Entregue
                await POST_DATA(`Log/InsertLogModulosUsuario?idUsuario=${userLoggedIn.IdUsuario}&sModulo=Venda PDV&sDescricao=Realizada venda ${result.objetoRetorno.IdPedido}&IdPedido=${result.objetoRetorno.IdPedido}`);
            }
            setLoadingFinalizar(false);
        }
        catch (err) {
            setVendaFinalizada(false);
            setLoadingFinalizar(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro ao finalizar o pedido',
                message: err?.message,
            });
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleGerarPix = async () => {
        const valor = Number(dadosVenda.formaPagamento.pix);
        if (valor <= 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Valor precisa ser maior que R$0,00 para gerar o pix.',
            });
            return;
        }

        const totalPagar = Number(Number(dadosVenda.valorTotal).toFixed(2));
        const acrescimo = Number(dadosVenda.formaPagamento.acrescimo);

        const valorDinheiro = Number(dadosVenda.formaPagamento.dinheiro);
        const valorPix = Number(dadosVenda.formaPagamento.pix);
        const valorCartaoCredito = Number(dadosVenda.formaPagamento.cartaoCredito);
        const valorCartaoDebito = Number(dadosVenda.formaPagamento.cartaoDebito);
        const totalPagoOutrasFormas = valorDinheiro + valorCartaoCredito + valorCartaoDebito;
        const diferenca = totalPagar + acrescimo - totalPagoOutrasFormas;

        if (valorPix.toFixed(2) > totalPagar) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Valor do pix não pode ser superior ao total à pagar!',
            });
            return;
        }
        if (valorPix.toFixed(2) > diferenca.toFixed(2)) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Valor do pix não pode ser superior a soma das demais formas de pagamento e o total à pagar!',
            });
            return;
        }

        if (dadosBancarios === null || dadosBancarios.ChavePix === null || dadosBancarios.ChavePix.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'É necessário configurar o pix nos dados bancários',
            });
            return;
        }

        if (dadosUsuarioLoja?._UsuarioEmpresa?.CEP?.length === 0 || dadosUsuarioLoja?._UsuarioEmpresa?.Cidade?.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'É necessário configurar os dados de endereço da empresa',
            });
            return;
        }

        let pix = dadosBancarios.ChavePix;
        if (dadosBancarios.TipoPix === 'Telefone') pix = `+55${pix}`;

        let cidade = dadosUsuarioLoja?._UsuarioEmpresa?.Cidade;

        if (cidade.includes(' da ') || cidade.includes(' do ')) {
            cidade = dadosUsuarioLoja?._UsuarioEmpresa?.Cidade.split(' ')[0];
        }

        const dadosQrCode = {
            pixkey: pix.toString(),
            merchant: userLoggedIn.NomeFantasia,
            city: cidade,
            cep: dadosUsuarioLoja?._UsuarioEmpresa?.CEP.replace('-', ''),
            code: `${dadosUsuarioLoja.MeuCodigoIndicacao}${moment().format('YYYY-MM-DD HH:mm:ss')}`,
            amount: Number(dadosVenda.formaPagamento.pix.replace(',', '.'))
        };

        setQrCode(dadosQrCode);
    }

    const handleAtualizarValoresFormaPagamento = (property, valor) => {
        const valoresFormaPagamento = { ...dadosVenda.formaPagamento };
        let totalPagar = Number(dadosVenda.valorTotal.toString().replace(',', '.'));

        const valorTotalBase = dadosVenda.valorProdutos + Number(valoresFormaPagamento.acrescimo || 0) - Number(valoresFormaPagamento.desconto || 0);

        if (property !== null && valor !== null) {
            valor = valor.toString().replace(',', '.').replace(/^0+/, '');
            valoresFormaPagamento[property] = valor;

            if (property === 'cupom' && cupomDesconto !== null) {
                totalPagar = cupomDesconto.FlgPorcentagem ? (valorTotalBase - (valorTotalBase * (cupomDesconto.Valor / 100))) : (valorTotalBase - cupomDesconto.Valor);
            }
            else if (cupomDesconto !== null) {
                if (property === 'desconto') {
                    totalPagar = dadosVenda.valorProdutos + Number(valoresFormaPagamento.acrescimo || 0) - Number(valor);
                    totalPagar = cupomDesconto.FlgPorcentagem ? (totalPagar - (totalPagar * (cupomDesconto.Valor / 100))) : (totalPagar - cupomDesconto.Valor);
                }
                if (property === 'acrescimo') {
                    totalPagar = dadosVenda.valorProdutos - Number(valoresFormaPagamento.desconto || 0) + Number(valor);
                    totalPagar = cupomDesconto.FlgPorcentagem ? (totalPagar - (totalPagar * (cupomDesconto.Valor / 100))) : (totalPagar - cupomDesconto.Valor);
                }
            }
            else {
                if (property === 'desconto') {
                    totalPagar = dadosVenda.valorProdutos + Number(valoresFormaPagamento.acrescimo || 0) - Number(valor);
                }
                if (property === 'acrescimo') {
                    totalPagar = dadosVenda.valorProdutos - Number(valoresFormaPagamento.desconto || 0) + Number(valor);
                }
            }
        }
        else if (property === 'cupom') {
            totalPagar = cupomDesconto.FlgPorcentagem ? (valorTotalBase - (valorTotalBase * (cupomDesconto.Valor / 100))) : (valorTotalBase - cupomDesconto.Valor);
        }

        const valorDinheiro = Number((valoresFormaPagamento.dinheiro || 0).toString().replace(',', '.'));
        const valorPix = Number((valoresFormaPagamento.pix || 0).toString().replace(',', '.'));
        const valorCartaoCredito = Number((valoresFormaPagamento.cartaoCredito || 0).toString().replace(',', '.'));
        const valorCartaoDebito = Number((valoresFormaPagamento.cartaoDebito || 0).toString().replace(',', '.'));

        let idFormaPagamento = 0;
        if (valorCartaoCredito > 0) idFormaPagamento = 1;
        else if (valorPix > 0) idFormaPagamento = 2;
        else if (valorDinheiro > 0) idFormaPagamento = 3;
        else if (valorCartaoDebito > 0) idFormaPagamento = 4;

        const soma = valorDinheiro + valorPix + valorCartaoCredito + valorCartaoDebito;

        let troco = 0;

        if (soma > totalPagar) {
            troco = soma - totalPagar;
        }

        setDadosVenda({ ...dadosVenda, valorTotal: totalPagar, idFormaPagamento: idFormaPagamento, formaPagamento: { ...valoresFormaPagamento, troco: troco } });
    }

    const handleAplicarCupom = async () => {
        try {
            const cupom = dadosVenda.cupom?.trim();
            if (cupomDesconto !== null && cupomDesconto.Descricao.toUpperCase() == cupom.toUpperCase()) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Cupom já aplicado`,
                });
                return;
            }
            if (cupom.length === 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Favor informar o cupom`,
                });
                return;
            }

            const pedido = {
                IdUsuarioVendedor: userLoggedIn.IdUsuario,
                ValorEtiquetas: dadosVenda.valorProdutos,
                ValorTotal: dadosVenda.valorTotal,
                ValorFrete: 0
            }

            const result = await POST_DATA(`/Cupom/ValidaCupomxUsuarioPedido?idUsuario=${userLoggedIn.IdUsuario}&cupom=${cupom}`, pedido);

            if (result.objetoRetorno.FlgFrete) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Nãó é possível aplicar cupom do tipo frete.`,
                });
                return;
            }

            setCupomDesconto(() => result.objetoRetorno);
        }
        catch (err) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: err?.message,
            })
        }
    }

    return (
        <Container>
            {
                vendaNaoConcluida !== null &&
                <Confirmation
                    title="Última venda não concluída!"
                    mensagem='Existe uma venda não concluída, deseja retomar com a mesma?'
                    btn1={{
                        title: "Cancelar", onClick: () => {
                            removeStorage('shoppingCart');
                            return null;
                        }
                    }}
                    btn2={{
                        title: "Retomar",
                        onClick: () => {
                            const carrinhoSessao = vendaNaoConcluida;
                            const dataVenda = moment();
                            const objVenda = { ...carrinhoSessao, idVendedor: userLoggedIn.IdUsuario, nomeVendedor: userLoggedIn.NomeFantasia, dataVenda: new Date(dataVenda) }
                            if (userLoggedIn.IdUsuario === carrinhoSessao.idVendedor) {
                                setProdutosSelecionados(objVenda.produtos);
                                setDadosVenda(objVenda);
                                setIdAcaoExecutada(objVenda.idAcaoExecutada);
                                setIdAcaoLiberada(objVenda.idAcaoLiberada);
                                setVendaFinalizada(objVenda.vendaFinalizada);
                            }
                            else {
                                setDadosVenda(objVenda);
                                setProdutosSelecionados([]);
                                dispatch({ type: SHOPING_CART_SET, value: objVenda });
                                setStorage('shoppingCart', JSON.stringify(objVenda));
                            }
                        }
                    }}
                />
            }

            {
                openModalPesquisaCliente &&
                <Modal
                    open={openModalPesquisaCliente}
                    onClose={() => setOpenModalPesquisaCliente(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalContainer>
                        <BootstrapDialogTitle id={'modalClientes'} onClose={() => setOpenModalPesquisaCliente(false)}>
                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>Clientes</Box>
                        </BootstrapDialogTitle>
                        <Box style={{ width: '100%' }}>
                            <TextField
                                label="Nome Cliente"
                                placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                                size="small"
                                sx={{ width: "300px" }}
                                value={searchCliente}
                                onChange={(e) => {
                                    setSearchCliente(e.target.value);
                                }}
                                onKeyDownCapture={(e) => {
                                    if (e.key === 'Enter') {
                                        fetchClientes();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: searchProduto.length > 0 && <InputAdornment
                                        style={{ cursor: 'pointer' }}
                                        position="end"
                                        onClick={async () => {
                                            setSearchCliente(() => '');
                                            await fetchClientes('');
                                        }}
                                    >
                                        <ClearIcon />
                                    </InputAdornment>
                                }}
                            />
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ height: 43, marginLeft: 10 }}
                                onClick={async () => {
                                    fetchClientes();
                                }}
                            >
                                Pesquisar
                            </Button>
                        </Box>
                        <Box style={{ width: '100%', maxHeight: 400, overflowY: 'auto', marginTop: 15 }}>
                            {loadingClientes ? (
                                <Stack
                                    sx={{ height: "150px" }}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CircularProgress />
                                    <Typography sx={{ mt: 1 }}>Carregando clientes</Typography>
                                </Stack>
                            ) : (
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columnsClientes.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth: column.minWidth,
                                                            maxWidth: column.maxWidth,
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {clientes.map(cliente => {
                                                return <TableRow key={cliente.IdUsuario}>
                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                        {cliente.Nome}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                        {cliente.Cpf}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                        <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}>
                                                            <span>{cliente.Email}</span>
                                                            <span>{cliente.Celular}</span>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                        <Button
                                                            onClick={() => navigate('/edicao-cliente', { state: { pdv: true, idUsuario: cliente.IdUsuario } })}
                                                            variant="outlined"
                                                            style={{ marginBottom: 5, width: 120 }}
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={async () => {
                                                                const resultCliente = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${cliente.IdUsuario}&join=true&Log=`);

                                                                if (resultCliente._Endereco === null || resultCliente._Endereco.length === 0) {
                                                                    dispatch({
                                                                        type: CONTROLEMENSAGEM_AVISO,
                                                                        tipoComponente: 'alert',
                                                                        titulo: 'Aviso',
                                                                        message: 'É necessário atualizar os dados de endereço do cliente',
                                                                    });
                                                                    return;
                                                                }

                                                                setDadosVenda({ ...dadosVenda, cliente: resultCliente, idCliente: cliente.IdUsuario, nomeCliente: cliente.Nome });
                                                                setOpenModalPesquisaCliente(false);
                                                                setSearchCliente('');
                                                                await fetchClientes('');
                                                            }}
                                                        >Selecionar</Button>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                    {clientes.length === 0 && (
                                        <Stack
                                            sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                            <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                                Nenhum resultado encontrado.
                                            </Typography>
                                        </Stack>
                                    )}
                                </TableContainer>
                            )}
                        </Box>
                    </ModalContainer>
                </Modal>
            }

            {
                openModalFinalizarVenda &&
                <Modal
                    open={openModalFinalizarVenda}
                    onClose={() => setOpenModalFinalizarVenda(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalContainer style={{ background: '#f9f9f9', flexDirection: 'column', maxWidth: 600, maxHeight: 580 }}>
                        {
                            !vendaFinalizada &&
                            <>
                                <BootstrapDialogTitle id={'modalFinalizarVenda'} onClose={() => setOpenModalFinalizarVenda(false)}>
                                    <Box sx={{ fontSize: 20, marginLeft: -2 }}>
                                        <h3 style={{ color: '#d35393' }}>Finalizar venda</h3>
                                        <Divider />
                                    </Box>
                                </BootstrapDialogTitle>
                                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Box style={{ width: 400, background: 'white', borderRadius: 5 }}>
                                        <Typography style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                                            {Number(Number(dadosVenda.valorTotal || 0).toFixed(2)).toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })}
                                        </Typography>
                                    </Box>
                                    <TextField
                                        label="Valor"
                                        size="small"
                                        sx={{ width: "400px", marginTop: 2, fontWeight: 'bold' }}
                                        value={Number(Number(dadosVenda.valorTotal || 0).toFixed(2)).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                        InputLabelProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
                                        InputProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
                                    />

                                    <Divider style={{ marginTop: 10, marginBottom: 10, background: 'black' }} />
                                    <TextField
                                        readOnly
                                        label="Dinheiro"
                                        size="small"
                                        sx={{ width: "400px", marginTop: 1 }}
                                        value={Number(dadosVenda?.formaPagamento?.dinheiro || 0).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    />
                                    <TextField
                                        readOnly
                                        label="Pix"
                                        size="small"
                                        sx={{ width: "400px", marginTop: 2 }}
                                        value={Number(dadosVenda?.formaPagamento?.pix || 0).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    />
                                    <TextField
                                        readOnly
                                        label="Cartão de crédito"
                                        size="small"
                                        sx={{ width: "400px", marginTop: 2 }}
                                        value={Number(dadosVenda?.formaPagamento?.cartaoCredito || 0).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    />
                                    <TextField
                                        readOnly
                                        label="Cartão de débito"
                                        size="small"
                                        sx={{ width: "400px", marginTop: 2 }}
                                        value={Number(dadosVenda?.formaPagamento?.cartaoDebito || 0).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    />

                                    {
                                        dadosVenda.idFormaPagamento == 1 &&
                                        <TextField
                                            label="Número de parcelas cartão de crédito"
                                            size="small"
                                            sx={{ width: "400px", marginTop: 2 }}
                                            value={dadosVenda.quantidadeParcelas}
                                            onChange={(e) => {
                                                const parcelas = Number(e.target.value.replace(/([^\d])+/gim, ''));
                                                if (parcelas === 0) setDadosVenda({ ...dadosVenda, quantidadeParcelas: 1 });
                                                else setDadosVenda({ ...dadosVenda, quantidadeParcelas: parcelas });
                                            }}
                                            InputLabelProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
                                            InputProps={{ style: { fontSize: 18, fontWeight: 'bold' } }}
                                            inputProps={{
                                                maxLength: 2,
                                            }}
                                        />
                                    }
                                </Box>
                                <Box style={{ width: 400, marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        disabled={loadingFinalizar}
                                        readOnly={loadingFinalizar}
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        style={{ height: 43, marginLeft: 10 }}
                                        onClick={async () => {
                                            await handleFinalizarVenda();
                                        }}
                                    >
                                        {loadingFinalizar && <CircularProgress size={20} style={{ color: 'white', marginRight: 10 }} />}
                                        Finalizar
                                    </Button>
                                </Box>
                            </>
                        }
                        {
                            vendaFinalizada &&
                            <>
                                {
                                    loadingFinalizarVenda ? (
                                        <Stack
                                            sx={{ height: "150px" }}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress />
                                            <Typography sx={{ mt: 1 }}>Finalizando venda...</Typography>
                                        </Stack>
                                    ) : (
                                        <BootstrapDialogTitle id={'modalFinalizarVenda'} onClose={() => setOpenModalFinalizarVenda(false)}>
                                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>
                                                <h3 style={{ color: '#d35393' }}>VENDA CONCLUÍDA COM SUCESSO</h3>
                                                <Divider />
                                            </Box>
                                            <Box style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <TaskAltIcon style={{ fontSize: 180, color: '#d35393' }} />
                                            </Box>
                                        </BootstrapDialogTitle>
                                    )
                                }
                            </>
                        }
                    </ModalContainer>
                </Modal>
            }

            <Box style={{ width: "100%" }}>
                <Typography style={{ fontWeight: 'bold' }}>PDV / Caixa</Typography>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap', marginTop: 5 }}
                justifyContent="space-between"
            >
                <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid
                        item
                        xs={12}
                        md={2.9}
                        style={{ cursor: 'pointer', border: '1px solid #e3e3e3', padding: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pointerEvents: vendaFinalizada ? 'none' : null, backgroundColor: vendaFinalizada ? '#f2f2f2' : null }}
                        onClick={() => setIdAcaoExecutada(1)}
                    >
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <Box style={{ width: 60, display: 'flex' }}>
                                <Inventory2Icon style={{ fontSize: 60, color: '#d35393', marginRight: 45 }} />
                                <CheckCircleOutlineIcon style={{ marginLeft: 20 }} />
                            </Box>
                        </Box>
                        <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>Produtos</Typography>
                        {
                            idAcaoExecutada === 1 &&
                            <Box style={{ width: '100%', height: 5, background: '#d35393', marginTop: 5, marginBottom: -10 }} />
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={2.9}
                        style={{ cursor: 'pointer', border: '1px solid #e3e3e3', padding: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pointerEvents: vendaFinalizada || produtosSelecionados.length === 0 || idAcaoLiberada < 2 ? 'none' : null, backgroundColor: vendaFinalizada || produtosSelecionados.length === 0 || idAcaoLiberada < 2 ? '#f2f2f2' : null }}
                        onClick={() => setIdAcaoExecutada(2)}
                    >
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <Box style={{ width: 60, display: 'flex' }}>
                                <TextSnippetIcon style={{ fontSize: 60, color: idAcaoLiberada < 2 ? 'gray' : '#d35393', marginRight: 45 }} />
                                {idAcaoLiberada < 2 ? <PriorityHighIcon style={{ marginLeft: 20 }} /> : <CheckCircleOutlineIcon style={{ marginLeft: 20 }} />}
                            </Box>
                        </Box>
                        <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>Dados da venda</Typography>
                        {
                            idAcaoExecutada === 2 &&
                            <Box style={{ width: '100%', height: 5, background: '#d35393', marginTop: 5, marginBottom: -10 }} />
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={2.9}
                        style={{ cursor: 'pointer', border: '1px solid #e3e3e3', padding: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pointerEvents: vendaFinalizada || produtosSelecionados.length === 0 || idAcaoLiberada < 3 ? 'none' : null, backgroundColor: vendaFinalizada || produtosSelecionados.length === 0 || idAcaoLiberada < 3 ? '#f2f2f2' : null }}
                        onClick={() => setIdAcaoExecutada(3)}
                    >
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <Box style={{ width: 60, display: 'flex' }}>
                                <MonetizationOnIcon style={{ fontSize: 60, color: idAcaoLiberada < 3 ? 'gray' : '#d35393', marginRight: 45 }} />
                                {idAcaoLiberada < 3 ? <PriorityHighIcon style={{ marginLeft: 20 }} /> : <CheckCircleOutlineIcon style={{ marginLeft: 20 }} />}
                            </Box>
                        </Box>
                        <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>Pagamento</Typography>
                        {
                            idAcaoExecutada === 3 &&
                            <Box style={{ width: '100%', height: 5, background: '#d35393', marginTop: 5, marginBottom: -10 }} />
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={2.9}
                        style={{ cursor: 'pointer', border: '1px solid #e3e3e3', padding: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pointerEvents: produtosSelecionados.length === 0 || idAcaoLiberada < 4 ? 'none' : null, backgroundColor: produtosSelecionados.length === 0 || idAcaoLiberada < 4 ? '#f2f2f2' : null }}
                        onClick={() => setIdAcaoExecutada(4)}
                    >
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <Box style={{ width: 60, display: 'flex' }}>
                                <ProductionQuantityLimitsIcon style={{ fontSize: 60, color: idAcaoLiberada < 4 ? 'gray' : '#d35393', marginRight: 45 }} />
                                {idAcaoLiberada < 4 ? <PriorityHighIcon style={{ marginLeft: 20 }} /> : <CheckCircleOutlineIcon style={{ marginLeft: 20 }} />}
                            </Box>
                        </Box>
                        <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>Finalizar</Typography>
                        {
                            idAcaoExecutada === 4 &&
                            <Box style={{ width: '100%', height: 5, background: '#d35393', marginTop: 5, marginBottom: -10 }} />
                        }
                    </Grid>

                    {
                        idAcaoExecutada === 1 &&
                        <>
                            <Grid item xs={12} md={6.7} style={{ marginTop: 10, border: '1px solid #e3e3e3', padding: 10, maxHeight: 600, overflowY: 'auto' }}>
                                <Box style={{ width: '100%' }}>
                                    <Typography style={{ fontSize: 20 }}>Selecionar produtos</Typography>
                                    <Typography style={{ fontSize: 12, color: 'gray' }}>Escolha os produtos para iniciar a venda</Typography>
                                    <Divider style={{ marginTop: 10 }} />
                                </Box>
                                <Stack direction="row" gap={2} sx={{ marginTop: 2, alignItems: 'center' }}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={1}
                                            value={tipoFiltro}
                                            name="radio-buttons-group"
                                            onChange={(e) => { setTipoFiltro(Number(e.target.value)) }}
                                        >
                                            <FormControlLabel value={1} control={<Radio />} label="Nome" />
                                            <FormControlLabel value={2} control={<Radio />} label="Sku" />
                                        </RadioGroup>
                                    </FormControl>

                                    <TextField
                                        label="Qtd"
                                        placeholder={'Qtd'}
                                        size="small"
                                        sx={{ width: "70px" }}
                                        value={qtdProduto}
                                        inputProps={{ minLength: 1, maxLength: 3, inputMode: 'numeric', pattern: '[0-9]*' }}
                                        onChange={(e) => {
                                            if (e.target.value === '') setQtdProduto(1);
                                            if (e.target.value == '0') setQtdProduto(1);
                                            else setQtdProduto(e.target.value.replace(/[^0-9:]/g, "").trim())
                                        }}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                    />

                                    <TextField
                                        label="Pesquisar"
                                        placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                                        size="small"
                                        sx={{ width: "300px", marginLeft: -1 }}
                                        value={searchProduto}
                                        onChange={(e) => {
                                            setSearchProduto(e.target.value);
                                        }}
                                        onKeyDownCapture={(e) => {
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                fetchProdutos(true);
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: searchProduto.length > 0 && <InputAdornment
                                                style={{ cursor: 'pointer' }}
                                                position="end"
                                                onClick={() => {
                                                    setSearchProduto(() => '');
                                                    setRandomSearch(Math.random())
                                                }}
                                            >
                                                <ClearIcon />
                                            </InputAdornment>
                                        }}
                                    />
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        style={{ height: 43 }}
                                        onClick={async () => {
                                            if (page === 1) {
                                                await fetchProdutos();
                                            }
                                            else
                                                setPage(1);
                                        }}
                                    >
                                        Pesquisar
                                    </Button>
                                </Stack>
                                <Box style={{ width: '100%' }}>
                                    {loadingProdutos ? (
                                        <Stack
                                            sx={{ height: "150px" }}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress />
                                            <Typography sx={{ mt: 1 }}>Carregando produtos</Typography>
                                        </Stack>
                                    ) : (
                                        <>
                                            <TableContainer>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columnsProdutosDisponiveis.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{
                                                                        minWidth: column.minWidth,
                                                                        maxWidth: column.maxWidth,
                                                                    }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {produtosDisponiveis?.map(produto => {
                                                            return produto?._Etiquetas.map(et => {
                                                                return et._EtiquetaComplemento.reduce((acc, itemEtc) => {
                                                                    if (!acc.find(p => p.Sku === itemEtc.Sku)) {
                                                                        acc.push(itemEtc);
                                                                    }
                                                                    return acc;
                                                                }, []).map(etc => {
                                                                    return <TableRow key={etc.IdEtiquetaComplemento}>
                                                                        <TableCell align="left" style={{ minWidth: 100 }}>
                                                                            <img
                                                                                style={{ borderRadius: "8px", width: "80px", height: "120px", objectFit: 'cover' }}
                                                                                src={produto.LocalIIS}
                                                                                alt="Imagem do produto"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{ minWidth: 100 }}>
                                                                            <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}>
                                                                                <span>{et.sDescricao}</span>
                                                                                <span style={{ marginTop: 5, fontSize: 16, fontWeight: 550 }}>
                                                                                    {etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}/{etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'}
                                                                                </span>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{ minWidth: 150 }}>
                                                                            {etc?.Sku === null ? '-' : etc?.Sku}
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{ minWidth: 100 }}>
                                                                            <Box style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                                                <span style={{ fontWeight: 'bold', fontSize: 18, color: '#d35393' }}>{calculateTotalEtiqueta(et).toLocaleString("pt-BR", {
                                                                                    style: "currency",
                                                                                    currency: "BRL",
                                                                                })}</span>
                                                                                <span>
                                                                                    <span style={{ fontWeight: 'bold', color: etc.iQuantidade === 0 ? 'red' : 'black' }}>{etc.iQuantidade}</span> UN
                                                                                </span>

                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{ minWidth: 100 }}>
                                                                            <Button
                                                                                disabled={etc.iQuantidade <= 0}
                                                                                variant="outlined"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault()
                                                                                    handleSelecionarProduto(produto, et.IdEtiqueta, etc.IdEtiquetaComplemento, 'add', true);
                                                                                }}
                                                                            >
                                                                                <AddIcon />
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                })
                                                            })
                                                        })}
                                                    </TableBody>
                                                </Table>
                                                {produtosDisponiveis.length === 0 && (
                                                    <Stack
                                                        sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                                        <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                                            Nenhum resultado encontrado.
                                                        </Typography>
                                                    </Stack>
                                                )}
                                            </TableContainer>
                                            <Box style={{ width: '100%', marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                                                <Pagination
                                                    size="large"
                                                    color="primary"
                                                    showFirstButton
                                                    showLastButton
                                                    count={totalPages}
                                                    page={page}
                                                    onChange={handleChangePage}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5.1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10, border: '1px solid #e3e3e3', padding: 10, minHeight: 600, maxHeight: 600, overflowY: 'auto' }}>
                                <div>
                                    <Box style={{ width: '100%' }}>
                                        <Typography style={{ fontSize: 20 }}>Itens do pedido</Typography>
                                        <Divider style={{ marginTop: 10 }} />
                                    </Box>
                                    <Box style={{ width: '100%' }}>
                                        <TableContainer>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columnsProdutosSelecionados.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    minWidth: column.minWidth,
                                                                    maxWidth: column.maxWidth,
                                                                }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        produtosSelecionados.map(produto => {
                                                            return produto._Etiquetas.map(et => {
                                                                const etc = et.EtiquetaComplementoSelecionada;

                                                                if (etc === null || etc === undefined) return null;

                                                                return <TableRow key={etc.IdEtiquetaComplemento}>
                                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                                        <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}>
                                                                            <span>{et.sDescricao}</span>
                                                                            <span style={{ marginTop: 5, fontSize: 16, fontWeight: 550 }}>
                                                                                {etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}/{etc?.Cor?.Descricao ? etc?.Cor?.Descricao : '-'}

                                                                            </span>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                                        {etc?.Sku === null ? '-' : etc?.Sku}
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ minWidth: 100 }}>
                                                                        <TextField
                                                                            type="number"
                                                                            id={`quantidadeVenda_${et.IdEtiqueta}`}
                                                                            style={{ color: 'black' }}
                                                                            variant="outlined"
                                                                            readOnly={true}
                                                                            value={et?.EtiquetaComplementoSelecionada?.iQuantidadeVendida || 0}
                                                                            InputProps={{
                                                                                style: { fontWeight: 'bold', width: 90, color: 'black', textAlign: 'center' },
                                                                                inputProps: { min: 0, max: etc?.iQuantidade },
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <Remove sx={{ width: 15, marginLeft: '-7px', cursor: 'pointer' }} onClick={() => handleSelecionarProduto(produto, et.IdEtiqueta, etc.IdEtiquetaComplemento, 'remove')} />
                                                                                    </InputAdornment>
                                                                                ),
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <Add sx={{ width: 15, marginRight: '-7px', cursor: 'pointer' }} onClick={() => handleSelecionarProduto(produto, et.IdEtiqueta, etc.IdEtiquetaComplemento, 'add')} />
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ minWidth: 140 }}>
                                                                        <Box style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                                            {
                                                                                et?.mPorcentoDesconto > 0 &&
                                                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <Typography sx={{ textDecoration: 'line-through', fontWeight: 'bold', color: 'gray !important', fontSize: 12, textAlign: 'start' }}>
                                                                                        {et?.EtiquetaComplementoSelecionada?.iQuantidadeVendida === 0 && et?.mValor?.toLocaleString("pt-BR", {
                                                                                            style: "currency",
                                                                                            currency: "BRL",
                                                                                        })}
                                                                                        {et?.EtiquetaComplementoSelecionada?.iQuantidadeVendida > 0 && (et.EtiquetaComplementoSelecionada?.iQuantidadeVendida * Number(et.mValor))?.toLocaleString("pt-BR", {
                                                                                            style: "currency",
                                                                                            currency: "BRL",
                                                                                        })}
                                                                                    </Typography>
                                                                                    <span style={{ fontWeight: 'bold', fontSize: 12, color: '#d35393' }}>({et?.mPorcentoDesconto}%)</span>
                                                                                </Box>
                                                                            }
                                                                            <span style={{ fontWeight: 'bold', fontSize: 18, color: '#d35393' }}>{calculateTotalEtiqueta(et).toLocaleString("pt-BR", {
                                                                                style: "currency",
                                                                                currency: "BRL",
                                                                            })}</span>
                                                                            <span>
                                                                                <span style={{ fontWeight: 'bold', color: etc?.iQuantidade === 0 ? 'red' : 'black' }}>{etc?.iQuantidade}</span> UN
                                                                            </span>

                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell align="left" style={{ minWidth: 30 }}>
                                                                        <DeleteOutlineIcon onClick={() => handleAtualizaQuantidadeEtiquetaComplemento(produto, et.IdEtiqueta, 'removeTroca')} style={{ fontSize: 30, cursor: 'pointer' }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                            {produtosSelecionados.length === 0 && (
                                                <Stack
                                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                                    direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                                        Nenhum produto selecionado.
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </TableContainer>
                                    </Box>
                                </div>
                                <Box style={{ width: '100%', height: 70, padding: 10, background: '#d35393', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box style={{ background: 'white', width: 100, fontSize: 16, borderRadius: 10, padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography>Quantidade</Typography>
                                        <span>{quantidadeTotal}</span>
                                    </Box>
                                    <Box style={{ background: 'white', width: 140, fontSize: 16, borderRadius: 10, padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography>Valor</Typography>
                                        <span>{valorTotal}</span>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    }

                    {
                        idAcaoExecutada === 2 &&
                        <Grid item xs={12} style={{ paddingLeft: 18 }}>
                            <Grid container spacing={2} style={{ marginTop: 10, border: '1px solid #e3e3e3', padding: 10, maxHeight: 600, overflowY: 'auto' }}>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: 20 }}>Informações da Venda</Typography>
                                    <Divider />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        disabled
                                        label="Cliente"
                                        placeholder='Selecione o cliente'
                                        size="small"
                                        sx={{ width: "100%" }}
                                        value={dadosVenda.nomeCliente}
                                        InputProps={{
                                            endAdornment: <InputAdornment
                                                style={{ cursor: 'pointer' }}
                                                position="end"
                                            >
                                                <Box style={{ display: 'flex', cursor: 'pointer' }}>
                                                    <SearchIcon onClick={() => { setOpenModalPesquisaCliente(true); }} />
                                                    <AddIcon onClick={() => navigate('/edicao-cliente', { state: { pdv: true } })} />
                                                </Box>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        disabled
                                        label="Vendedor"
                                        placeholder='Selecione o vendedor'
                                        size="small"
                                        sx={{ width: "100%" }}
                                        value={dadosVenda.nomeVendedor || ''}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            id='dataInicio'
                                            format="dd/MM/yyyy"
                                            label='Data venda'
                                            value={dadosVenda.dataVenda}
                                            onChange={(newValue) => {
                                                setDadosVenda({ ...dadosVenda, dataVenda: newValue })
                                            }}
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ width: '100%' }}
                                        />
                                    </LocalizationProvider>

                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        multiline
                                        minRows={4}
                                        maxRows={8}
                                        label="Observaçao"
                                        placeholder='Informe a observação'
                                        size="small"
                                        sx={{ width: "100%" }}
                                        value={dadosVenda.observacao}
                                        onChange={(e) => setDadosVenda({ ...dadosVenda, observacao: e.target.value })}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    }

                    {
                        idAcaoExecutada === 3 &&
                        <Grid item xs={12} style={{ paddingLeft: 18 }}>
                            <Grid container spacing={2} style={{ marginTop: 10, border: '1px solid #e3e3e3', padding: 10, maxHeight: 600, overflowY: 'auto' }}>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: 20 }}>Finalização do pagamento</Typography>
                                    <Typography style={{ fontSize: 12, color: 'gray' }}>Escolha a forma de pagamento para finalizar a compra</Typography>
                                    <Divider style={{ marginTop: 10 }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Box>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Valor dos produtos</Typography>
                                        <Typography style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}>{dadosVenda.valorProdutos.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}</Typography>

                                        <TextField
                                            label="Acréscimo (R$)"
                                            size="small"
                                            sx={{ width: "100%", marginTop: 1 }}
                                            value={(dadosVenda?.formaPagamento?.acrescimo || 0).toString().replace('.', ',')}
                                            onFocus={event => {
                                                event.target.select();
                                            }}
                                            onChange={(e) => {
                                                const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim();
                                                if (Number(valor) < 0) {
                                                    setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, acrescimo: 0 } });
                                                }
                                                handleAtualizarValoresFormaPagamento('acrescimo', valor);
                                            }}
                                        />
                                        <TextField
                                            label="Desconto (R$)"
                                            size="small"
                                            sx={{ width: "100%", marginTop: 1 }}
                                            value={(dadosVenda?.formaPagamento?.desconto || 0).toString().replace('.', ',')}
                                            onFocus={event => {
                                                event.target.select();
                                            }}
                                            onChange={(e) => {
                                                const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim();
                                                if (Number(valor) < 0) {
                                                    setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, desconto: 0 } });
                                                }
                                                handleAtualizarValoresFormaPagamento('desconto', valor);
                                            }}
                                        />
                                        <TextField
                                            label="Cupom"
                                            size="small"
                                            sx={{ width: "100%", marginTop: 1 }}
                                            value={dadosVenda?.cupom}
                                            onFocus={event => {
                                                event.target.select();
                                            }}
                                            onChange={(e) => {
                                                setDadosVenda({ ...dadosVenda, cupom: e.target.value });
                                            }}
                                            onKeyDownCapture={(e) => {
                                                if (e.key === 'Enter' || e.key === 'Tab') {
                                                    handleAplicarCupom();
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Button
                                                        variant="contained"
                                                        aria-label="Aplicar"
                                                        onClick={() => handleAplicarCupom()}
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        Aplicar
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        aria-label="Aplicar"
                                                        onClick={() => {
                                                            setDadosVenda({ ...dadosVenda, cupom: '' });
                                                            setCupomDesconto(null);
                                                        }}
                                                        style={{ marginLeft: -10, marginRight: -20 }}
                                                    >
                                                        <CloseIcon style={{ fontSize: 22 }} />
                                                    </Button>
                                                </InputAdornment>
                                            }}
                                        />

                                        <Divider style={{ marginTop: 30 }} />
                                        {
                                            cupomDesconto !== null &&
                                            <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Total à pagar</Typography>
                                                <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: 14, textDecoration: 'line-through' }}>{(dadosVenda.valorProdutos - (Number(dadosVenda.formaPagamento?.desconto) || 0) + (Number(dadosVenda.formaPagamento?.acrescimo) || 0)).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}</Typography>
                                                <Typography style={{ color: '#ff8e00', fontWeight: 'bold', fontSize: 18 }}>{Number(Number(dadosVenda.valorTotal || 0).toFixed(2)).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}</Typography>
                                            </Box>
                                        }
                                        {
                                            cupomDesconto === null &&
                                            <Box style={{ marginTop: 10 }}>
                                                <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Total à pagar</Typography>
                                                <Typography style={{ color: '#ff8e00', fontWeight: 'bold', fontSize: 18 }}>{dadosVenda.valorTotal.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={0.5} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider orientation='vertical' style={{ width: 1.316, opacity: 0.2, height: '100%', color: 'black', background: 'black' }} />
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>Formas de pagamento</Typography>

                                    <TextField
                                        label="Dinheiro"
                                        size="small"
                                        sx={{ width: "100%", marginTop: 1 }}
                                        value={(dadosVenda?.formaPagamento?.dinheiro || 0).toString().replace('.', ',')}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        onChange={(e) => {
                                            const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim();
                                            if (Number(valor) < 0) {
                                                setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, dinheiro: 0 } });
                                            }
                                            handleAtualizarValoresFormaPagamento('dinheiro', valor);
                                        }}
                                    />
                                    <TextField
                                        label="Pix"
                                        size="small"
                                        sx={{ width: "100%", marginTop: 1 }}
                                        value={(dadosVenda?.formaPagamento?.pix || 0).toString().replace('.', ',')}
                                        onChange={(e) => {
                                            const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim();
                                            if (Number(valor) < 0) {
                                                setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, pix: 0 } });
                                            }

                                            handleAtualizarValoresFormaPagamento('pix', valor);
                                        }}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Button
                                                    variant="contained"
                                                    aria-label="Gerar PIX"
                                                    onClick={() => handleGerarPix()}
                                                    style={{ fontSize: 12 }}
                                                >
                                                    Gerar
                                                </Button>
                                            </InputAdornment>
                                        }}
                                    />
                                    <TextField
                                        label="Cartão de crédito"
                                        size="small"
                                        sx={{ width: "100%", marginTop: 1 }}
                                        value={(dadosVenda?.formaPagamento?.cartaoCredito || 0).toString().replace('.', ',')}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        onChange={(e) => {
                                            const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim();
                                            if (Number(valor) < 0) {
                                                setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, cartaoCredito: 0 } });
                                            }

                                            handleAtualizarValoresFormaPagamento('cartaoCredito', valor);
                                        }}
                                    />
                                    <TextField
                                        label="Cartão de débito"
                                        size="small"
                                        sx={{ width: "100%", marginTop: 1 }}
                                        value={(dadosVenda?.formaPagamento?.cartaoDebito || 0).toString().replace('.', ',')}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        onChange={(e) => {
                                            const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim();
                                            if (Number(valor) < 0) {
                                                setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, cartaoDebito: 0 } });
                                            }

                                            handleAtualizarValoresFormaPagamento('cartaoDebito', valor);
                                        }}
                                    />

                                    <Divider style={{ marginTop: 10 }} />

                                    <Box style={{ marginTop: 3, marginTop: 10 }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Troco</Typography>
                                        <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{(dadosVenda?.formaPagamento?.troco || 0).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}</Typography>
                                    </Box>

                                </Grid>

                                {
                                    qrCode != null && qrCode != '' &&
                                    <Grid item xs={0.6} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Divider orientation='vertical' style={{ width: 1.316, opacity: 0.2, height: '100%', color: 'black', background: 'black' }} />
                                    </Grid>
                                }

                                <Grid item xs={3.4}>
                                    {
                                        qrCode != null && qrCode != '' &&
                                        <Box style={{ marginLeft: 10 }}>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 10 }}>Pix para pagamento</Typography>
                                            <PIX
                                                pixkey={qrCode.pixkey}
                                                merchant={qrCode.merchant}
                                                city={qrCode.city}
                                                cep={qrCode.cep}
                                                code={qrCode.code}
                                                amount={qrCode.amount}
                                                onLoad={payload => {
                                                    setRawPix(payload);
                                                }}
                                            />
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                                                <p
                                                    id='dadosPix'
                                                    style={{
                                                        maxWidth: '480px',
                                                        wordBreak: 'break-all'
                                                    }}
                                                >
                                                    {rawPix}
                                                </p>
                                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button variant="contained" style={{ marginTop: 10, background: 'black' }} onClick={() => {
                                                        navigator.clipboard.writeText(rawPix);
                                                    }}>Copiar</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {
                        idAcaoExecutada === 4 &&
                        <Grid item xs={12} style={{ paddingLeft: 18 }}>
                            <Grid container spacing={2} style={{ marginTop: 10, border: '1px solid #e3e3e3', padding: 10, maxHeight: 600, overflowY: 'auto' }}>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: 20 }}>{dadosVenda?.idPedido ? `#${dadosVenda?.idPedido} - ` : ''}Venda efetuada com sucesso</Typography>
                                    <Divider style={{ marginTop: 10 }} />
                                </Grid>
                                <Grid item xs={4} />
                                <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    onClick={() => {
                                        setAcaoExecutadaFinalizarVenda(1);
                                        alert('Item não disponível');
                                    }}
                                >
                                    <Box style={{ width: '100%', height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap', cursor: 'pointer', border: '1px solid #e3e3e3', background: acaoExecutadaFinalizarVenda === 1 ? '#d35393' : 'white', color: acaoExecutadaFinalizarVenda === 1 ? 'white' : 'black' }}>
                                        <LocalPrintshopIcon style={{ fontSize: 60, color: acaoExecutadaFinalizarVenda === 1 ? 'white' : '#d35393' }} />
                                        <Typography style={{ fontWeight: 'bold' }}>Imprimir recibo</Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    onClick={() => {
                                        setAcaoExecutadaFinalizarVenda(2);
                                        alert('Baixar recibo');
                                    }}
                                >
                                    <Box style={{ width: '100%', height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap', cursor: 'pointer', border: '1px solid #e3e3e3', background: acaoExecutadaFinalizarVenda === 2 ? '#d35393' : 'white', color: acaoExecutadaFinalizarVenda === 2 ? 'white' : 'black' }}>
                                        <CloudDownloadIcon style={{ fontSize: 60, color: acaoExecutadaFinalizarVenda === 2 ? 'white' : '#d35393' }} />
                                        <Typography style={{ fontWeight: 'bold' }}>Baixar recibo</Typography>
                                    </Box>
                                </Grid>
                                {/* <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    onClick={() => {
                                        setAcaoExecutadaFinalizarVenda(3);
                                        alert('Emitir NFC-e');
                                    }}
                                >
                                    <Box style={{ width: '100%', height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap', cursor: 'pointer', border: '1px solid #e3e3e3', background: acaoExecutadaFinalizarVenda === 3 ? '#d35393' : 'white', color: acaoExecutadaFinalizarVenda === 3 ? 'white' : 'black' }}>
                                        <FeedIcon style={{ fontSize: 60, color: acaoExecutadaFinalizarVenda === 3 ? 'white' : 'black' }} />
                                        <Typography style={{ fontWeight: 'bold' }}>Emitir NFC-e</Typography>
                                    </Box>
                                </Grid> */}
                                <Grid item xs={2} />
                            </Grid>
                        </Grid>
                    }

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: idAcaoExecutada === 1 || vendaFinalizada ? 'flex-end' : 'space-between' }}>
                        {
                            !vendaFinalizada && idAcaoExecutada > 1 && <Button variant="text" onClick={() => setIdAcaoExecutada(idAcaoExecutada - 1)}>Voltar</Button>
                        }

                        {
                            idAcaoExecutada === 1 &&
                            <Button variant="contained"
                                onClick={() => {
                                    if (produtosSelecionados.length === 0) {
                                        dispatch({
                                            type: CONTROLEMENSAGEM_AVISO,
                                            tipoComponente: 'alert',
                                            titulo: 'Aviso',
                                            message: 'É necessário selecionar ao menos um produto',
                                        });
                                        return;
                                    }

                                    const objVenda = { ...dadosVenda, idAcaoLiberada: 2, idAcaoExecutada: 2 };
                                    setIdAcaoExecutada(2);
                                    setIdAcaoLiberada(2);
                                    setStorage('shoppingCart', JSON.stringify(objVenda));
                                    dispatch({ type: SHOPING_CART_SET, value: objVenda });
                                }}
                                style={{ background: 'black', color: 'white' }}
                            >
                                Ir para Cliente
                            </Button>
                        }
                        {
                            idAcaoExecutada === 2 &&
                            <Button variant="contained" onClick={() => {
                                if (dadosVenda.idCliente === 0) {
                                    dispatch({
                                        type: CONTROLEMENSAGEM_AVISO,
                                        tipoComponente: 'alert',
                                        titulo: 'Aviso',
                                        message: 'É necessário selecionar o cliente',
                                    });
                                    return;
                                }

                                if (dadosVenda.dataVenda === null || dadosVenda.dataVenda === '') {
                                    dispatch({
                                        type: CONTROLEMENSAGEM_AVISO,
                                        tipoComponente: 'alert',
                                        titulo: 'Aviso',
                                        message: 'É necessário informar a data da venda',
                                    });
                                    return;
                                }

                                const objVenda = { ...dadosVenda, idAcaoLiberada: 3, idAcaoExecutada: 3 };
                                setIdAcaoExecutada(3);
                                setIdAcaoLiberada(3);

                                setStorage('shoppingCart', JSON.stringify(objVenda));
                                dispatch({ type: SHOPING_CART_SET, value: objVenda });
                            }}
                                style={{ background: 'black', color: 'white' }}
                            >Ir para Pagamento
                            </Button>
                        }
                        {
                            idAcaoExecutada === 3 &&
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    if (dadosVenda.idFormaPagamento === 0) {
                                        dispatch({
                                            type: CONTROLEMENSAGEM_AVISO,
                                            tipoComponente: 'alert',
                                            titulo: 'Aviso',
                                            message: 'É necessário selecionar a forma de pagamento',
                                        });
                                        return;
                                    }

                                    const totalPagar = Number(dadosVenda.valorTotal).toFixed(2);
                                    const valorDinheiro = Number(dadosVenda.formaPagamento.dinheiro);
                                    const valorPix = Number(dadosVenda.formaPagamento.pix);
                                    const valorCartaoCredito = Number(dadosVenda.formaPagamento.cartaoCredito);
                                    const valorCartaoDebito = Number(dadosVenda.formaPagamento.cartaoDebito);
                                    const totalPago = valorDinheiro + valorPix + valorCartaoCredito + valorCartaoDebito;

                                    let msg = '';
                                    if (totalPago < totalPagar) {
                                        msg += 'Valor de pagamento é inferior ao valor da compra!'
                                    }
                                    if (valorPix > totalPagar) {
                                        msg += '\nPix não possuí troco!'
                                    }
                                    if (valorCartaoCredito > totalPagar) {
                                        msg += '\nCartão de crédito não possuí troco!'
                                    }
                                    if (valorCartaoDebito > totalPagar) {
                                        msg += '\nCartão de débito não possuí troco!'
                                    }
                                    if (totalPago > totalPagar && (valorPix > 0 || valorCartaoCredito > 0 || valorCartaoDebito > 0)) {
                                        msg += '\nO valor informado para pagamento é superior ao valor devido!'
                                    }

                                    if (cupomDesconto !== null && (cupomDesconto.PorcentagemRepasseIndicacao === null || cupomDesconto.PorcentagemRepasseIndicacao === 0)) {
                                        msg += '\nCupom de desconto informado não possui percentual de repasse para o influencer. Valor deve ser supeior à R$0,00!'
                                    }

                                    if (msg.length > 0) {
                                        dispatch({
                                            type: CONTROLEMENSAGEM_AVISO,
                                            tipoComponente: 'alert',
                                            titulo: 'Aviso',
                                            message: msg,
                                        });
                                        return;
                                    }

                                    if (document.getElementById('dadosPix') !== null) {
                                        const dadosPix = document.getElementById('dadosPix').innerHTML;
                                        if (dadosPix)
                                            setDadosVenda({ ...dadosVenda, formaPagamento: { ...dadosVenda.formaPagamento, dadosPix: dadosPix } });
                                    }

                                    setOpenModalFinalizarVenda(true);
                                    const objVenda = { ...dadosVenda, idAcaoLiberada: 4, idAcaoExecutada: 4 };
                                    setStorage('shoppingCart', JSON.stringify(objVenda));
                                    dispatch({ type: SHOPING_CART_SET, value: objVenda });
                                }}
                                style={{ background: 'black', color: 'white' }}
                            >
                                Finalizar
                            </Button>
                        }
                        {
                            idAcaoExecutada === 4 &&
                            <>
                                <Button
                                    variant="text"
                                    style={{ marginRight: 5 }}
                                    onClick={async () => {
                                        removeStorage('shoppingCart');
                                        setDadosVenda({ ...INITIAL_SALE, idVendedor: userLoggedIn.IdUsuario, nomeVendedor: userLoggedIn.NomeFantasia, dataVenda: new Date(moment()) });
                                        setVendaFinalizada(false);
                                        setProdutosSelecionados([]);

                                        dispatch({ type: SHOPING_CART_SET, value: null });
                                        await fetchProdutos();

                                        setIdAcaoExecutada(1);
                                        setIdAcaoLiberada(1);
                                    }}
                                >
                                    Nova venda
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={async () => {
                                        removeStorage('shoppingCart');
                                        setDadosVenda({ ...INITIAL_SALE, idVendedor: userLoggedIn.IdUsuario, nomeVendedor: userLoggedIn.NomeFantasia, dataVenda: new Date(moment()) });
                                        setVendaFinalizada(false);
                                        setProdutosSelecionados([]);

                                        dispatch({ type: SHOPING_CART_SET, value: null });
                                        await fetchProdutos();

                                        setIdAcaoExecutada(1);
                                        setIdAcaoLiberada(1);
                                    }}
                                    style={{ background: 'black', color: 'white' }}
                                >
                                    Concluir
                                </Button>
                            </>
                        }
                    </Grid>
                </Grid>
            </Stack>

        </Container>
    );
}

export default CaixaPDV;