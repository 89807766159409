import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './styles.css';
import { useNavigate } from 'react-router';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, espaco = true }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const usuarioLogado = useSelector((store) => store.usuarioLogado);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';
  const ehLeapVesti = process.env.REACT_APP_LEAP_VESTI === 'true';

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if(!leftDrawerOpened){
      setOpen(false);  
    }
  }, [leftDrawerOpened]);

  const handleClick = () => {
    setOpen(!open);

    setSelected(!selected && !open ? menu.id : (selected && !open) ? selected : null);
  };

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} espaco={item.espaco} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    usuarioLogado?.usuario && menu.id === 'userLogado' ? 
      <Box className='photo' style={{ color: 'white' }}>
        <img src={usuarioLogado.usuario.pessoa.PES_ImagemPath} alt='logo' width='100%' />
      </Box> : 
      <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto', color: 'white' }} />
  ) : (
    null
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: 'flex-start',
          
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
        style={{ background: (ehLeapLovers || ehLeapVesti) ? '#f7931e' : null }}
        selected={espaco && selected === menu.id}
        onClick={() => {
          if(!leftDrawerOpened && menu.url !== undefined){
            
            navigate(`${menu.url}`);
          }
          else {
            handleClick();
          }
        }}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 34 }}>{menuIcon}</ListItemIcon>
        {leftDrawerOpened && <>
          <ListItemText
            primary={
              <Typography sx={{ my: 'auto', color: 'white' }}>
                {
                  usuarioLogado?.usuario && menu.id === 'userLogado' ? 
                  usuarioLogado.usuario.pessoa.PES_Nome : 
                  menu.title
                }
              </Typography>
            }
            secondary={
              menu.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {menu.caption}
                </Typography>
              )
            }
          />
            {open ? (
              <KeyboardArrowUpIcon stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            ) : (
              <KeyboardArrowDownIcon stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            )}
          </>
        }
      </ListItemButton>
      
      <Collapse in={open} timeout="auto" unmountOnExit style={{ marginBottom: espaco ? 30 : 0 }}>
        <List
          component="div"
          disablePadding
          sx={{
            position: 'relative',
            '&:after': {
              content: "''",
              position: 'absolute',
              left: '32px',
              top: 0,
              height: '100%',
              width: '1px',
              opacity: 1,
              background: theme.palette.primary.light,
            },
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
