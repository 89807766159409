import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SettingsTwoTone, Visibility, VisibilityOff } from "@mui/icons-material";
import { POST_DATA, PUT_DATA } from '../../../services/API';
import { useDispatch, useSelector } from 'react-redux';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import logoEditor from '../../../assets/leapPay.jpeg'
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import * as moment from "moment";
import { useNavigate } from "react-router";
import { atualizarOnboardStore } from "../../../services/utils/functions";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

const ImgSafe2Pay = <path d="M47,19.14a4.07,4.07,0,0,0-1.68-1.63,14.05,14.05,0,0,0-3.2-1.14,22.35,22.35,0,0,1-2.94-.84,3.21,3.21,0,0,1-1.42-1,2.77,2.77,0,0,1-.44-1.66c0-1.9,1.3-2.86,3.91-2.86a53.61,53.61,0,0,1,5.55.45.11.11,0,0,0,.12-.1V8.3a.11.11,0,0,0-.09-.1c-1.34-.22-2.43-.38-3.27-.46A23.22,23.22,0,0,0,41,7.59,7.28,7.28,0,0,0,36.16,9a5,5,0,0,0-1.63,4.08,5.62,5.62,0,0,0,.6,2.78A4.5,4.5,0,0,0,37,17.56a15.75,15.75,0,0,0,3.6,1.21,16.49,16.49,0,0,1,2.45.7,2.78,2.78,0,0,1,1.27,1,2.68,2.68,0,0,1,.4,1.55q0,3.53-3.86,3.52a46.1,46.1,0,0,1-5.77-.5.11.11,0,0,0-.12.1v2.05a.11.11,0,0,0,.09.1l.65.11a35.34,35.34,0,0,0,5.32.54,6.74,6.74,0,0,0,4.79-1.54,6,6,0,0,0,1.65-4.58A5.69,5.69,0,0,0,47,19.14Z" class="cls-1"></path>

const MOCK_PAYMENT_OPTIONS_DATA = [
  {
    logo: "https://pagar.me/static/logo_pagarme-f40e836118f75338095ebb5b461cd5ed.svg",
    nome: "Pagar.me",
    ativo: false,
  },
  {
    logo: "https://logodownload.org/wp-content/uploads/2014/10/paypal-logo-3-1.png",
    nome: "PayPal",
    ativo: false,
  },
  {
    logo: "https://www.acsw.com.br/img/logo%20s2p.png",
    nome: "Safe2Pay",
    ativo: false,
  },
  {
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png",
    nome: "Stripe",
    ativo: false,
  },
  {
    logo: "https://cdn-boto.asaas.com/_next/static/media/header-logo.d237bbad.svg",
    nome: "Asaas",
    ativo: false,
  },
  {
    logo: logoEditor,
    nome: "LeapPay",
    ativo: false,
  },
];

export default function OpcoesFormasPagamento() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoggedIn = useSelector((state) => state.usuarioLogado);
  const onBoard = useSelector((store) => store.onBoard);

  const [mostrarToken, setMostrarSenha] = useState(false);
  const [mostrarSecretKey, setMostrarSecretKey] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loadingSaveIntegracao, setLoadingSaveIntegracao] = useState(false);
  const idTipoIntegracao = 3; //Formas de pagamento

  const [subcontaAsaas, setSubcontaAsaas] = useState(null);

  const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

  const [idIntegracaoAtual, setIdIntegracaoAtual] = useState(0);
  const [objAlterarFormaPagamento, setObjAlterarFormaPagamento] = useState({
    idIntegracao: 0,
    idIntegracaoTipo: 0,
    idx: 0,
    field: '',
    value: ''
  });

  const handleChange =
    (panel, podePxpandir) => (event, isExpanded) => {
      if (!podePxpandir) return;

      setExpanded(isExpanded ? panel : false);
    };

  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    if (!userLoggedIn?.IdUsuario) return;

    (async () => {
      const result = await POST_DATA(`Integracao/GetAllIntegracao?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdIntegracao`);
      const options = result.filter((integracao) => integracao.IdIntegracaoTipo === idTipoIntegracao);

      try {
        const resultSubconta = await POST_DATA(`UsuarioDadosSubContaAsaas/ConsultaSubContaByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}`);
        setSubcontaAsaas(resultSubconta);
      }
      catch { }

      setOptionsList(options);
      await fetchIntegracaoAtual(options);
    })();

  }, [userLoggedIn]);

  const fetchIntegracaoAtual = async (options) => {
    const tmpOptions = [...options]
    const result = await POST_DATA(`IntegracaoParametros/GetAllIntegracaoParametrosByIdIntegracaoTipoAndIdUsuario?IdIntegracaoTipo=${idTipoIntegracao}&IdUsuario=${userLoggedIn?.IdUsuario}`);
    tmpOptions.forEach(item => {
      const option = result.filter(a => a.IdIntegracao === item.IdIntegracao)[0];
      if (option) {
        item.IntegracaoParametros = option;
      }
      else {
        item.IntegracaoParametros = {
          IdUsuario: userLoggedIn.IdUsuario,
          IdIntegracao: item.IdIntegracao,
          Email: '',
          Token: '',
          FlagAtivo: false
        };
      }
    });
    setOptionsList(tmpOptions);

    tmpOptions.map(item => {
      if (item.IntegracaoParametros?.FlagAtivo == true) {
        setIdIntegracaoAtual(item.IdIntegracao);
      }
    });
  };

  const handleSetOptions = (idIntegracaoTipo, idx, field, value) => {
    const config = optionsList.filter(item => item.IdIntegracaoTipo === idIntegracaoTipo)[idx];
    if (config) {
      const arr = [...optionsList];
      arr[idx].IntegracaoParametros = { ...config.IntegracaoParametros, [field]: value };
      setOptionsList(arr);
    }
  }

  const handleSalvar = async (id) => {
    try {
      if (subcontaAsaas !== null) {
        dispatch({
          type: CONTROLEMENSAGEM_AVISO,
          tipoComponente: 'alert',
          titulo: 'Aviso',
          message: 'Não é possível ativar forma de pagamento, pois existe subconta Asaas gerado!',
        });

        return;
      }

      const option = optionsList.filter(item => item.IdIntegracao === id)[0];
      const objCadastroFinal = {
        ...option.IntegracaoParametros,
        IdIntegracaoParametros: option.IntegracaoParametros?.IdIntegracaoParametros ? option.IntegracaoParametros.IdIntegracaoParametros : 0,
        IdIntegracao: id,
        SecretKey: option.IntegracaoParametros.Token,
        IdUsuario: userLoggedIn.IdUsuario
      };

      await PUT_DATA("IntegracaoParametros/CadastroIntegracaoParametros", objCadastroFinal);

      dispatch({
        type: CONTROLEMENSAGEM_SUCESSO,
        tipoComponente: 'alert',
        titulo: 'Sucesso',
        message: 'Informações salvas!',
      });

      if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 4)[0].FlagStatus) {
        const objOnboard = { ...onBoard };
        objOnboard.IdUltimoOnboardingStoreItem = 4;

        const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 4)[0];
        itemOnboard.FlagStatus = true;

        atualizarOnboardStore(objOnboard, itemOnboard);

        dispatch({
          type: SET_DADOS_ON_BOARD,
          data: objOnboard
        });

        navigate('/on-board');
      }
    }
    catch (err) {
      dispatch({
        type: CONTROLEMENSAGEM_ERRO,
        tipoComponente: 'alert',
        titulo: 'Erro',
        message: `Ocorreu um erro ao salvar\n${err.message}`,
      });
    }
  }

  return (
    <Stack sx={{ mt: 2 }}>
      <Confirmation
        title="Mudar forma de pagamento?"
        mensagem="Tem certeza que deseja mudar o meio de pagamento? Uma vez que esta mudança seja feita, irá inativar o meio de pagamento atual"
        btn1={{ title: "Não", onClick: null }}
        btn2={{
          title: "Sim",
          onClick: () => {
            const config = optionsList.filter(item => item.IdIntegracao === idIntegracaoAtual)[0];
            const index = optionsList.indexOf(config);
            if (config) {
              const arr = [...optionsList];
              arr[index].IntegracaoParametros = { ...config.IntegracaoParametros, FlagAtivo: false };
              setOptionsList(arr);
            }

            setIdIntegracaoAtual(objAlterarFormaPagamento.idIntegracao);
            handleSetOptions(objAlterarFormaPagamento.idIntegracaoTipo, objAlterarFormaPagamento.idx, objAlterarFormaPagamento.field, objAlterarFormaPagamento.value);
          }
        }}
      />

      {optionsList &&
        optionsList?.filter(a => a.IdIntegracao === 10).map((option, i) => {
          return option.IntegracaoParametros && (
            <Accordion
              key={i}
              expanded={expanded === `panel-${i}`}
              onChange={handleChange(`panel-${i}`, option.IdIntegracao == 10)}
            >
              <AccordionSummary
                expandIcon={option.IdIntegracao === 10 ? <SettingsTwoTone /> : null}
                aria-controls={`panel-${i}-content`}
                id={`panel-${i}-header`}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={2}
                  width={"100%"}
                  sx={{ padding: "0 1rem" }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box style={{ background: option.Nome === 'Asaas' ? 'blue' : 'white', padding: 10, borderRadius: 10 }}>
                      <img
                        alt='Logo'
                        src={option.logo ? option.logo : MOCK_PAYMENT_OPTIONS_DATA?.filter(item => item.nome === option.Nome)[0]?.logo}
                        style={{ width: "70px", height: "60px" }}
                      />
                    </Box>
                    <Box style={{ width: '100%', displey: 'flex', flexDirection: 'column', paddingLeft: 30, justifyContent: 'flex-start' }}>
                      <Typography sx={{ color: "text.secondary", fontWeight: 'bold', fontSize: 20 }}>
                        {option.Nome}
                      </Typography>
                      <Typography>Permite que você receba pagamentos com o {option.Nome}</Typography>
                    </Box>
                  </Stack>
                  {
                    option.IdIntegracao !== 10 &&
                    <Stack direction="row" gap={2} alignItems="center">
                      <Switch
                        color="success"
                        checked={option.IntegracaoParametros.FlagAtivo}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (optionsList.filter(a => a.IntegracaoParametros.FlagAtivo).length > 0) {
                            setObjAlterarFormaPagamento({
                              idIntegracao: option.IdIntegracao,
                              idIntegracaoTipo: option.IdIntegracaoTipo,
                              idx: i,
                              field: 'FlagAtivo',
                              value: !option?.IntegracaoParametros?.FlagAtivo
                            });
                            getConfirmation();
                          }
                          else {
                            handleSetOptions(option.IdIntegracaoTipo, i, 'FlagAtivo', !option?.IntegracaoParametros?.FlagAtivo);
                          }
                        }}
                      />
                      <Typography
                        sx={{ color: option?.IntegracaoParametros?.FlagAtivo ? "green" : "gray" }}
                      >
                        {option?.IntegracaoParametros?.FlagAtivo ? "LIGADO" : "DESLIGADO"}
                      </Typography>
                    </Stack>
                  }
                  {
                    option.IdIntegracao === 10 &&
                    <Stack direction="row" gap={2} alignItems="center">
                      <Button variant="contained" style={{ color: 'white', fontSize: 12 }} onClick={() => navigate('/dados-bancarios-pagamento')}>Conectar meio de pagamento</Button>
                    </Stack>
                  }
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" gap={1}>
                  <Alert severity="info">
                    <AlertTitle>Configurações</AlertTitle>
                    Configure o envio de e-mail com a plataforma{" "}
                    <strong>{option.Nome}</strong> seguindo esses passos:
                    <ol style={{ marginTop: "1rem" }}>
                      <li>
                        1. Crie uma conta na plataforma da{" "}
                        <strong>{option.Nome}</strong>.
                      </li>
                      <li>
                        2. Com a conta criada, informe <strong>E-mail</strong>, {" "}
                        <strong>chave de integração</strong> e <strong>secret key</strong> nos campos abaixo.
                      </li>
                    </ol>
                  </Alert>
                  <Stack direction="row" gap={1} width={"100%"}>
                    <TextField
                      label="E-mail"
                      variant="outlined"
                      type="email"
                      sx={{ flex: 1 }}
                      value={option.IntegracaoParametros.Email}
                      onChange={(e) => {
                        handleSetOptions(option.IdIntegracaoTipo, i, 'Email', e.target.value.trim());
                      }}
                    ></TextField>
                    <TextField
                      type={mostrarToken ? "text" : "password"}
                      label="Chave de integração (token)"
                      variant="outlined"
                      sx={{ flex: 2 }}
                      value={option.IntegracaoParametros.Token}
                      onChange={(e) => {
                        handleSetOptions(option.IdIntegracaoTipo, i, 'Token', e.target.value.trim());
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setMostrarSenha(!mostrarToken)}
                            edge="end"
                          >
                            {mostrarToken ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }}
                    />
                    {
                      option.IdIntegracao !== 6 &&
                      <Button disabled={subcontaAsaas != null} variant="contained" onClick={() => handleSalvar(option.IdIntegracao)}>Salvar</Button>
                    }
                  </Stack>
                  {
                    option.IdIntegracao === 6 &&
                    <Stack direction="row" gap={1} width={"100%"}>
                      <TextField
                        type={mostrarSecretKey ? "text" : "password"}
                        label="Secret Key"
                        variant="outlined"
                        sx={{ flex: 2 }}
                        value={option.IntegracaoParametros.SecretKey}
                        onChange={(e) => {
                          handleSetOptions(option.IdIntegracaoTipo, i, 'SecretKey', e.target.value.trim());
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setMostrarSecretKey(!mostrarSecretKey)}
                              edge="end"
                            >
                              {mostrarSecretKey ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }}
                      />
                      <Button disabled={subcontaAsaas != null} variant="contained" onClick={() => handleSalvar(option.IdIntegracao)}>Salvar</Button>
                    </Stack>
                  }
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Stack>
  );
}
