export const TEMA_LOJA_SET = 'TEMA_LOJA_SET';
export const TEMA_LOJA_SET_THEME = 'TEMA_LOJA_SET_THEME';
export const TEMA_LOJA_SET_CONFIGURACAO_ITEM = 'TEMA_LOJA_SET_CONFIGURACAO_ITEM';
export const TEMA_LOJA_GET_NEW = 'TEMA_LOJA_GET_NEW';
export const TEMA_LOJA_SET_MENU = 'TEMA_LOJA_SET_MENU';
export const TEMA_LOJA_SET_LOGO = 'TEMA_LOJA_SET_LOGO';
export const TEMA_LOJA_SET_TIPO_LOGO = 'TEMA_LOJA_SET_TIPO_LOGO';
export const TEMA_LOJA_ADD_CARROSSEL_IMAGEM = 'TEMA_LOJA_ADD_CARROSSEL_IMAGEM';
export const TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG = 'TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG';
export const TEMA_LOJA_REMOVE_CARROSSEL_IMAGEM = 'TEMA_LOJA_REMOVE_CARROSSEL_IMAGEM';
export const TEMA_LOJA_SET_OPTION_SITE = 'TEMA_LOJA_SET_OPTION_SITE';
export const TEMA_LOJA_SET_TOP_PRODUTOS = 'TEMA_LOJA_SET_TOP_PRODUTOS';
export const TEMA_LOJA_SET_EXIBICAO_ITENS = 'TEMA_LOJA_SET_EXIBICAO_ITENS';
export const TEMA_LOJA_SET_CAMPAIGNS = 'TEMA_LOJA_SET_CAMPAIGNS';
export const TEMA_LOJA_SET_FOOTER = 'TEMA_LOJA_SET_FOOTER';
export const TEMA_LOJA_SET_FALE_CONOSCO = 'TEMA_LOJA_SET_FALE_CONOSCO';
export const TEMA_LOJA_SET_POLITICAS = 'TEMA_LOJA_SET_POLITICAS';
export const TEMA_LOJA_SET_ABOUT_US = 'TEMA_LOJA_SET_ABOUT_US';
export const TEMA_LOJA_SET_DETALHES_PAGINA_PRODUTOS = 'TEMA_LOJA_SET_DETALHES_PAGINA_PRODUTOS';
export const TEMA_LOJA_SET_HEADER_MENSAGENS = 'TEMA_LOJA_SET_HEADER_MENSAGENS';
export const TEMA_TEMA_SELECIONADO = 'TEMA_TEMA_SELECIONADO';
export const TEMA_LOJA_SET_IMAGEM_PRODUTO = 'TEMA_LOJA_SET_IMAGEM_PRODUTO';
export const TEMA_LOJA_SET_MARKETING_SEO = 'TEMA_LOJA_SET_MARKETING_SEO';
export const TEMA_LOJA_SET_GOOGLE_ANALITICS = 'TEMA_LOJA_SET_GOOGLE_ANALITICS';
export const TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO = 'TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO';
export const TEMA_LOJA_SET_EXIBICAO_ITENS_MENU = 'TEMA_LOJA_SET_EXIBICAO_ITENS_MENU';