import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_DATA, GET_DATA_DESIGN, handleTrack, POST_AUTH, POST_DATA, POST_DATA_DESING, POST_DATA_FILE, POST_DATA_SERVICOS_ROBO, PUT_DATA, PUT_DATA_DESIGN, SalvarLogSentry, UploadFileAzure } from "../../../services/API";
import { Box, Button, ButtonGroup, Card, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Modal, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Container, ImgIcon } from "./styles";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import StarIcon from '@mui/icons-material/Star';
import InputMask from "react-input-mask";

import visaSvg from '../../../assets/logos/visa.svg';
import mastercardSvg from '../../../assets/logos/mastercard.svg';
import lasy from '../../../assets/logos/lasy.svg';
import eloSvg from '../../../assets/logos/elo.svg';
import hypercard from '../../../assets/logos/hipercard.svg';
import americanExpress from '../../../assets/logos/american-express.svg';
import pix from '../../../assets/logos/pix.svg';

import CloseIcon from '@mui/icons-material/Close';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BusinessIcon from '@mui/icons-material/Business';
import PaidIcon from '@mui/icons-material/Paid';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import ChecklistIcon from '@mui/icons-material/Checklist';

import ImageIcon from '@mui/icons-material/Image';
import { TEMA_LOJA_ADD_CARROSSEL_IMAGEM, TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG, TEMA_LOJA_SET, TEMA_LOJA_SET_ABOUT_US, TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO, TEMA_LOJA_SET_FALE_CONOSCO, TEMA_LOJA_SET_FOOTER, TEMA_LOJA_SET_GOOGLE_ANALITICS, TEMA_LOJA_SET_IMAGEM_PRODUTO, TEMA_LOJA_SET_LOGO, TEMA_LOJA_SET_MARKETING_SEO, TEMA_LOJA_SET_MENU, TEMA_LOJA_SET_POLITICAS, TEMA_LOJA_SET_THEME, TEMA_LOJA_SET_TIPO_LOGO, TEMA_TEMA_SELECIONADO } from "../../../store/TemaLojaReducer/types";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import UploadImagemDesign from "../../../components/UploadImagemDesign";
import Loader from "../../../ui-component/Loader";
import * as moment from "moment";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useLocation, useNavigate } from "react-router";
import { atualizarOnboardStore } from "../../../services/utils/functions";
import ExibicaoItens from "../../../components/ExibicaoItens";
import MensagemMarketing from "../../../components/MensagemMarketing";
import SuaLogo from '../../../assets/suaLogo.png';
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";

import katex from "katex";
import "katex/dist/katex.min.css";
import './styles.css';

import Editor from "../../../components/QuillEditor";

import AvatarEditor from 'react-avatar-editor';

import { SketchPicker } from 'react-color';
import axios from "axios";
import OpcoesSite from "../../../components/OpcoesSite";
import ExibicaoItensMenu from "../../../components/ExibicaoItensMenu";
import { base64ToFile, convertUrlToImage } from "../../../services/utils/utils";

import planoFundo from "../../../assets/planoFundo.png";
import { isMobile } from "../../../services/DeviceServices";

import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

window.katex = katex;

const TikTokIcon = ({ color = "#000000" }) => {
    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="100%"
            height="100%"
        >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};

const containerModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4
};

const listPallete = [
    {
        id: 'default',
        descricao: 'Padrão',
        background: {
            default: '#f1416c',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete1',
        descricao: 'Cinza',
        background: {
            default: '#a5a49f',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete2',
        descricao: 'Laranja',
        background: {
            default: '#f28705',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete3',
        descricao: 'Amarelo',
        background: {
            default: '#f3c953',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete4',
        descricao: 'Rosa',
        background: {
            default: '#f194c0',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete5',
        descricao: 'Vermelho',
        background: {
            default: '#f20306',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete6',
        descricao: 'Branco',
        background: {
            default: '#f3f0e9',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete7',
        descricao: 'Azul claro',
        background: {
            default: '#7ca6bc',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete8',
        descricao: 'Azul escuro',
        background: {
            default: '#2852a0',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete9',
        descricao: 'Amarelo claro',
        background: {
            default: '#d3d947',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete10',
        descricao: 'Verde escuro',
        background: {
            default: '#317121',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete11',
        descricao: 'Verde claro',
        background: {
            default: '#abd796',
            paper: '#FFFFFF'
        }
    },
    {
        id: 'pallete12',
        descricao: 'Preto/Vinho escuro',
        background: {
            default: '#200a25',
            paper: '#FFFFFF'
        }
    },
]

const ConfigurarEcommerce = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const superUser = useSelector((store) => store.superUser);
    const themeStorage = useSelector((store) => store.temaLoja);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const onBoard = useSelector((store) => store.onBoard);

    const editorRef = useRef(null);

    const [imagem, setImagem] = useState(null);
    const [zoom, setZoom] = useState(1);

    const [loading, setLoading] = useState(true);
    const [construtorSite, setConstrutorSite] = useState(null);
    const [themeStorageSelected, setThemeStorageSelected] = useState(null);
    const [tokenDesign, setTokenDesign] = useState(null);
    const [carrosselBannerTab, setCarrosselBannerTab] = useState(0);
    const [bannerSessaoTab, setBannerSessaoTab] = useState(0);
    const [empresa, setEmpresa] = useState(null);
    const [validacoes, setValidacoes] = useState([]);
    const [mostrarConfigsAvancadas, setMostrarConfigsAvancadas] = useState(false);
    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();

    const [dominioLeap, setDominioLeap] = useState(null);

    const [temaSelecionado, setTemaSelecionado] = useState(null);

    const [tabConfigAvancada, setTabConfigAvancada] = useState(0);

    const [dimensoesMobileStr, setDimensoesMobileStr] = useState("Tamanho máximo 765 x 429");
    const [widthMobile, setWidthMobile] = useState(765);
    const [heightMobile, setHeightMobile] = useState(429);

    const [dimensoesWebPadraoStr, setDimensoesWebPadraoStr] = useState("Tamanho máximo 1920 x 379");
    const [widthWebPadrao, setWidthWebPadrao] = useState(1920);
    const [heightWebPadrao, setHeightWebPadrao] = useState(379);

    const [dimensoesWebFullPageStr, setDimensoesWebFullPageStr] = useState("Tamanho máximo 1920 x 379");
    const [widthWebWebFullPage, setWidthWebWebFullPage] = useState(1920);
    const [heightWebFullPage, setHeightWebFullPage] = useState(379);

    //Configuração de corredores
    const [showValidacaoMobie, setShowValidacaoMobie] = useState(false);
    const [openModalImagemCrop, setOpenModalImagemCrop] = useState(false);


    useEffect(() => {
        (async () => {
            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };

            const login = await POST_AUTH(obj);
            setTokenDesign(login.token);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (tokenDesign === null) return;
            await GetDadosTema();
        })();
    }, [tokenDesign, location.state]);

    const GetDadosTema = async () => {
        try {
            // const onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${usuarioLogado.IdUsuario}`);
            // console.log('onboardLojistaResult', onboardLojistaResult);

            // return;            

            setLoading(true);

            const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
            setEmpresa(userEmpresa);

            const resultDominio = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${userLoggedIn?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
            if (resultDominio !== null && resultDominio.length > 0) {
                setDominioLeap(resultDominio[0].NomeSiteLeap);
            }
            // else {
            //     dispatch({
            //         type: CONTROLEMENSAGEM_AVISO,
            //         tipoComponente: 'alert',
            //         titulo: 'Domínio não configurado',
            //         message: 'Para prosseguir, é necessário que o item (Liberar Loja / Domínio) esteja configurado'
            //     });

            //     navigate('/on-board');

            //     return;
            // }

            const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);

            // construtorSite.data.arquivo.tipoThema = 13;
            // construtorSite.data.arquivo.themes_store[0].title = 'Tangerine';

            await handleTrack(userLoggedIn.IdUsuario, 'DesignLoja', `Caregando dados do design => Tema: ${(construtorSite.data.id > 0 ? construtorSite.data.arquivo.tipoThema : 'Tema não selecionado')}`);

            setConstrutorSite(construtorSite.data);

            if (construtorSite.data.id > 0) {
                construtorSite.data.arquivo.idLoja = userLoggedIn.IdUsuario;

                const temasResult = await GET_DATA(`Tema/GetTemaById?id=${construtorSite.data.arquivo.tipoThema}`);

                handleImagemCrop(construtorSite.data.arquivo.themes_store[0]?.logo?.url);

                setTemaSelecionado(temasResult);

                dispatch({
                    type: TEMA_LOJA_SET,
                    data: construtorSite.data.arquivo
                });

                if (isMobile()) {
                    setShowValidacaoMobie(true);
                }
            }
            else if (!location?.state?.idTemaSelecionado) {
                await handleTrack(userLoggedIn.IdUsuario, 'DesignLoja', `Tema não selecionado, indo para a tela de seleção de tema`);

                navigate('/on-board-design-site', { state: { escolherTemplate: true }, replace: true });
                return;
            }
            else {
                let objConstrutor = { ...construtorSite.data.arquivo };

                const temasResult = await GET_DATA(`Tema/GetTemaById?id=${location?.state?.idTemaSelecionado}`);
                setTemaSelecionado(temasResult);

                objConstrutor.idLoja = userLoggedIn.IdUsuario;
                objConstrutor.tipoThema = location?.state?.idTemaSelecionado;
                objConstrutor.themes_store[0].title = temasResult.Nome;

                const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${temasResult.IdLojaOrigem}`);

                const dadosTema = await GET_DATA(`/Base/DownloadFile?url=${temasResult.UrlArquivoConfig}`);
                objConstrutor = JSON.parse(dadosTema);
                objConstrutor.idLoja = userLoggedIn.IdUsuario;

                const obj = {
                    id: 0,
                    idCliente: userLoggedIn.IdUsuario,
                    idUsuarioAlteracao: 1, //Admin
                    urlSite: '',
                    urlArquivoConfig: '',
                    status: 1,
                    arquivoStr: JSON.stringify(objConstrutor)
                }

                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, obj);

                let resultConstrutorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
                setConstrutorSite(resultConstrutorSite.data);

                try {
                    if (resultDominio.length > 0) {
                        const urlDominio = resultDominio[0].NomeSiteLeap?.includes(`http`) ? resultDominio[0].NomeSiteLeap : `https://${resultDominio[0].NomeSiteLeap}`;

                        await PUT_DATA_DESIGN(`ConstrutorSite/AtualizarDominio?id=${resultConstrutorSite.data.id}&urlSite=${temasResult.UrlTema}&dominio=${urlDominio}&idTema=${temasResult.IdTema}`, tokenDesign);

                        await handleDuplicarPordutosLoja(temasResult.IdLojaOrigem);
                    }
                }
                catch (err) {
                    SalvarLogSentry(err);
                }

                try {
                    const marcasLoja = await POST_DATA(`UsuarioEmpresaXMarca/GetAllUsuarioEmpresaXMarcaByValorExato?strValorExato=${userEmpresa.IdUsuarioEmpresa}&ColunaParaValorExato=UXM.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
                    for (const item of marcasLoja) {
                        item.id = 0;
                        item.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                    }

                    if (marcasLoja.length > 0) {
                        await PUT_DATA(`UsuarioEmpresaXMarca/InsertListUsuarioEmpresaXMarca`, marcasLoja);
                    }
                }
                catch (err) {
                    SalvarLogSentry(err);
                }

                //Duplicar generos, categorias, peças e sub peças do tema selecionado
                try {
                    const generosLojaTema = await POST_DATA(`UsuarioEmpresaXSexo/GetAllUsuarioEmpresaXSexoByValorExato?strValorExato=${userEmpresa.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
                    for (const itemGenero of generosLojaTema) {
                        itemGenero.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                    }
                    await PUT_DATA(`UsuarioEmpresaXSexo/InsertListUsuarioEmpresaXSexo`, generosLojaTema);

                    const categoriasLojaTema = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userEmpresa.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=idSexo&Log=`);
                    for (const itemCategoria of categoriasLojaTema) {
                        itemCategoria.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                    }
                    await PUT_DATA(`UsuarioEmpresaXCategoria/InsertListUsuarioEmpresaXCategoria`, categoriasLojaTema);

                    const pecasLojaTema = await POST_DATA(`UsuarioEmpresaXPeca/GetAllUsuarioEmpresaXPecaByValorExato?strValorExato=${userEmpresa.IdUsuarioEmpresa}&ColunaParaValorExato=Up.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Up.idSexo&Log=`);
                    for (const itemPecas of pecasLojaTema) {
                        itemPecas.IdUsuarioEmpresa = userLoggedIn.IdUsuarioEmpresa;
                    }
                    await PUT_DATA(`UsuarioEmpresaXPeca/InsertListUsuarioEmpresaXPeca`, pecasLojaTema);
                }
                catch (err) {
                    SalvarLogSentry(err);
                }

                dispatch({
                    type: TEMA_LOJA_SET,
                    data: resultConstrutorSite.data.arquivo
                });

                await handleTrack(userLoggedIn.IdUsuario, 'DesignLoja', `Carregamento do design finalizado com sucesso`);

                if (isMobile()) {
                    if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus) {
                        const objOnboard = { ...onBoard };
                        objOnboard.IdUltimoOnboardingStoreItem = 3;

                        const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0];
                        itemOnboard.FlagStatus = true;

                        await atualizarOnboardStore(objOnboard, itemOnboard);
                        dispatch({
                            type: SET_DADOS_ON_BOARD,
                            data: objOnboard
                        });
                    }

                    navigate('/on-board', { replace: true });
                }
                else {
                    navigate('/configurar-ecommerce');
                }
            }

            setLoading(false);
        } catch (err) {
            SalvarLogSentry(err, 'Desgin Site => GetDadosTema');
            setLoading(false);
        }
    }

    const handleImagemCrop = (urlImagem, atualizarLogo = false) => {
        if (typeof urlImagem === 'string') {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous'; // Evita problemas de CORS para imagens externas
                img.src = urlImagem;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);

                    // Converte para Data URL (base64)
                    const dataURL = canvas.toDataURL('image/webp'); // ou 'image/png'

                    let type = '';
                    try {
                        type = dataURL.split(',')[0].split(';')[0].split(':')[1];
                    }
                    catch { }

                    const file = base64ToFile(dataURL, 'logo', type);

                    setImagem(dataURL);

                    if (atualizarLogo) {
                        dispatch({
                            type: TEMA_LOJA_SET_LOGO,
                            themeStorageId: themeStorage.idThemeStoreSelected,
                            logo: {
                                ...themeStorageSelected?.logo,
                                img: file,
                                url: URL.createObjectURL(file),
                                name: `logo.${type.split('/')[1]}`,
                                extensao: type.split('/')[1]
                            }
                        });
                    }
                };

                img.onerror = (error) => reject(error);
            });
        }
        return null;
    }

    const handleDuplicarPordutosLoja = async (IdLoja) => {
        if (IdLoja === 0) return;

        try {
            //Verificar se não tem produtos já duplicados
            let filtroApi = {
                idUsuario: userLoggedIn.IdUsuario,
                idUsuarioVendedor: userLoggedIn.IdUsuario,
                idUsuarioComprador: 0,
                iPagina: 1,
                Sexo: '0,1,2',
                FlagKids: false,
                Categoria: null,
                Peca: null,
                Tamanho: "",
                Marca: "",
                HashTags: null,
                ValorMinimo: 0,
                ValorMaximo: 0,
                FlgUsado: false,
                FlgFavoritos: false,
                order_by: '_Etiquetas.sDescricao asc',
                FlgAtivo: true,
                Sku: null,
                sDescricaoEtiqueta: null
            }

            let result = await PUT_DATA(`Imagem/GetListImagemFilterPaginationCRM`, filtroApi);

            if (result._Imagens.length > 0) return;

            filtroApi = {
                idUsuario: IdLoja,
                idUsuarioVendedor: IdLoja,
                idUsuarioComprador: 0,
                iPagina: 1,
                Sexo: '0,1,2',
                FlagKids: false,
                Categoria: null,
                Peca: null,
                Tamanho: "",
                Marca: "",
                HashTags: null,
                ValorMinimo: 0,
                ValorMaximo: 0,
                FlgUsado: false,
                FlgFavoritos: false,
                order_by: '_Etiquetas.sDescricao asc',
                FlgAtivo: true,
                Sku: null,
                sDescricaoEtiqueta: null
            }

            result = await PUT_DATA(`Imagem/GetListImagemFilterPaginationCRM`, filtroApi);

            for (const item of result._Imagens) {
                await handleDuplicarProduto(item.idImagem);
            }

            // try {
            //     //Atualizar onboard
            //     const objOnboard = { ...onBoard };
            //     objOnboard.IdUltimoOnboardingStoreItem = 2;

            //     const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 2)[0];
            //     itemOnboard.FlagStatus = true;

            //     atualizarOnboardStore(objOnboard, itemOnboard);
            // }
            // catch { }
        }
        catch (err) {
            SalvarLogSentry(err);
        }
    }

    const handleDuplicarProduto = async (idImagem) => {
        try {
            const produto = await POST_DATA(`Imagem/GetImagemById?Id=${idImagem}&join=true`);

            produto.IdUsuario = userLoggedIn.IdUsuario;
            produto.idImagem = 0;
            produto.DtInclusao = new Date();
            for (const etiqueta of produto._Etiquetas) {
                etiqueta.sDescricao = etiqueta.sDescricao;
                etiqueta.IdEtiqueta = 0;
                etiqueta.DtInclusao = new Date();

                for (const etiquetaComplemento of etiqueta._EtiquetaComplemento) {
                    etiquetaComplemento.IdEtiquetaComplemento = 0;
                }
            }

            let imagem = await convertUrlToImage('ImagemPrincipal', produto.LocalIIS);
            let data = new FormData();
            data.append(`imagem`, imagem);
            data.append("objImagem", JSON.stringify(produto));

            const imagemPrincipal = await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja`, data);
            let idx = 1;
            for (const imagemComplementar of produto._ImagensComplementares) {
                imagemComplementar.idImagem = 0;
                imagemComplementar.IdUsuario = userLoggedIn.IdUsuario;
                imagemComplementar.DtInclusao = new Date();
                imagemComplementar.IdImagemPrincipal = imagemPrincipal.obj.idImagem;
                imagem = await convertUrlToImage(`ImagemComplementar${idx}`, imagemComplementar.LocalIIS);

                data = new FormData();
                data.append("objImagem", JSON.stringify(imagemComplementar));
                data.append(`imagem`, imagem);

                await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja`, data);
                idx++;
            }

        }
        catch (err) {
            SalvarLogSentry(err);
        }
    }

    useEffect(() => {
        if (themeStorageSelected !== null && empresa !== null && (!themeStorageSelected?.faleConosco?.cep || themeStorageSelected?.faleConosco?.cep?.cep?.length < 8)) {
            dispatch({
                type: TEMA_LOJA_SET_FALE_CONOSCO,
                themeStorageId: construtorSite.arquivo.idThemeStoreSelected,
                faleConosco: {
                    contatoWhatsApp: empresa?.Telefone?.replace(/([^\d])+/gim, ''),
                    contatoEmail: empresa?.Email,
                    cep: empresa?.CEP?.replace(/([^\d])+/gim, ''),
                    cidade: empresa?.Cidade,
                    endereco: empresa?.Logradouro,
                    bairro: empresa?.Bairro,
                    numero: empresa?.Numero,
                    complemnto: empresa?.Complemento,
                    uf: empresa?.Estado
                }
            });

        }

        if (themeStorageSelected !== null) {
            handleTipoThema(themeStorage.tipoThema);
        }

        setTamanosBanners();
    }, [themeStorageSelected]);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store) {
            const construtorSiteSelected = themeStorage.themes_store[0];

            if (themeStorageSelected?.bannerCupom === null) {
                themeStorageSelected.bannerCupom = [
                    {
                        "id": 1,
                        "name": "",
                        "alternative_text": "Imagem",
                        "caption": "",
                        "url": null,
                        "url_mobile": null,
                        "extensao": "",
                        "height": "",
                        "width": "",
                        "paginaExibicao": null,
                        "filtrarProdutoBy": null,
                        "type": null,
                        "fullPage": false
                    }
                ];
            }

            setThemeStorageSelected(construtorSiteSelected);

            setTamanosBanners();
        }
    }, [themeStorage]);

    const setTamanosBanners = () => {
        if (themeStorage?.tipoThema === 2) { //Coffee          
            setWidthWebPadrao(1340);
            setHeightWebPadrao(600);

            setHeightWebFullPage(600);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 600");
            setDimensoesWebPadraoStr("Tamanho máximo 1340 x 600");
        }
        if (themeStorage?.tipoThema === 3) { //Vanilla
            setWidthWebPadrao(1340);
            setHeightWebPadrao(553);

            setHeightWebFullPage(553);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 553");
            setDimensoesWebPadraoStr("Tamanho máximo 1340 x 553");
        }
        if (themeStorage?.tipoThema === 4) { //Chicago
            setWidthWebPadrao(1340);
            setHeightWebPadrao(650);

            setHeightWebFullPage(650);
            setDimensoesWebFullPageStr("Tamanho máximo 1340 x 650");
            setDimensoesWebPadraoStr("Tamanho máximo 1340 x 650");
        }
        if (themeStorage?.tipoThema === 5) { //Lollypop
            setWidthWebPadrao(1340);
            setHeightWebPadrao(379);

            setHeightWebFullPage(379);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 379");
            setDimensoesWebPadraoStr("Tamanho máximo 1340 x 379");
        }
        if (themeStorage?.tipoThema === 6) { //Coral
            setWidthWebPadrao(1440);
            setHeightWebPadrao(510);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 650");
            setDimensoesWebPadraoStr("Tamanho máximo 1440 x 650");
        }
        if (themeStorage?.tipoThema === 7) { //Blush
            setWidthWebPadrao(1125);
            setHeightWebPadrao(510);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 510");
            setDimensoesWebPadraoStr("Tamanho máximo 1125 x 510");
        }
        if (themeStorage?.tipoThema === 8) { //Midnight
            setWidthWebPadrao(1220);
            setHeightWebPadrao(552);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 552");
            setDimensoesWebPadraoStr("Tamanho máximo 1220 x 552");
        }
        if (themeStorage?.tipoThema === 9) { //Peral
            setWidthWebPadrao(1270);
            setHeightWebPadrao(700);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 700");
            setDimensoesWebPadraoStr("Tamanho máximo 1270 x 700");
        }
        if (themeStorage?.tipoThema === 10) { //Grape
            setWidthWebPadrao(1220);
            setHeightWebPadrao(375);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 375");
            setDimensoesWebPadraoStr("Tamanho máximo 1220 x 375");
        }
        if (themeStorage?.tipoThema === 11) { //Melancia
            setWidthWebPadrao(1274);
            setHeightWebPadrao(637);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 637");
            setDimensoesWebPadraoStr("Tamanho máximo 1274 x 637");
        }
        if (themeStorage?.tipoThema === 13) { //Tangerine
            setWidthWebPadrao(1440);
            setHeightWebPadrao(718);

            setHeightWebFullPage(1920);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 671837");
            setDimensoesWebPadraoStr("Tamanho máximo 1440 x 718");
        }
    }


    const enviarImagensToAzure = async (themeStorage) => {
        const theme = themeStorage.themes_store[0];
        const pasta = `${userLoggedIn.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}`

        if (theme.logo?.img !== null && theme.logo?.img !== undefined) {
            const nommeArquivo = `logo_${moment().format('YYYYMMDDHHmmss')}.${theme.logo.extensao}`;

            theme.logo.url = await UploadFileAzure(theme.logo.img, `${pasta}/images`, nommeArquivo, `image/${theme.logo.extensao}`);
        }

        for (const imagem of theme.carrossel_images) {
            let nommeArquivo = "";
            if (imagem.img !== null && imagem.img !== undefined) {
                nommeArquivo = `banner${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
            }

            //Mobile
            if (imagem?.img_mobile !== null && imagem?.img_mobile !== undefined) {
                nommeArquivo = `banner_mobile${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensaoMobile}`);
            }

            if (themeStorage?.tipoThema === 2) { //Coffee
                imagem.height = "600px"
                imagem.width = "1340px"
            }
            if (themeStorage?.tipoThema === 3) { //Vanilla
                imagem.height = "553px"
                imagem.width = "1340px"
            }
            if (themeStorage?.tipoThema === 4) { //Chicago
                imagem.height = "650px"
                imagem.width = "1340px"
            }
            if (themeStorage?.tipoThema === 5) { //Lollypop
                imagem.height = "379px"
                imagem.width = "1024px"
            }
            if (themeStorage?.tipoThema === 6) { //Coral
                imagem.height = "650px"
                imagem.width = "1440px"
            }
            if (themeStorage?.tipoThema === 7) { //Blush
                imagem.height = "510px"
                imagem.width = "1225px"
            }

            if (themeStorage?.tipoThema === 8) { //Midnight
                imagem.height = "552px"
                imagem.width = "1220px"
            }
            if (themeStorage?.tipoThema === 9) { //Pearl
                imagem.height = "700px"
                imagem.width = "1270px"
            }

            if (themeStorage?.tipoThema === 10) { //Grape
                imagem.height = "375px"
                imagem.width = "1220px"
            }
        }

        if (themeStorage?.tipoThema !== 3) {
            for (const imagem of theme.banner1) {
                let nommeArquivo = "";

                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `banner1${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `banner_mobile1${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensaoMobile}`);
                }
            }

            for (const imagem of theme.banner2) {
                let nommeArquivo = "";
                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `banner2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `banner_mobile2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensaoMobile}`);
                }
            }

            for (const item of theme.exibicao_itens) {
                if (item.imagem.img !== null && item.imagem.img !== undefined) {
                    const nommeArquivo = `${item.imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${item.imagem.extensao}`;
                    item.imagem.url = await UploadFileAzure(item.imagem.img, `${pasta}/images`, nommeArquivo, `image/${item.imagem.extensao}`);
                }
            }
        }

        if (themeStorage?.tipoThema === 3) { //Tema Vanilla
            for (const imagem of theme.banner2) {
                let nommeArquivo = "";
                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `banner2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `banner_mobile2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensaoMobile}`);
                }
            }

            for (const produtoFavorito of theme.cardsProdutoFavorito) {
                let nommeArquivo = "";
                if (produtoFavorito.imagem.img !== null && produtoFavorito.imagem.img !== undefined) {
                    nommeArquivo = `image-${produtoFavorito.id}_${moment().format('YYYYMMDDHHmmss')}.${produtoFavorito.imagem.extensao}`;
                    produtoFavorito.imagem.url = await UploadFileAzure(produtoFavorito.imagem.img, `${pasta}/images`, nommeArquivo, `image/${produtoFavorito.imagem.extensao}`);
                }
            }
        }

        if (themeStorage?.tipoThema === 6 || themeStorage?.tipoThema === 10 || themeStorage?.tipoThema === 11) { //Tema Coral, Grape e Melancia
            for (const imagem of theme.bannerCupom) {
                let nommeArquivo = "";
                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `bannerCupom${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `bannerCupom_mobile${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensaoMobile}`);
                }
            }
        }

        if (themeStorage?.tipoThema === 7) { //Blush
            for (const item of theme.exibicao_itens_tags) {

                let nommeArquivo = "";
                if (item.imagem.img !== null && item.imagem.img !== undefined) {
                    nommeArquivo = `exibicao_item_menu${item.imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${item.imagem.extensao}`;
                    item.imagem.url = await UploadFileAzure(item.imagem.img, `${pasta}/images`, nommeArquivo, `image/${item.imagem.extensao}`);
                }
            }

            for (const imagem of theme.bannerSessao) {
                let nommeArquivo = "";
                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `bannerSessao${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `bannerSessao_mobile${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensaoMobile}`);
                }
            }
        }

        if (theme.aboutUs?.imagem1?.url !== null && theme.aboutUs.imagem1.img !== undefined) {
            const nommeArquivo = `${theme.aboutUs.imagem1.id}_${moment().format('YYYYMMDDHHmmss')}.${theme.aboutUs.imagem1.extensao}`;
            theme.aboutUs.imagem1.url = await UploadFileAzure(theme.aboutUs.imagem1.img, `${pasta}/images`, nommeArquivo, theme.aboutUs.imagem1.extensao);
        }

        if (theme.aboutUs?.imagem2?.url !== null && theme.aboutUs.imagem2.img !== undefined) {
            const nommeArquivo = `${theme.aboutUs.imagem2.id}_${moment().format('YYYYMMDDHHmmss')}.${theme.aboutUs.imagem2.extensao}`;
            theme.aboutUs.imagem2.url = await UploadFileAzure(theme.aboutUs.imagem2.img, `${pasta}/images`, nommeArquivo, theme.aboutUs.imagem2.extensao);
        }

        if (theme.marketingSEO?.favicon?.url !== null && theme.marketingSEO?.favicon.img !== undefined) {
            const nommeArquivo = `${theme.marketingSEO.favicon.id}_${moment().format('YYYYMMDDHHmmss')}.${theme.marketingSEO.favicon.extensao}`;
            theme.marketingSEO.favicon.url = await UploadFileAzure(theme.marketingSEO.favicon.img, `${pasta}/images`, nommeArquivo, theme.marketingSEO.favicon.extensao);
        }

        return themeStorage;
    }

    const handleValidacao = () => {
        let msgValidacao = '';
        let htmlValidacao = '';

        if (themeStorage === null || themeStorage?.tipoThema === 0) {
            msgValidacao += '<li><Typography>Favor selecionar o tema da loja</Typography></li>';
        }
        if (!themeStorageSelected?.logo?.url || themeStorageSelected?.logo?.url.length === 0) {
            msgValidacao += '<li><Typography>Favor informar a logo da loja</Typography></li>';
        }
        if (themeStorageSelected?.carrossel_images.filter(a => a.url !== null && a.url?.length > 0).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos um banner web para a loja</Typography></li>';
        }

        if (themeStorageSelected?.carrossel_images.filter(a => a.url_mobile !== null && a.url_mobile?.length > 0).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos um banner mobile para a loja</Typography></li>';
        }
        if (themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos uma rede social da loja</Typography></li>';
        }
        if (themeStorageSelected?.footer?.formas_pagamento?.filter(a => a.selected).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos uma forma de pagamento para loja</Typography></li>';
        }

        //Endereço
        if (!themeStorageSelected?.faleConosco?.cep || (themeStorageSelected?.faleConosco?.cep && themeStorageSelected?.faleConosco?.cep.length < 8)) {
            msgValidacao += '<li><Typography>Favor informar o CEP do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.endereco || (themeStorageSelected?.faleConosco?.endereco && themeStorageSelected?.faleConosco?.endereco.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar o logradouro do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.bairro || (themeStorageSelected?.faleConosco?.bairro && themeStorageSelected?.faleConosco?.bairro.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar o bairro do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.cidade || (themeStorageSelected?.faleConosco?.cidade && themeStorageSelected?.faleConosco?.cidade.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar a cidade do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.uf || (themeStorageSelected?.faleConosco?.uf && themeStorageSelected?.faleConosco?.uf.length < 2)) {
            msgValidacao += '<li><Typography>Favor informar o UF do endereço da loja</Typography></li>';
        }

        //Contato
        if (!themeStorageSelected?.faleConosco?.contatoWhatsApp || (themeStorageSelected?.faleConosco?.contatoWhatsApp && themeStorageSelected?.faleConosco?.contatoWhatsApp.length < 11)) {
            msgValidacao += '<li><Typography>Na sessão de endereço e contato da loja, favor informar um Whatsapp válido</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.contatoEmail || (themeStorageSelected?.faleConosco?.contatoEmail && themeStorageSelected?.faleConosco?.contatoEmail.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar o e-mail da loja</Typography></li>';
        }

        if (msgValidacao.length > 0) {
            htmlValidacao += `<Typography style='text-align: center;
            display: flex;
            justify-content: center;
            font-weight: bold;
            font-size: 21px;'>Itens pendentes para verificação</Typography>`;

            htmlValidacao += `<ul>`;

            htmlValidacao += msgValidacao;

            htmlValidacao += `</ul>`;
        }

        setValidacoes(htmlValidacao);
        return msgValidacao.length;
    }

    const getRemoveComplementoRedeSocial = (link) => {
        link = link.replace('https://www.facebook.com/', '');
        link = link.replace('https://www.instagram.com/', '');
        link = link.replace('https://br.pinterest.com/', '');
        link = link.replace('https://twitter.com/', '');
        link = link.replace('https://www.youtube.com/', '');
        link = link.replace('https://www.tiktok.com/', '');
        link = link.replace('https://www.linkedin.com/in/', '');

        return link;
    }

    const handlePublish = async (visualizar = true) => {
        try {

            const res = handleValidacao();
            if (res > 0) {
                window.scrollTo(0, 0);
                return;
            }

            setLoading(true);

            const theme_storage = { ...themeStorage };
            const themeToSave = await enviarImagensToAzure(theme_storage);

            const usuario = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
            if (usuario !== null) {
                themeToSave.themes_store[0].footer.redes_socias.forEach((item) => {
                    if (item.id === 'instagram')
                        usuario.Instagram = item.link;
                    else if (item.id === 'facebook')
                        usuario.Facebook = item.link;
                    else if (item.id === 'tiktok')
                        usuario.Tiktok = item.link;
                    else if (item.id === 'twitter')
                        usuario.Twitter = item.link;
                    else if (item.id === 'youtube')
                        usuario.Youtube = item.link;
                    else if (item.id === 'linkedin')
                        usuario.LinkedIn = item.link;
                });

                usuario.FlgAlteraFotoProfile = true;
                usuario.FotoProfile = themeToSave.themes_store[0].logo.url;

                await POST_DATA(`Usuario/updateDadosUsuario`, usuario);
            }

            const construtorSiteObj = { ...construtorSite };
            if (construtorSiteObj.id > 0) {
                //construtorSite.arquivo = themeToSave;
                construtorSiteObj.ArquivoStr = JSON.stringify(themeToSave);

                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, construtorSiteObj);
            } else {
                const obj = {
                    id: 0,
                    idCliente: userLoggedIn.IdUsuario,
                    idUsuarioAlteracao: 1, //Admin
                    urlSite: '',
                    urlArquivoConfig: '',
                    status: 1,
                    arquivoStr: JSON.stringify(themeToSave)
                }

                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, obj);
            }

            await PUT_DATA('UsuarioEmpresa/AtualizarHoraAtendimentoUsuarioEmpresa', empresa);

            let resultConstrutorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
            setConstrutorSite(resultConstrutorSite.data);

            if (resultConstrutorSite.data.id > 0) {

                const urlDominio = dominioLeap?.includes(`http`) ? dominioLeap : `https://${dominioLeap}`;
                await PUT_DATA_DESIGN(`ConstrutorSite/AtualizarDominio?id=${resultConstrutorSite.data.id}&urlSite=${temaSelecionado.UrlTema}&dominio=${urlDominio}&idTema=${temaSelecionado.IdTema}`, tokenDesign);
                resultConstrutorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
                setConstrutorSite(resultConstrutorSite.data);
            }

            dispatch({
                type: TEMA_LOJA_SET,
                data: resultConstrutorSite.data.arquivo
            });

            //Salvar tema menu
            const respTemaMenu = await POST_DATA(`TemaMenu/GetAllTemaMenusByIdtema?IdTema=${resultConstrutorSite.data.arquivo.tipoThema}`);
            const respTemaMenuEmpresa = await POST_DATA(`UsuarioEmpresaXTemaMenu/GetAllUsuarioEmpresaXTemaMenuByIdUsuarioEmpresa?idUsuarioEmpresa=${userLoggedIn.IdUsuarioEmpresa}`);

            let arrTemaMenuSalvar = [];
            if (respTemaMenuEmpresa.length === 0) {
                for (const temaMenu of respTemaMenu) {
                    arrTemaMenuSalvar.push({
                        IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                        IdTemaMenu: temaMenu.IdTemaMenu,
                        FlgAtivo: true,
                        DataCadastro: moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                }
            }
            else if (respTemaMenuEmpresa.length !== respTemaMenu.length) {
                arrTemaMenuSalvar = [...respTemaMenuEmpresa];

                for (const temaMenu of respTemaMenu) {
                    if (respTemaMenuEmpresa.filter(a => a.IdTemaMenu === temaMenu.IdTemaMenu).length > 0) continue;

                    arrTemaMenuSalvar.push({
                        IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                        IdTemaMenu: temaMenu.IdTemaMenu,
                        FlgAtivo: true,
                        DataCadastro: moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                }
            }

            //Salvar menu tema empresa
            if (arrTemaMenuSalvar.length > 0) {
                for (const temaMenu of arrTemaMenuSalvar) {
                    await PUT_DATA(`UsuarioEmpresaXTemaMenu/CadastroUsuarioEmpresaXTemaMenu`, temaMenu);
                }
            }

            let possuiDominioRegistradoAzure = false;
            try {
                let dataDominioConsulta = JSON.stringify({
                    "domain": dominioLeap,
                    "nome_domain": dominioLeap.split('.')[0]
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_SERVICOS_ROBO_FRONTDOOR}getDeploy`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: dataDominioConsulta
                };

                const responseDominio = await axios.request(config);
                if (responseDominio.data.success && responseDominio.data.data.length > 0) {
                    possuiDominioRegistradoAzure = true;
                }
            } catch (error) {
                SalvarLogSentry(error);
            }

            if (possuiDominioRegistradoAzure && dominioLeap != null) {
                let dataPurge = JSON.stringify({
                    "domains": [
                        dominioLeap
                    ]
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_SERVICOS_ROBO_FRONTDOOR}purge`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    data: dataPurge
                };

                axios.request(config)
                    .then((response) => {
                        console.log('purge ok');
                    })
                    .catch((error) => {
                        console.log('error', error);
                        SalvarLogSentry(error);
                    });

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso ao publicar',
                    message: 'Em poucos minutos a loja estará atualizada com as mudanças realizadas'
                });
            }
            else {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso ao publicar'
                });
            }

            try {
                const endereco = {
                    End_IdUsuario: userLoggedIn.IdUsuario,
                    END_Logradouro: themeToSave.themes_store[0].faleConosco?.endereco,
                    END_Bairro: themeToSave.themes_store[0].faleConosco?.bairro,
                    END_Numero: themeToSave.themes_store[0].faleConosco?.numero,
                    END_Cidade: themeToSave.themes_store[0].faleConosco?.cidade,
                    END_Estado: themeToSave.themes_store[0].faleConosco?.uf,
                    END_CEP: themeToSave.themes_store[0].faleConosco?.cep,
                    END_Latitude: themeToSave.themes_store[0].faleConosco?.latitude,
                    END_Longitude: themeToSave.themes_store[0].faleConosco?.longitude,
                    END_Ativo: 1,
                }

                if (userLoggedIn.IdEndereco) {
                    const possuiEndereco = await POST_DATA(`Endereco/GetEnderecoById?END_Id=${userLoggedIn.IdEndereco}&join=true&Log=`);
                    endereco.END_Id = possuiEndereco.END_Id;
                }

                await PUT_DATA('Endereco/CadastroEndereco', endereco);
            }
            catch { }

            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus) {
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 3;

                const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0];
                itemOnboard.FlagStatus = true;

                await atualizarOnboardStore(objOnboard, itemOnboard);
                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });

                try {
                    const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
                    userEmpresa.Telefone = themeToSave.themes_store[0]?.faleConosco?.contatoWhatsApp;
                    await POST_DATA('UsuarioEmpresa/UpdateUsuarioEmpresa', userEmpresa);
                }
                catch { }

                navigate('/on-board');
                return;
            }

            if (visualizar)
                handleOpenECommerce();

            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: 'Ocorreu um erro ao salvar os dados da loja',
            });
        }
    };

    const getIconSocialMedia = (id) => {
        if (id === undefined) return null;

        const estilo = {
            fontSize: '30px'
        }
        let Icon = null;
        if (id === 'facebook') {
            Icon = <FacebookIcon
                style={estilo}
            />
        }
        else if (id === 'instagram') {
            Icon = <InstagramIcon
                style={estilo}
            />
        }
        else if (id === 'twitter') {
            Icon = <XIcon
                style={estilo}
            />
        }
        else if (id === 'whatsapp') {
            Icon = <WhatsAppIcon
                style={estilo}
            />
        }
        else if (id === 'pinterest') {
            Icon = <PinterestIcon
                style={estilo}
            />
        }
        else if (id === 'youtube') {
            Icon = <YouTubeIcon
                style={estilo}
            />
        }
        else if (id === 'tiktok') {
            Icon = <TikTokIcon
                style={{ ...estilo, width: 20 }}
            />
        }
        else if (id === 'linkedin') {
            Icon = <LinkedInIcon
                style={estilo}
            />
        }

        const item = themeStorageSelected.footer.redes_socias.filter(a => a.id === id)[0];
        if (item === undefined || item === null) return null;

        const userRedeSocial = getRemoveComplementoRedeSocial(item.link);

        return (
            <Box key={id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, maxWidth: 300 }}>
                <FormControlLabel
                    labelPlacement="top"
                    control={<Box style={{ display: 'flex', alignItems: 'center' }}>
                        {Icon}
                        <TextField
                            title={id == 'twitter' ? 'Twitter/X' : item.name}
                            style={{ margin: 10, minWidth: 200 }}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            value={userRedeSocial}
                            onChange={(e) => {
                                if (e.target.value.length === 0) {
                                    handleFooterCheckProperty(item.id, 'redes_socias', false);
                                }
                                else {
                                    handleFooterCheckProperty(item.id, 'redes_socias', true);
                                }

                                let link = getRemoveComplementoRedeSocial(e.target.value.trim());

                                handleChangeDataFooter(item.id, 'redes_socias', 'link', link);
                            }}

                            id="outlined-basic"
                            label={id === 'whatsapp' ? 'Digite o número' : 'Digite o link'}
                        />
                    </Box>}
                    label={item.id == 'twitter' ? 'Twitter/X' : item.name}
                />
            </Box>
        );
    }

    const getIconsPaymentsTypes = (item) => {
        if (item === undefined || item === null || item?.id === 'boleto') return null;

        let icon = null;
        if (item.id === 'visa') {
            icon = <ImgIcon imgSrc={visaSvg} />
        }
        else if (item.id === 'mastercard') {
            icon = <ImgIcon imgSrc={mastercardSvg} />
        }
        else if (item.id === 'lasy') {
            icon = <ImgIcon imgSrc={lasy} />
        }
        else if (item.id === 'elo') {
            icon = <ImgIcon imgSrc={eloSvg} />
        }
        else if (item.id === 'hypercard') {
            icon = <ImgIcon imgSrc={hypercard} />
        }
        else if (item.id === 'american_express') {
            icon = <ImgIcon imgSrc={americanExpress} />
        }
        else if (item.id === 'pix') {
            icon = <ImgIcon imgSrc={pix} />
        }

        return (
            <Box key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    name={item.id}
                    id={item.id}
                    checked={item.selected}
                    onChange={() => handleFooterCheckProperty(item.id, 'formas_pagamento')}
                />
                {icon}
            </Box>
        );
    }

    const handleChangeTabs = (event, newValue) => {
        setCarrosselBannerTab(newValue);
    };

    const handleChangeTabsBannerSessao = (event, newValue) => {
        setBannerSessaoTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTipoThema = (idTipoThema) => {
        dispatch({
            type: TEMA_LOJA_SET,
            data: { ...themeStorage, tipoThema: idTipoThema }
        });

        if (idTipoThema === 1) {  //Coffee
            handleChangeTemeSelected('footer', 'newSletter', true);

            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, 'black');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', 'black');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#ffff');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Helvetica Neue, Arial, sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        if (idTipoThema === 2) {  //Coffee
            handleChangeTemeSelected('footer', 'newSletter', true);

            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, 'black');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', 'black');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#ffff');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#ffff');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Avenir Light');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        else if (idTipoThema === 3) { //Vanilla
            // //Plano  de fundo
            // handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#FE9E40');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Avenir Light');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 18);
        }
        else if (idTipoThema === 4) {  //Chicago
            // //Plano  de fundo
            // handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#e23009');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, '"Roboto","Helvetica","Arial", sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 18);
        }
        else if (idTipoThema === 5) {  //Lolly Pop            
            // //Plano  de fundo
            // handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#F1416C');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, '"Roboto","Helvetica","Arial", sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 18);
        }
        else if (idTipoThema === 6) {  //Coral
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, '#282828');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#D0451B');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', 'white');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, '"Brandon Grotesque","Helvetica","Arial", sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 20);
        }
        else if (idTipoThema === 7) {  //Blush
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, 'black');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#4A4A4A');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Proxima Nova Lt');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 14);
        }
        else if (idTipoThema === 8) {  //Midnght
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, '#141414');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#4A4A4A');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Poppins Extra Light');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 15);
        }
        else if (idTipoThema === 9) {  //Pearl
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, ': #2E302F');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#4A4A4A');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Helvetica Neue, Arial, sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        else if (idTipoThema === 10) {  //Grape
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, ': #2E302F');            
            // //Componente principal
            // handleChangeDataTema('palette', 'personal', 'component', 'background', '#4A4A4A');
            // //Cor fonte
            // handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Lato Regular');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        else if (idTipoThema === 11) {  //Melancia
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, ': #2E302F');            
            // //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#ee712e');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#fffdf2');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Lato Regular');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        else if (idTipoThema === 12) {  //Cherry
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, ': #2E302F');            
            // //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#8a0012');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#fffdf2');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Inter Regular');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        else if (idTipoThema === 13) {  //Tangerine
            // //Cor da fonte
            // handleChangeDataTema('typograpy', 'color', null, null, ': #2E302F');            
            // //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#ffd9b8');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#4A4A4A');

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#ffff');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Inter Regular');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }

        handleSelectMenu();
        handleSelecionarCategoriasFooter();
    }

    const handleOnChangeLogo = (dataLogo) => {
        dispatch({
            type: TEMA_LOJA_SET_LOGO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            logo: dataLogo
        });
        setImagem(dataLogo.url);
    }

    const handleChangeTipoLogo = (tipoLogo) => {
        dispatch({
            type: TEMA_LOJA_SET_TIPO_LOGO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            tipo_logo: tipoLogo
        });
    }

    const handleOnChangeImagemCarrossel = (dataImagem, property = 'carrossel_images') => {
        dispatch({
            type: TEMA_LOJA_ADD_CARROSSEL_IMAGEM,
            property: property,
            themeStorageId: themeStorage.idThemeStoreSelected,
            imagem: dataImagem
        });
    }

    const handleChangeAboutUs = (property, value) => {
        if (themeStorageSelected?.aboutUs === undefined) return;

        const data = { ...themeStorageSelected?.aboutUs };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_ABOUT_US,
            themeStorageId: themeStorage.idThemeStoreSelected,
            aboutUs: data
        });
    }

    const handleChangeCardProdutoFavorito = (id, property, value) => {
        const data = [...themeStorageSelected.cardsProdutoFavorito];

        data.forEach(item => {
            if (item.id === id)
                item[property] = value;
        });

        dispatch({
            type: TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            cardsProdutoFavorito: data
        });
    }

    const handleChangeMarketingSEO = (property, value) => {
        const data = { ...themeStorageSelected?.marketingSEO };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_MARKETING_SEO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            marketingSEO: data
        });
    }

    const handleChangeGoogleAnalitics = (property, value) => {
        const data = { ...themeStorageSelected?.googleAnalytics };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_GOOGLE_ANALITICS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            googleAnalytics: data
        });
    }

    const handleChangeGoogleAnaliticsOld = (id, keyproperty, property, value) => {
        if (themeStorageSelected?.aboutUs === undefined) return;

        const data = { ...themeStorageSelected?.googleAnalytics };

        data[keyproperty].forEach(item => {
            if (item.id === id)
                item[property] = value;
        });

        dispatch({
            type: TEMA_LOJA_SET_GOOGLE_ANALITICS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            googleAnalytics: data
        });
    }

    const handleChangepoliticas = (id, property, value) => {
        const data = [...themeStorageSelected.politicas];

        data.forEach(item => {
            if (item.id === id)
                item[property] = value;
        });

        dispatch({
            type: TEMA_LOJA_SET_POLITICAS,
            themeStorageId: themeStorage.idThemeStoreSelected,
            politicas: data
        });
    }

    const handleChangeEndereco = (property, value) => {
        const data = { ...themeStorageSelected?.faleConosco };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_FALE_CONOSCO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            faleConosco: data
        });
    }

    const handleChangeTemeSelected = (property, subproperty, value) => {
        const data = { ...themeStorageSelected };

        if (subproperty !== null) {
            data[property][subproperty] = value;
        }
        else {
            data[property] = value;
        }

        dispatch({
            type: TEMA_TEMA_SELECIONADO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            data: data
        });
    }

    const handleSelecionarCategoriasFooter = () => {
        const footer = { ...themeStorageSelected.footer };

        footer.categorias.forEach(categoria => {
            categoria.selected = true;
            return categoria;
        });

        footer.ajuda.forEach(ajuda => {
            ajuda.selected = true;
            return ajuda;
        });

        dispatch({
            type: TEMA_LOJA_SET_FOOTER,
            themeStorageId: themeStorage.idThemeStoreSelected,
            data: footer
        });
    }

    const handleCheckFullPageBanners = (property, value) => {
        const objs = [...themeStorageSelected[property]];
        let obj = objs.filter(a => a.type === 'config')[0];

        if (obj !== null && obj !== undefined) {
            obj = {
                ...obj,
                fullPage: value
            }
        }
        else {
            obj = {
                id: 4,
                type: 'config',
                fullPage: value
            }
        }

        dispatch({
            type: TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            value: obj
        });
    }

    const handleChangeDataPageBanners = (idx, property, subproperty, value) => {
        const obj = [...themeStorageSelected[property]][idx];
        obj[subproperty] = value;

        dispatch({
            type: TEMA_LOJA_ADD_CARROSSEL_IMAGEM,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            imagem: obj
        });
    }

    const handleFooterCheckProperty = (id, property, valor = null) => {
        const data = [...themeStorageSelected.footer[property]];

        data.forEach(footer => {
            if (footer.id === id) {
                footer.selected = valor !== null ? valor : !footer.selected;
            }
            return footer;
        });

        dispatch({
            type: TEMA_LOJA_SET_FOOTER,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            data: data
        });
    }

    const handleChangeDataFooter = (id, property, item, value) => {
        let data = null;
        if (property !== undefined && property !== null) {
            data = [...themeStorageSelected.footer[property]];
            data.forEach(footer => {
                if (footer.id === id) {
                    footer[item] = value;
                }
                return footer;
            });
        }
        else {
            data = { ...themeStorageSelected.footer };
            if (id !== undefined && id !== null) {
                data.forEach(footer => {
                    if (footer.id === id) {
                        footer[item] = value;
                    }
                    return footer;
                });
            } else {
                data[item] = value;
            }
        }

        dispatch({
            type: TEMA_LOJA_SET_FOOTER,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            data: data
        });
    }

    const handleOpenECommerce = () => {
        if (construtorSite !== null && construtorSite.id > 0 && construtorSite.urlSite) {
            const codigo = construtorSite.urlSite.split('=');
            const url = `${temaSelecionado?.UrlTema}?xyz=${codigo[1]}`;

            window.open(url, '_blank');
        }
    }

    const marcouDiaSemana = empresa?.FlgAbreSeg || empresa?.FlgAbreTer || empresa?.FlgAbreQua || empresa?.FlgAbreQui || empresa?.FlgAbreSex;
    const handleChangeEmpresa = (property, value) => {
        const tmp = { ...empresa };
        tmp[property] = value;

        setEmpresa(tmp);
    }

    const handleChangeDataTema = (property, subproperty, subsubproperty, subsubsubproperty, value) => {
        const theme = { ...themeStorageSelected.theme };

        if (subproperty)
            if (subsubproperty !== null)
                if (subsubsubproperty !== null)
                    theme[property][subproperty][subsubproperty][subsubsubproperty] = value;
                else
                    theme[property][subproperty][subsubproperty] = value;
            else
                theme[property][subproperty] = value;
        else
            theme[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_THEME,
            themeStorageId: themeStorage.idThemeStoreSelected,
            theme: theme
        });
    }

    const handleSelectMenu = () => {
        const menus = [...themeStorageSelected.menu];
        menus.forEach(m => {
            //Padrão
            if (themeStorage.tipoThema === 1 && m.type === 'padrao') {
                if (m.id === 'home' || m.id === 'produtos' || m.id === 'lojas' || m.id === 'promocoes' || m.id === 'lancamentos' || m.id === 'aboutUs' || m.id === 'contact' || m.id === 'newIn') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Coffee
            else if (themeStorage.tipoThema === 2 && m.type === 'coffee') {
                if (m.id === 'paginaInicial' || m.id === 'colecao' || m.id === 'nossoHistoria' || m.id === 'faleConosco') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Vanilla
            else if (themeStorage.tipoThema === 3 && m.type === 'vanilla') {
                if (m.id === 'paginaInicial' || m.id === 'nossaHistoria' || m.id === 'lojaVanilla' || m.id === 'contatoVanilla') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Chicago
            else if (themeStorage.tipoThema === 4 && m.type === 'chicago') {
                if (m.id === 'paginaInicial' || m.id === 'ofertas' || m.id === 'produtos' || m.id === 'nossaHistoria') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Lolly
            else if (themeStorage.tipoThema === 5 && m.type === 'lollyPop') {
                if (m.id === 'paginaInicial' || m.id === 'produtos' || m.id === 'ofertas' || m.id === 'faleConosco' || m.id === 'nossaHistoria')
                    m.selected = true;
                else
                    m.selected = false;
            }
            //Lolly
            else if (themeStorage.tipoThema === 6 && m.type === 'coral') {
                if (m.id === 'verColecao' || m.id === 'ocasioes' || m.id === 'roupas' || m.id === 'promocoes' || m.id === 'mais')
                    m.selected = true;
                else
                    m.selected = false;
            }
            else {
                m.selected = false;
            }

            return m;
        });

        dispatch({
            type: TEMA_LOJA_SET_MENU,
            themeStorageId: themeStorage.idThemeStoreSelected,
            opcoesMenu: menus
        });
    }

    const handleDeletarTemaAtual = async () => {
        const result = await PUT_DATA_DESIGN(`ConstrutorSite/ExcluirSite?id=${construtorSite.id}`, tokenDesign);

        if (result) {
            dispatch({
                type: TEMA_LOJA_SET,
                data: null
            });

            await GetDadosTema();
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso ao deletar'
            });
        }
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTabConfigAvancada(newValue);
    };

    const handleCep = (e) => {
        if (e.target.value.length === 8) {
            axios
                .get(`https://viacep.com.br/ws/${e.target.value}/json/`)
                .then((res) => {
                    if (!res.data.erro) {
                        handleChangeEndereco(`endereco`, res.data.logradouro);
                        handleChangeEndereco(`complemento`, res.data.complemento);
                        handleChangeEndereco(`numero`, res.data.siafi);
                        handleChangeEndereco(`bairro`, res.data.bairro);
                        handleChangeEndereco(`cidade`, res.data.localidade);
                        handleChangeEndereco(`uf`, res.data.uf);
                    }
                });
        }
    };

    const CardImagemLayout = ({ urlImagem, nomeTema }) => {
        const [openModal, setOpenModal] = useState(false);

        return <>
            <Modal
                key="modal"
                open={openModal}
                style={{
                    width: '100%',
                    height: '100%',
                }}
                keepMounted
                disableAutoFocus
            >
                <Box
                    sx={[
                        containerModal,
                        {
                            width: 1070,
                            height: 600,
                            overflowY: null,
                            borderRadius: 2,
                            backgroundColor: '#ffff',
                        },
                    ]}
                >
                    <Box style={{ width: '100%' }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 15,
                                top: 35,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                    </Box>
                    <img src={urlImagem} width={1024} alt={nomeTema} />
                </Box>

            </Modal>

            <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={urlImagem} width={'100%'} alt={nomeTema} />
                <Button style={{ marginTop: 10, background: 'black', color: 'white' }} onClick={() => setOpenModal(true)}>Expandir</Button>
            </Box>
        </>
    }

    if (showValidacaoMobie) {
        if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus) {
            const objOnboard = { ...onBoard };
            objOnboard.IdUltimoOnboardingStoreItem = 3;

            const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0];
            itemOnboard.FlagStatus = true;

            atualizarOnboardStore(objOnboard, itemOnboard);
            dispatch({
                type: SET_DADOS_ON_BOARD,
                data: objOnboard
            });
        }

        return <Box style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d3d3d3' }}>
            <Box style={{ width: '100%', height: '100%', minHeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ width: 350, height: 'auto', minHeight: 400, padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box style={{ width: '100%' }}>
                        <InfoIcon style={{ fontSize: 60 }} color="primary" />
                    </Box>
                    <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Para continuar, recomendamos o uso de um computador ou tablet para melhor desempenho e experiência.</Typography>

                    <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Gostaria de continuar no computador? Envie este link para seu email e acesse de lá.</Typography>

                    <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid #c0c0c0', padding: 10, borderRadius: 10 }}>https://crm.leapstyle.me/ <ContentCopyIcon style={{ marginLeft: 15, fontSize: 25, cursor: 'pointer' }} onClick={() => {
                        try {
                            navigator.clipboard.writeText('https://crm.leapstyle.me/');

                            dispatch({
                                type: CONTROLEMENSAGEM_SUCESSO,
                                tipoComponente: 'alert',
                                titulo: 'Aviso',
                                message: 'Link copiado',
                            });
                        }
                        catch (err) {

                        }
                    }} /></Typography>

                    <Button variant="contained" onClick={() => {
                        navigate(-1);
                    }}>Voltar</Button>
                </Card>
            </Box>

        </Box>
    }

    const handleSaveCrop = async () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
            handleImagemCrop(canvas, true);
        }
    };

    try {
        return loading ? location?.state?.idTemaSelecionado ? <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh' }}>
            <Box style={{ width: '800px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#faa03c', paddingLeft: 20, paddingRight: 20 }}>
                <Typography style={{ color: '#ffff', fontSize: 18, fontWeight: 'bold' }}>Neste momento estamos configurando sua loja.  Por favor não feche sua tela até a conclusão, isso pode levar até 2 minutos</Typography>

                <Box style={{ width: '100%', borderRadius: 30, background: 'white', padding: 10, marginTop: 20 }}>
                    <LinearProgress sx={{ width: '100%', height: 25, borderRadius: 10, backgroundColor: '#faa03c' }} />
                </Box>
                <Typography style={{ color: '#ffff', fontSize: 15 }}>Gerando...</Typography>
            </Box>
        </Box>
            : <Stack
                sx={{ height: "450px", marginTop: '10%' }}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress />
                <Typography sx={{ mt: 1 }}>Carregando dados do eCommerce</Typography>
            </Stack>
            : (
                <Container>
                    <Confirmation
                        title="Deletar"
                        mensagem="Ao prosseguir os dados já configurados serão perdidos! Deseja continuar?"
                        btn1={{ title: "Cancelar", onClick: null }}
                        btn2={{
                            title: "Excluir",
                            onClick: async () => {
                                await handleDeletarTemaAtual();
                            }
                        }}
                    />

                    {
                        (openModalImagemCrop && themeStorageSelected?.logo?.url) &&
                        <Modal
                            key="modal"
                            open={openModalImagemCrop}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            keepMounted
                            disableAutoFocus
                        >
                            <Box
                                sx={[
                                    containerModal,
                                    {
                                        width: 400,
                                        height: 400,
                                        overflowY: null,
                                        borderRadius: 2,
                                        backgroundColor: '#ffff',
                                    },
                                ]}
                            >
                                <Box style={{ width: '100%' }}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setOpenModalImagemCrop(false)}
                                        sx={{
                                            position: 'absolute',
                                            right: 15,
                                            top: 35,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>

                                </Box>

                                {
                                    imagem &&
                                    <div style={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <AvatarEditor
                                            ref={editorRef}
                                            image={imagem}
                                            width={200}
                                            height={200}
                                            border={20}
                                            borderRadius={0} // Circular
                                            scale={zoom}
                                        />
                                        <div className="controls" style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                                            <input
                                                type="range"
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.05}
                                                aria-labelledby="Zoom"
                                                onChange={(e) => {
                                                    setZoom(e.target.value)
                                                }}
                                                className="zoom-range"
                                            />
                                        </div>
                                        <Button style={{ marginTop: 15 }} variant="contained" onClick={() => {
                                            handleSaveCrop();
                                            setOpenModalImagemCrop(false);
                                        }}>Salvar</Button>
                                    </div>
                                }
                            </Box>

                        </Modal>
                    }

                    <Box style={{ width: "100%", display: 'flex', flexWrap: 'wrap' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / eCommerce</Typography>
                        <Box style={{ width: "100%", marginTop: 10, display: 'flex', flexWrap: 'nowrap', justifyContent: construtorSite?.dataCriacao ? 'space-between' : 'end' }}>
                            {construtorSite !== null && construtorSite.id > 0 && <Button variant='contained' style={{ background: 'black' }} onClick={() => getConfirmation()}>Deletar Tema</Button>}
                            {
                                construtorSite?.dataCriacao &&
                                <Typography style={{ fontWeight: 'bold' }}> Última Alt.: {construtorSite?.dataAlteracao ? moment(construtorSite.dataAlteracao).format('DD/MM/YYYY') : moment(construtorSite.dataCriacao).format('DD/MM/YYYY')}</Typography>
                            }
                            <Button disabled={themeStorage?.tipoThema === 0 || loading} variant='contained' onClick={() => handlePublish(true)}>
                                {loading && <Loader />}
                                Publicar / Visualizar
                            </Button>
                        </Box>
                    </Box>
                    <Grid container spacing={3} style={{ marginTop: 2 }}>
                        {
                            validacoes?.length > 0 &&
                            <Grid item xs={12} style={{ padding: 20, paddingLeft: 50, paddingRight: 50 }}>
                                <Box style={{ width: '100%', border: '1px solid #d4d4d4', height: 'auto', padding: 20, borderRadius: 20 }} dangerouslySetInnerHTML={{ __html: validacoes }} />
                            </Grid>
                        }
                        <Grid item xs={3}>
                            <Timeline position="alternate">
                                <TimelineItem>
                                    <TimelineOppositeContent
                                        sx={{ m: 'auto 0', marginTop: 0 }}
                                        align="right"
                                        variant="body1"
                                        color="text.secondary"
                                    >
                                        <span style={{ fontSize: 20, borderRadius: 30, background: themeStorage?.tipoThema > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>1</span>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator style={{ height: 270 }}>
                                        <TimelineConnector />
                                        <TimelineDot color="primary">
                                            <ChecklistIcon />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2, marginTop: 10 }}>
                                        <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                            Tema
                                        </Typography>
                                        <Typography>Tema selecionado</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Grid>
                        <Grid item xs={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {
                                temaSelecionado !== null &&
                                <>
                                    <Box style={{ width: 400, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img width={'100%'} height={'320px'} src={temaSelecionado.UrlImagem} alt='Tema selecionado' />
                                        <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{temaSelecionado.Nome}</Typography>
                                    </Box>
                                    <Box style={{ width: 550, marginLeft: 15, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {
                                            themeStorage?.tipoThema === 2 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_coffee_template.png'} nomeTema='Tema coffee' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 3 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_vanilla_template.png'} nomeTema='Tema vanilla' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 4 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_chicago_template.png'} nomeTema='Tema chicago' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 5 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_lollypop_template.png'} nomeTema='Tema lollypop' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 6 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_coral_template.png'} nomeTema={'Tema Coral'} />
                                        }
                                        {
                                            themeStorage?.tipoThema === 7 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_blush_template.png'} nomeTema='Tema blush' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 8 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_midnight_template.png'} nomeTema='Tema Midnight' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 9 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_pearl_template.png'} nomeTema='Tema Pearl' />
                                        }
                                        {
                                            themeStorage?.tipoThema === 10 &&
                                            <CardImagemLayout urlImagem={'https://leapstyle.blob.core.windows.net/temas-template/estrutura_grpe_template.png'} nomeTema='Tema Grape' />
                                        }
                                    </Box>
                                </>
                            }
                        </Grid>

                        {
                            themeStorage?.tipoThema === 5 &&
                            <>
                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorage?.tipoThema > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>1.1</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 770 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <ChecklistIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 43 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Cor do Tema
                                                </Typography>
                                                <Typography>Selecionado a cor do Tema</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <Box style={{ width: '100%' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} style={{ paddingTop: 100 }}>

                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    style={{ marginTop: -20 }}
                                                    control={
                                                        <Select
                                                            variant="outlined"
                                                            id="label-select"
                                                            value={themeStorageSelected?.theme?.palette?.personal?.id}
                                                            onChange={(e) => {
                                                                const id = e.target.value;
                                                                const pallete = listPallete.filter(a => a.id === id)[0];

                                                                if (!pallete) {
                                                                    handleChangeDataTema('palette', 'selectedTheme', null, null, 'personal');
                                                                    handleChangeDataTema('palette', 'personal', 'id', null, id);
                                                                    handleChangeDataTema('palette', 'personal', 'component', 'background', 'white');
                                                                    handleChangeDataTema('palette', 'personal', 'background', 'paper', 'white');

                                                                    return;
                                                                };

                                                                handleChangeDataTema('palette', 'selectedTheme', null, null, 'personal');
                                                                handleChangeDataTema('palette', 'personal', 'id', null, id);
                                                                handleChangeDataTema('palette', 'personal', 'component', 'background', pallete.background.default);
                                                                handleChangeDataTema('palette', 'personal', 'background', 'paper', pallete.background.paper);


                                                            }}
                                                            MenuProps={{
                                                                disablePortal: true,
                                                                PaperProps: {
                                                                    style: {
                                                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                    },
                                                                },
                                                            }}
                                                            style={{ marginTop: 10, marginLeft: -20, width: '250px', color: 'black' }}
                                                        >
                                                            <MenuItem value="personal">Nenhum(a)</MenuItem>
                                                            {
                                                                listPallete.map((pallete) => {
                                                                    return <MenuItem key={pallete.id} value={pallete.id}><span style={{ width: 15, height: 15, borderRadius: 50, background: pallete.background.default, marginRight: 10 }}></span>{pallete.descricao}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    }
                                                    label={<span style={{ fontWeight: 'bold' }}>Cores Loja</span>}
                                                />


                                                <Box style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                                                    <Typography>Cor de fundo cabeçalho e rodapé: </Typography><span style={{ marginTop: 7, height: 15, width: 100, background: themeStorageSelected?.theme?.palette?.personal?.component?.background, border: '1px solid #dfdfdf' }}></span>
                                                </Box>
                                                <Box style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                                                    <Typography>Cor do texto do cabeçalho e rodapé: </Typography><span style={{ marginTop: 7, height: 15, width: 100, background: themeStorageSelected?.theme?.palette?.personal?.component?.color, border: '1px solid #dfdfdf' }}></span>
                                                </Box>

                                                <Box style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                                                    <Typography>Cor de fundo página: </Typography><span style={{ marginTop: 7, height: 15, width: 100, background: themeStorageSelected?.theme?.palette?.personal?.background.paper, border: '1px solid #dfdfdf' }}></span>
                                                </Box>

                                            </Grid>

                                            <Grid item xs={9} style={{ paddingTop: 50, display: 'flex', justifyContent: 'center' }}>
                                                <img src={planoFundo} alt="Plano de fundo" width={470} style={{ position: 'absolute', zIndex: 1 }} />

                                                <Box style={{ width: 300, height: 653, zIndex: 2, marginLeft: 13, marginTop: 98, borderRadius: 10 }}>
                                                    <Box style={{ background: themeStorageSelected?.theme?.palette?.personal?.component?.background, width: '100%', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography style={{ fontWeight: 'bold', color: themeStorageSelected?.theme?.palette?.personal?.component?.color }}>Mensagens de ofertas</Typography>
                                                    </Box>
                                                    <Divider style={{ width: '100%' }} />
                                                    <Box style={{ background: 'white', width: '100%', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Cabeçalho</Typography>
                                                    </Box>
                                                    <Box style={{ background: themeStorageSelected?.theme?.palette?.personal?.background.paper, width: '100%', height: '366px', paddingBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <Box style={{ marginTop: 20, width: 240, height: '80px', background: '#f6f6f6', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography style={{ fontWeight: 'bold' }}>Banner principal</Typography>
                                                        </Box>
                                                        <Grid container spacing={3} style={{ width: 260, marginTop: 5, paddingTop: 10, paddingLeft: 22, justifyContent: 'space-between' }}>
                                                            <Grid item xs={5.9} style={{ background: '#f6f6f6', height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography style={{ marginLeft: -17, fontWeight: 'bold' }}>Produto 1</Typography>

                                                                <Button variant="contained" style={{ marginLeft: -17, fontSize: 10, background: themeStorageSelected?.theme?.palette?.personal?.component?.background }}>Comprar</Button>
                                                            </Grid>
                                                            <Grid item xs={5.9} style={{ background: '#f6f6f6', height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography style={{ marginLeft: -17, fontWeight: 'bold' }}>Produto 2</Typography>

                                                                <Button variant="contained" style={{ marginLeft: -17, fontSize: 10, background: themeStorageSelected?.theme?.palette?.personal?.component?.background }}>Comprar</Button>
                                                            </Grid>
                                                            <Grid item xs={5.9} style={{ background: '#f6f6f6', marginTop: 5, height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography style={{ marginLeft: -17, fontWeight: 'bold' }}>Produto 3</Typography>

                                                                <Button variant="contained" style={{ marginLeft: -17, fontSize: 10, background: themeStorageSelected?.theme?.palette?.personal?.component?.background }}>Comprar</Button>
                                                            </Grid>
                                                            <Grid item xs={5.9} style={{ background: '#f6f6f6', marginTop: 5, height: 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography style={{ marginLeft: -17, fontWeight: 'bold' }}>Produto 4</Typography>

                                                                <Button variant="contained" style={{ marginLeft: -17, fontSize: 10, background: themeStorageSelected?.theme?.palette?.personal?.component?.background }}>Comprar</Button>
                                                            </Grid>

                                                        </Grid>
                                                    </Box>
                                                    <Box style={{ width: '100%', paddingTop: 10, marginTop: -10 }}>
                                                        <Box style={{ background: themeStorageSelected?.theme?.palette?.personal?.component?.background, width: '100%', marginTop: 10, height: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography style={{ fontWeight: 'bold', color: themeStorageSelected?.theme?.palette?.personal?.component?.color }}>Rodapé 1</Typography>
                                                        </Box>
                                                        <Box style={{ background: themeStorageSelected?.theme?.palette?.personal?.background.paper, background: '#f6f6f6', width: '100%', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography style={{ fontWeight: 'bold', color: 'black' }}>Rodapé 2</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            </>
                        }

                        {
                            themeStorage && ((superUser != null && themeStorage?.tipoThema === 1) || themeStorage?.tipoThema > 1) &&
                            <>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.logo?.url && themeStorageSelected?.logo?.url.length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>2</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 200 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <ImageIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 5 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Logo
                                                </Typography>
                                                <Typography>Informe seu logo (200X200)</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex' }}>
                                    <UploadImagemDesign
                                        dados={themeStorageSelected?.logo}
                                        isEditing={true}
                                        key={'logo_1'}
                                        id={'logo_1'}
                                        idComponent={'logo_1'}
                                        imgData={themeStorageSelected?.logo?.url}
                                        onRemove={() => setOpenModalImagemCrop(true)}
                                        onChange={handleOnChangeLogo}
                                        borderRadiusImg={0}
                                        paddingTopImg={0}
                                        widthImg='100%'
                                        heightImg={140}
                                        style={{ width: 150, height: 220 }}
                                        descricao="200x200"

                                        validarDimensoes={false}

                                        dimensaoHeightImg={200}
                                        dimensaoWidthImg={200}
                                    />

                                    <Box style={{ with: '100%', marginLeft: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Selecione o tipo de logo (Prévia)</Typography>

                                            </Grid>
                                            <Grid item xs={4} style={{ display: 'flex', width: 250, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <img
                                                    style={{ borderRadius: 50, height: 100, width: 100, padding: 2, border: '1px dashed black' }}
                                                    src={themeStorageSelected?.logo?.url ? themeStorageSelected?.logo?.url : SuaLogo}
                                                />
                                                <Typography style={{ textAlign: 'center', marginTop: 5 }}>Circulo</Typography>
                                                <Checkbox
                                                    name='Circulor'
                                                    id='circulo'
                                                    checked={themeStorageSelected?.logo?.tipo_logo === 1 ? true : false}
                                                    onChange={() => handleChangeTipoLogo(1)}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ display: 'flex', width: 250, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <img
                                                    style={{ width: 100, height: 100, border: '1px dashed black' }}
                                                    src={themeStorageSelected?.logo?.url ? themeStorageSelected?.logo?.url : SuaLogo}
                                                />
                                                <Typography style={{ textAlign: 'center', marginTop: 5 }}>Quadrado</Typography>
                                                <Checkbox
                                                    name='Circulor'
                                                    id='circulo'
                                                    checked={themeStorageSelected?.logo?.tipo_logo === 2 ? true : false}
                                                    onChange={() => handleChangeTipoLogo(2)}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ display: 'flex', width: 250, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <img
                                                    style={{ width: 150, height: 100, border: '1px dashed black' }}
                                                    src={themeStorageSelected?.logo?.url ? themeStorageSelected?.logo?.url : SuaLogo}
                                                />
                                                <Typography style={{ textAlign: 'center', marginTop: 5 }}>Retangulo</Typography>
                                                <Checkbox
                                                    name='Circulor'
                                                    id='circulo'
                                                    checked={themeStorageSelected?.logo?.tipo_logo === 3 ? true : false}
                                                    onChange={() => handleChangeTipoLogo(3)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.logo?.url && themeStorageSelected?.logo?.url.length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>3</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 200 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <ImageIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{
                                                py: '12px',
                                                px: 2,
                                                marginTop: 5
                                            }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Opções do site
                                                </Typography>
                                                <Typography>Informe o banner web e mobile</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <OpcoesSite />
                                </Grid>

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.logo?.url && themeStorageSelected?.logo?.url.length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>4</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: (themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4 || themeStorage?.tipoThema === 7 || themeStorage?.tipoThema === 8 || themeStorage?.tipoThema === 10) ? 1380 : themeStorage?.tipoThema === 3 ? 850 : themeStorage?.tipoThema === 6 ? 1850 : 550 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <ImageIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{
                                                py: '12px',
                                                px: 2,
                                                marginTop: (themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4 || themeStorage?.tipoThema === 7 || themeStorage?.tipoThema === 8 || themeStorage?.tipoThema === 10) ? 80 : themeStorage?.tipoThema === 3 ? 45 : themeStorage?.tipoThema === 6 ? 107 : 30
                                            }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Banners
                                                </Typography>
                                                <Typography>Informe o banner web e mobile</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9}>
                                    <Card sx={{ width: '100%', marginTop: 2, padding: 2 }}>
                                        <Typography style={{ fontWeight: 'bold', marginBottom: 3, fontSize: 18 }}>Banner Topo</Typography>

                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={carrosselBannerTab} onChange={handleChangeTabs} aria-label="basic tabs example">
                                                {themeStorageSelected?.carrossel_images && themeStorageSelected.carrossel_images.filter(a => a.type !== 'config').map((imagem, index) => {
                                                    return (
                                                        <Tab key={index} style={{ fontSize: '16px' }} label={`Banner Imagem ${index + 1}`} {...a11yProps(index)} />
                                                    )
                                                })}
                                            </Tabs>
                                        </Box>
                                        {themeStorageSelected?.carrossel_images && themeStorageSelected.carrossel_images.filter(a => a.type !== 'config').map((imagem, index) => {
                                            return (
                                                carrosselBannerTab === index &&
                                                <Box key={`carrossel-${index}`} style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Box style={{ width: '58%', marginLeft: 10 }}>
                                                            <Typography style={{ marginBottom: 5 }}>Banner Web</Typography>
                                                            <UploadImagemDesign
                                                                dados={imagem}
                                                                isEditing={true}
                                                                key={imagem.id}
                                                                id={imagem.id}
                                                                idComponent={`bannerTop_web_${index}`}
                                                                dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                imgData={imagem.url}
                                                                onChange={handleOnChangeImagemCarrossel}
                                                                borderRadiusImg={0}
                                                                paddingTopImg={0}
                                                                widthImg='100%'
                                                                heightImg={100}
                                                                style={{ height: 200 }}
                                                                descricao={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                            />
                                                        </Box>
                                                        <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                            <Typography style={{ marginBottom: 5 }}>Banner Mobile</Typography>
                                                            <UploadImagemDesign
                                                                dados={imagem}
                                                                isEditing={true}
                                                                mobile={true}
                                                                key={imagem.id}
                                                                id={imagem.id}
                                                                idComponent={`bannerTop_mobile_${index}`}
                                                                dimensaoHeightImg={heightMobile}
                                                                dimensaoWidthImg={widthMobile}
                                                                imgData={imagem.url_mobile}
                                                                onChange={handleOnChangeImagemCarrossel}
                                                                borderRadiusImg={0}
                                                                paddingTopImg={0}
                                                                widthImg='100%'
                                                                heightImg={100}
                                                                style={{ height: 200 }}
                                                                descricao={dimensoesMobileStr}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Grid container spacing={2} style={{ marginTop: 2 }}>
                                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Checkbox
                                                                name='bannerTopo'
                                                                id='bannerTopo'
                                                                checked={themeStorageSelected?.carrossel_images?.length > 0 ? themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                                onChange={() => {
                                                                    const chk = themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage;
                                                                    handleCheckFullPageBanners('carrossel_images', !chk);
                                                                }}
                                                            />
                                                            <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>

                                                            <FormControl style={{ width: 150, marginLeft: 15 }}>
                                                                <InputLabel id="demo-simple-select-label">Ordenação</InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    labelId="demo-simple-select-label"
                                                                    id="select_order_banner1"
                                                                    defaultValue='1'
                                                                    value={imagem?.ordenacao || 1}
                                                                    label="Ordenação"
                                                                    onChange={(e) => {
                                                                        handleChangeDataPageBanners(index, 'carrossel_images', 'ordenacao', Number(e.target.value))
                                                                    }}
                                                                    MenuProps={{
                                                                        disablePortal: true,
                                                                        PaperProps: {
                                                                            style: {
                                                                                transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                            },
                                                                        },
                                                                    }}
                                                                >
                                                                    {
                                                                        themeStorageSelected.carrossel_images.filter(a => a.type !== 'config').map((banner, idxBanner) => {
                                                                            return <MenuItem key={`option_order_${idxBanner}`} value={(idxBanner + 1)}>{idxBanner + 1}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>


                                                        </Grid>

                                                        <Grid item xs={12} style={{ paddingLeft: 25 }}>
                                                            <FormControl>
                                                                <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultChecked={imagem.filtrarProdutoBy || 'nenhum'}
                                                                    name="radio-buttons-group"
                                                                    direction="row"
                                                                    style={{ display: 'flex', flexDirection: 'row' }}
                                                                    onChange={(e) => {
                                                                        handleChangeDataPageBanners(index, 'carrossel_images', 'filtrarProdutoBy', e.target.value)
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                    <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                    <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                    <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>

                                                    </Grid>
                                                </Box>
                                            )
                                        })}
                                    </Card>

                                    {
                                        (themeStorage?.tipoThema !== 3 && themeStorage?.tipoThema !== 5 && themeStorage?.tipoThema !== 13) &&
                                        <>
                                            <Card sx={{ width: '100%', marginTop: 3, padding: 2 }}>
                                                {themeStorageSelected?.banner1 && themeStorageSelected.banner1.filter(a => a.type !== 'config').map((imagem, index) => {
                                                    return (
                                                        0 === index &&
                                                        <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                            <Typography style={{ fontWeight: 'bold', marginBottom: 10, marginTop: -10, fontSize: 18 }}>
                                                                {
                                                                    themeStorage?.tipoThema === 11 ? 'Banner Meninas' : 'Banner Meio'
                                                                }
                                                            </Typography>

                                                            <Box style={{ display: 'flex' }}>
                                                                <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                    <Typography style={{ marginBottom: 5 }}>Banner Web</Typography>
                                                                    <UploadImagemDesign
                                                                        dados={imagem}
                                                                        isEditing={true}
                                                                        key={imagem.id}
                                                                        id={imagem.id}
                                                                        idComponent={`bannerMeio_web_${index}`}
                                                                        dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                        dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                        imgData={imagem.url}
                                                                        onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner1')}
                                                                        borderRadiusImg={0}
                                                                        paddingTopImg={0}
                                                                        widthImg='100%'
                                                                        heightImg={100}
                                                                        style={{ height: 200 }}
                                                                        descricao={themeStorageSelected?.banner1?.length > 0 && themeStorageSelected?.banner1.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                    />
                                                                </Box>
                                                                <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                    <Typography style={{ marginBottom: 5 }}>Banner Mobile</Typography>
                                                                    <UploadImagemDesign
                                                                        dados={imagem}
                                                                        isEditing={true}
                                                                        mobile={true}
                                                                        key={imagem.id}
                                                                        id={imagem.id}
                                                                        idComponent={`bannerMeio_mobile_${index}`}
                                                                        dimensaoHeightImg={heightMobile}
                                                                        dimensaoWidthImg={widthMobile}
                                                                        imgData={imagem.url_mobile}
                                                                        onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner1')}
                                                                        borderRadiusImg={0}
                                                                        paddingTopImg={0}
                                                                        widthImg='100%'
                                                                        heightImg={100}
                                                                        style={{ height: 200 }}
                                                                        descricao={dimensoesMobileStr}
                                                                    />
                                                                </Box>
                                                            </Box>

                                                            <Grid container spacing={2} style={{ marginTop: 2 }}>
                                                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Checkbox
                                                                        name='bannerTopo'
                                                                        id='bannerTopo'
                                                                        checked={themeStorageSelected?.banner1?.length > 0 ? themeStorageSelected?.banner1.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                                        onChange={() => {
                                                                            const chk = themeStorageSelected?.banner1.filter(a => a.type === 'config')[0]?.fullPage;
                                                                            handleCheckFullPageBanners('banner1', !chk);
                                                                        }}
                                                                    />
                                                                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>

                                                                    {
                                                                        themeStorageSelected?.banner1.filter(a => a.type === 'config').length > 1 &&
                                                                        <FormControl style={{ width: 150, marginLeft: 15 }}>
                                                                            <InputLabel id="demo-simple-select-label">Ordenação</InputLabel>
                                                                            <Select
                                                                                fullWidth
                                                                                labelId="demo-simple-select-label"
                                                                                id="select_order_bannerTopo"
                                                                                defaultValue='1'
                                                                                value={imagem?.ordenacao || 1}
                                                                                label="Ordenação"
                                                                                onChange={(e) => {
                                                                                    handleChangeDataPageBanners(index, 'banner1', 'ordenacao', Number(e.target.value));
                                                                                }}
                                                                                MenuProps={{
                                                                                    disablePortal: true,
                                                                                    PaperProps: {
                                                                                        style: {
                                                                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {
                                                                                    themeStorageSelected?.banner1.filter(a => a.type === 'config').map((banner, idxBanner) => {
                                                                                        return <MenuItem key={`option_order_${idxBanner}`} value={(idxBanner + 1)}>{idxBanner + 1}</MenuItem>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    }

                                                                </Grid>

                                                                <Grid xs={12} style={{ paddingLeft: 25, marginTop: 10 }}>
                                                                    <FormControl>
                                                                        <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                            name="radio-buttons-group"
                                                                            direction="row"
                                                                            style={{ display: 'flex', flexDirection: 'row' }}
                                                                            onChange={(e) => {
                                                                                handleChangeDataPageBanners(index, 'banner1', 'filtrarProdutoBy', e.target.value);
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                            <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                            <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                            <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    )
                                                })}
                                            </Card>

                                            {
                                                (themeStorage?.tipoThema === 6 || themeStorage?.tipoThema === 10 || themeStorage?.tipoThema === 11) &&
                                                <Card sx={{ width: '100%', marginTop: 3, padding: 2 }}>
                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 3, fontSize: 18 }}>
                                                        {
                                                            themeStorage?.tipoThema === 11 ? 'Banner Meninos' : 'Banner Cupom'
                                                        }
                                                    </Typography>

                                                    {themeStorageSelected?.bannerCupom && themeStorageSelected.bannerCupom.filter(a => a.type !== 'config').map((imagem, index) => {
                                                        return (
                                                            0 === index &&
                                                            <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                                <Box style={{ display: 'flex' }}>
                                                                    <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                        <Typography style={{ marginBottom: 5 }}>Banner Web</Typography>
                                                                        <UploadImagemDesign
                                                                            dados={imagem}
                                                                            isEditing={true}
                                                                            key={imagem.id}
                                                                            id={imagem.id}
                                                                            idComponent={`bannerCupom_web_${index}`}
                                                                            dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                            dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                            imgData={imagem.url}
                                                                            onChange={(data) => handleOnChangeImagemCarrossel(data, 'bannerCupom')}
                                                                            borderRadiusImg={0}
                                                                            paddingTopImg={0}
                                                                            widthImg='100%'
                                                                            heightImg={100}
                                                                            style={{ height: 200 }}
                                                                            descricao={themeStorageSelected?.bannerCupom?.length > 0 && themeStorageSelected?.bannerCupom.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                        />
                                                                    </Box>
                                                                    <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                        <Typography style={{ marginBottom: 5 }}>Banner Mobile</Typography>
                                                                        <UploadImagemDesign
                                                                            dados={imagem}
                                                                            isEditing={true}
                                                                            mobile={true}
                                                                            key={imagem.id}
                                                                            id={imagem.id}
                                                                            idComponent={`bannerCupom_mobile_${index}`}
                                                                            dimensaoHeightImg={heightMobile}
                                                                            dimensaoWidthImg={widthMobile}
                                                                            imgData={imagem.url_mobile}
                                                                            onChange={(data) => handleOnChangeImagemCarrossel(data, 'bannerCupom')}
                                                                            borderRadiusImg={0}
                                                                            paddingTopImg={0}
                                                                            widthImg='100%'
                                                                            heightImg={100}
                                                                            style={{ height: 200 }}
                                                                            descricao={dimensoesMobileStr}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Checkbox
                                                                            name='bannerCupom'
                                                                            id='bannerCupom'
                                                                            checked={themeStorageSelected?.bannerCupom?.length > 0 ? themeStorageSelected?.bannerCupom.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                                            onChange={() => {
                                                                                const chk = themeStorageSelected?.bannerCupom.filter(a => a.type === 'config')[0]?.fullPage;
                                                                                handleCheckFullPageBanners('bannerCupom', !chk);
                                                                            }}
                                                                        />
                                                                        <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>

                                                                        {
                                                                            themeStorageSelected?.bannerCupom.filter(a => a.type === 'config').length > 0 &&
                                                                            <FormControl style={{ width: 150, marginLeft: 15 }}>
                                                                                <InputLabel id="demo-simple-select-label">Ordenação</InputLabel>
                                                                                <Select
                                                                                    fullWidth
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="select_order_bannerCupom"
                                                                                    defaultValue='1'
                                                                                    value={imagem?.ordenacao || 1}
                                                                                    label="Ordenação"
                                                                                    onChange={(e) => {
                                                                                        handleChangeDataPageBanners(index, 'bannerCupom', 'ordenacao', e.target.value);
                                                                                    }}
                                                                                    MenuProps={{
                                                                                        disablePortal: true,
                                                                                        PaperProps: {
                                                                                            style: {
                                                                                                transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        themeStorageSelected?.bannerCupom.filter(a => a.type === 'config').map((banner, idxBanner) => {
                                                                                            return <MenuItem key={`option_order_${idxBanner}`} value={(idxBanner + 1)}>{idxBanner + 1}</MenuItem>
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        }

                                                                    </Grid>

                                                                    <Grid xs={12} style={{ marginTop: 10, paddingLeft: 25 }}>
                                                                        <FormControl>
                                                                            <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                                name="radio-buttons-group"
                                                                                direction="row"
                                                                                style={{ display: 'flex', flexDirection: 'row' }}
                                                                                onChange={(e) => {
                                                                                    handleChangeDataPageBanners(index, 'bannerCupom', 'filtrarProdutoBy', e.target.value);
                                                                                }}
                                                                            >
                                                                                <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                                <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                                <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                                <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        )
                                                    })}
                                                </Card>
                                            }

                                            {
                                                (themeStorage?.tipoThema !== 10 && themeStorage?.tipoThema !== 5 && themeStorage?.tipoThema !== 12) &&
                                                <Card sx={{ width: '100%', marginTop: 2, padding: 2 }}>
                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 3, fontSize: 18 }}>Banner Fim</Typography>

                                                    {themeStorageSelected?.banner2 && themeStorageSelected.banner2.filter(a => a.type !== 'config').map((imagem, index) => {
                                                        return (
                                                            0 === index &&
                                                            <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                                <Box style={{ display: 'flex' }}>
                                                                    <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                        <Typography style={{ marginBottom: 5 }}>Banner Web</Typography>
                                                                        <UploadImagemDesign
                                                                            dados={imagem}
                                                                            isEditing={true}
                                                                            mobile={false}
                                                                            key={imagem.id}
                                                                            id={imagem.id}
                                                                            idComponent={`bannerFim_web_${index}`}
                                                                            dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                            dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                            imgData={imagem.url}
                                                                            onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner2')}
                                                                            borderRadiusImg={0}
                                                                            paddingTopImg={0}
                                                                            widthImg='100%'
                                                                            heightImg={100}
                                                                            style={{ height: 200 }}
                                                                            descricao={themeStorageSelected?.banner2?.length > 0 && themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                        />
                                                                    </Box>
                                                                    <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                        <Typography style={{ marginBottom: 5 }}>Banner Mobile</Typography>
                                                                        <UploadImagemDesign
                                                                            dados={imagem}
                                                                            isEditing={true}
                                                                            mobile={true}
                                                                            key={imagem.id}
                                                                            id={imagem.id}
                                                                            idComponent={`bannerFim_mobile_${index}`}
                                                                            dimensaoHeightImg={heightMobile}
                                                                            dimensaoWidthImg={widthMobile}
                                                                            imgData={imagem.url_mobile}
                                                                            onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner2')}
                                                                            borderRadiusImg={0}
                                                                            paddingTopImg={0}
                                                                            widthImg='100%'
                                                                            heightImg={100}
                                                                            style={{ height: 200 }}
                                                                            descricao={dimensoesMobileStr}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Checkbox
                                                                            name='bannerTopo'
                                                                            id='bannerTopo'
                                                                            checked={themeStorageSelected?.banner2?.length > 0 ? themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                                            onChange={() => {
                                                                                const chk = themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage;
                                                                                handleCheckFullPageBanners('banner2', !chk);
                                                                            }}
                                                                        />
                                                                        <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>

                                                                        {
                                                                            themeStorageSelected?.banner2.filter(a => a.type === 'config').length > 1 &&
                                                                            <FormControl style={{ width: 150, marginLeft: 15 }}>
                                                                                <InputLabel id="demo-simple-select-label">Ordenação</InputLabel>
                                                                                <Select
                                                                                    fullWidth
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="select_order_banner2"
                                                                                    defaultValue='1'
                                                                                    value={imagem?.ordenacao || 1}
                                                                                    label="Ordenação"
                                                                                    onChange={(e) => {
                                                                                        handleChangeDataPageBanners(index, 'banner2', 'ordenacao', e.target.value);
                                                                                    }}
                                                                                    MenuProps={{
                                                                                        disablePortal: true,
                                                                                        PaperProps: {
                                                                                            style: {
                                                                                                transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        themeStorageSelected?.banner2.filter(a => a.type === 'config').map((banner, idxBanner) => {
                                                                                            return <MenuItem key={`option_order_${idxBanner}`} value={(idxBanner + 1)}>{idxBanner + 1}</MenuItem>
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        }
                                                                    </Grid>

                                                                    <Grid xs={12} style={{ paddingLeft: 25, marginTop: 10 }}>
                                                                        <FormControl>
                                                                            <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                                name="radio-buttons-group"
                                                                                direction="row"
                                                                                style={{ display: 'flex', flexDirection: 'row' }}
                                                                                onChange={(e) => {
                                                                                    handleChangeDataPageBanners(index, 'banner2', 'filtrarProdutoBy', e.target.value)
                                                                                }}
                                                                            >
                                                                                <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                                <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                                <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                                <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        )
                                                    })}
                                                </Card>
                                            }
                                        </>
                                    }

                                    {
                                        (themeStorage?.tipoThema === 3) &&
                                        <Card sx={{ width: '100%', marginTop: 2, padding: 2 }}>
                                            <Typography style={{ fontWeight: 'bold', marginBottom: 3, fontSize: 18 }}>Banner Fim</Typography>

                                            {
                                                themeStorageSelected?.banner2 && themeStorageSelected.banner2.filter(a => a.type !== 'config').map((imagem, index) => {
                                                    return (
                                                        <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                            <Box style={{ display: 'flex' }}>
                                                                <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Web Fim Loja</Typography>
                                                                    <UploadImagemDesign
                                                                        dados={imagem}
                                                                        isEditing={true}
                                                                        mobile={false}
                                                                        key={imagem.id}
                                                                        id={imagem.id}
                                                                        idComponent={`bannerFim_web_${index}`}
                                                                        dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                        dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                        imgData={imagem.url}
                                                                        onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner2')}
                                                                        borderRadiusImg={0}
                                                                        paddingTopImg={0}
                                                                        widthImg='100%'
                                                                        heightImg={100}
                                                                        style={{ height: 200 }}
                                                                        descricao={themeStorageSelected?.banner2?.length > 0 && themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                    />
                                                                </Box>
                                                                <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Mobile Fim Loja</Typography>
                                                                    <UploadImagemDesign
                                                                        dados={imagem}
                                                                        isEditing={true}
                                                                        mobile={true}
                                                                        key={imagem.id}
                                                                        id={imagem.id}
                                                                        idComponent={`bannerFim_mobile_${index}`}
                                                                        dimensaoHeightImg={heightMobile}
                                                                        dimensaoWidthImg={widthMobile}
                                                                        imgData={imagem.url_mobile}
                                                                        onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner2')}
                                                                        borderRadiusImg={0}
                                                                        paddingTopImg={0}
                                                                        widthImg='100%'
                                                                        heightImg={100}
                                                                        style={{ height: 200 }}
                                                                        descricao={dimensoesMobileStr}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Checkbox
                                                                        name='bannerTopo'
                                                                        id='bannerTopo'
                                                                        checked={themeStorageSelected?.banner2?.length > 0 ? themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                                        onChange={() => {
                                                                            const chk = themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage;
                                                                            handleCheckFullPageBanners('banner2', !chk);
                                                                        }}
                                                                    />
                                                                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>

                                                                    {
                                                                        themeStorageSelected?.banner2.filter(a => a.type === 'config').length > 1 &&
                                                                        <FormControl style={{ width: 150, marginLeft: 15 }}>
                                                                            <InputLabel id="demo-simple-select-label">Ordenação</InputLabel>
                                                                            <Select
                                                                                fullWidth
                                                                                labelId="demo-simple-select-label"
                                                                                id="select_order_banner2"
                                                                                defaultValue='1'
                                                                                value={imagem?.ordenacao || 1}
                                                                                label="Ordenação"
                                                                                onChange={(e) => {
                                                                                    handleChangeDataPageBanners(index, 'banner2', 'ordenacao', e.target.value);
                                                                                }}
                                                                                MenuProps={{
                                                                                    disablePortal: true,
                                                                                    PaperProps: {
                                                                                        style: {
                                                                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {
                                                                                    themeStorageSelected?.banner2.filter(a => a.type === 'config').map((banner, idxBanner) => {
                                                                                        return <MenuItem key={`option_order_${idxBanner}`} value={(idxBanner + 1)}>{idxBanner + 1}</MenuItem>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    }
                                                                </Grid>

                                                                <Grid xs={12} style={{ paddingLeft: 25, marginTop: 10 }}>
                                                                    <FormControl>
                                                                        <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                            name="radio-buttons-group"
                                                                            direction="row"
                                                                            style={{ display: 'flex', flexDirection: 'row' }}
                                                                            onChange={(e) => {
                                                                                handleChangeDataPageBanners(index, 'banner2', 'filtrarProdutoBy', e.target.value)
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                            <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                            <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                            <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Card>
                                    }

                                </Grid>

                                {
                                    (themeStorage.tipoThema === 7 || themeStorage.tipoThema === 13) &&
                                    <>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Timeline position="alternate">
                                                <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0', marginTop: 0 }}
                                                        align="right"
                                                        variant="body1"
                                                        color="text.secondary"
                                                    >
                                                        <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.logo?.url && themeStorageSelected?.logo?.url.length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>4.1</span>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator style={{ height: 600 }}>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary">
                                                            <ImageIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{
                                                        py: '12px',
                                                        px: 2,
                                                        marginTop: 28
                                                    }}>
                                                        <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                            Banners Sessão
                                                        </Typography>
                                                        <Typography>Informe o banner web e mobile</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Card sx={{ width: '100%', marginTop: 2, padding: 2 }}>
                                                <Typography style={{ fontWeight: 'bold', marginBottom: 3, fontSize: 18 }}>Banner Sessão</Typography>

                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs value={bannerSessaoTab} onChange={handleChangeTabsBannerSessao} aria-label="basic tabs example">
                                                        {themeStorageSelected?.bannerSessao && themeStorageSelected.bannerSessao.filter(a => a.type !== 'config').map((imagem, index) => {
                                                            return (
                                                                <Tab key={index} style={{ fontSize: '16px' }} label={`Banner Imagem ${index + 1}`} {...a11yProps(index)} />
                                                            )
                                                        })}
                                                    </Tabs>
                                                </Box>
                                                {themeStorageSelected?.bannerSessao && themeStorageSelected.bannerSessao.filter(a => a.type !== 'config').map((imagem, index) => {
                                                    return (
                                                        bannerSessaoTab === index &&
                                                        <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                            <Box style={{ display: 'flex' }}>
                                                                <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner {index + 1}</Typography>
                                                                    <UploadImagemDesign
                                                                        dados={imagem}
                                                                        isEditing={true}
                                                                        key={imagem.id}
                                                                        id={imagem.id}
                                                                        idComponent={`bannerSessao_web_${index}`}
                                                                        dimensaoHeightImg={376}
                                                                        dimensaoWidthImg={index > 0 ? 1125 : 500}
                                                                        imgData={imagem.url}
                                                                        onChange={(data) => handleOnChangeImagemCarrossel(data, 'bannerSessao')}
                                                                        borderRadiusImg={0}
                                                                        paddingTopImg={0}
                                                                        widthImg='100%'
                                                                        heightImg={100}
                                                                        style={{ height: 200 }}
                                                                        descricao={index > 0 ? 'Tamanho máximo 1125 x 376' : 'Tamanho máximo 500 x 376'}
                                                                    />
                                                                </Box>
                                                                <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                    <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Mobile {index + 1}</Typography>
                                                                    <UploadImagemDesign
                                                                        dados={imagem}
                                                                        isEditing={true}
                                                                        mobile={true}
                                                                        key={imagem.id}
                                                                        id={imagem.id}
                                                                        idComponent={`bannerSessao_mobile_${index}`}
                                                                        dimensaoHeightImg={heightMobile}
                                                                        dimensaoWidthImg={widthMobile}
                                                                        imgData={imagem.url_mobile}
                                                                        onChange={(data) => handleOnChangeImagemCarrossel(data, 'bannerSessao')}
                                                                        borderRadiusImg={0}
                                                                        paddingTopImg={0}
                                                                        widthImg='100%'
                                                                        heightImg={100}
                                                                        style={{ height: 200 }}
                                                                        descricao={dimensoesMobileStr}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Checkbox
                                                                        name='bannerSessao'
                                                                        id='bannerSesao'
                                                                        checked={themeStorageSelected?.bannerSessao?.length > 0 ? themeStorageSelected?.bannerSessao.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                                        onChange={() => {
                                                                            const chk = themeStorageSelected?.bannerSessao.filter(a => a.type === 'config')[0]?.fullPage;
                                                                            handleCheckFullPageBanners('bannerSessao', !chk);
                                                                        }}
                                                                    />
                                                                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>

                                                                    {
                                                                        themeStorageSelected?.bannerSessao.filter(a => a.type === 'config').length > 1 &&
                                                                        <FormControl style={{ width: 150, marginLeft: 15 }}>
                                                                            <InputLabel id="demo-simple-select-label">Ordenação</InputLabel>
                                                                            <Select
                                                                                fullWidth
                                                                                labelId="demo-simple-select-label"
                                                                                id="select_order_bannerSessao"
                                                                                defaultValue='1'
                                                                                value={imagem?.ordenacao || 1}
                                                                                label="Ordenação"
                                                                                onChange={(e) => {
                                                                                    handleChangeDataPageBanners(index, 'bannerCupom', 'ordenacao', e.target.value);
                                                                                }}
                                                                                MenuProps={{
                                                                                    disablePortal: true,
                                                                                    PaperProps: {
                                                                                        style: {
                                                                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {
                                                                                    themeStorageSelected?.bannerSessao.filter(a => a.type === 'config').map((banner, idxBanner) => {
                                                                                        return <MenuItem key={`option_order_${idxBanner}`} value={(idxBanner + 1)}>{idxBanner + 1}</MenuItem>
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    }

                                                                </Grid>
                                                                <Grid item xs={12} style={{ paddingLeft: 25, marginTop: 10 }}>
                                                                    <FormControl>
                                                                        <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                            name="radio-buttons-group"
                                                                            direction="row"
                                                                            style={{ display: 'flex', flexDirection: 'row' }}
                                                                            onChange={(e) => {
                                                                                handleChangeDataPageBanners(index, 'bannerSessao', 'filtrarProdutoBy', e.target.value)
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                            <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                            <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                            <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider style={{ height: 2, background: 'gray', opacity: .5, marginTop: 0 }} />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl>
                                                                        <FormLabel id="demo-radio-buttons-group-label">Página onde será exibido:</FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue={imagem.paginaExibicao || 'nenhum'}
                                                                            name="radio-buttons-group"
                                                                            direction="row"
                                                                            style={{ display: 'flex', flexDirection: 'row' }}
                                                                            onChange={(e) => {
                                                                                handleChangeDataPageBanners(index, 'bannerSessao', 'paginaExibicao', e.target.value)
                                                                            }}
                                                                        >
                                                                            <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                            <FormControlLabel value="produtos" control={<Radio />} label="Produtos" />
                                                                            <FormControlLabel value="about-us" control={<Radio />} label="Nossa História" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )
                                                })}
                                            </Card>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>5</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 350 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <WifiTetheringIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 15 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Redes Sociais
                                                </Typography>
                                                <Typography>Colar o link de compartilhamento da rede social </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {themeStorageSelected?.footer?.redes_socias?.map((item) => {
                                        return getIconSocialMedia(item.id);
                                    })}
                                </Grid>

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.footer?.formas_pagamento?.filter(a => a.selected).length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>6</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 150 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <PaidIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 4 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Formas de pagamento
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 700 }}>
                                    {themeStorageSelected?.footer?.formas_pagamento?.map((item) => {
                                        return getIconsPaymentsTypes(item);
                                    })}
                                </Grid>

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: themeStorageSelected?.faleConosco?.cep?.length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>7</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 250 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <BusinessIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 10 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Endereço e contato da loja
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 700 }}>
                                    <Grid container style={{ display: 'flex', width: '100%' }}>
                                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Endereço</Typography>
                                        </Grid>
                                        <Grid item xs={2.2}>
                                            <TextField
                                                variant="outlined"
                                                type='phone'
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '90%' }}
                                                label='CEP'
                                                value={themeStorageSelected?.faleConosco?.cep}
                                                onChange={(e) => {
                                                    const cep = e.target.value.replace(/([^\d])+/gim, '');
                                                    handleChangeEndereco('cep', cep)
                                                    handleCep(e);
                                                }}
                                                inputProps={{ maxLength: 8 }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '90%' }}
                                                label='Endereço'
                                                value={themeStorageSelected?.faleConosco?.endereco}
                                                onChange={(e) => handleChangeEndereco('endereco', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '90%' }}
                                                label='Bairro'
                                                value={themeStorageSelected?.faleConosco?.bairro}
                                                onChange={(e) => handleChangeEndereco('bairro', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1.8}>
                                            <TextField
                                                variant="outlined"
                                                type='phone'
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '90%' }}
                                                label='Número'
                                                value={themeStorageSelected?.faleConosco?.numero}
                                                onChange={(e) => {
                                                    const cep = e.target.value.replace(/([^\d])+/gim, '');
                                                    handleChangeEndereco('numero', cep)
                                                }}
                                                inputProps={{ maxLength: 8 }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} style={{ marginTop: 10 }}>
                                            <TextField
                                                variant="outlined"
                                                type='text'
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '96%' }}
                                                label='Cidade'
                                                value={themeStorageSelected?.faleConosco?.cidade}
                                                onChange={(e) => {
                                                    handleChangeEndereco('cidade', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} style={{ marginTop: 10 }}>
                                            <TextField
                                                variant="outlined"
                                                type='string'
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '90%' }}
                                                label='UF'
                                                value={themeStorageSelected?.faleConosco?.uf}
                                                onChange={(e) => {
                                                    handleChangeEndereco('uf', e.target.value)
                                                }}
                                                inputProps={{ maxLength: 2 }}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5} style={{ marginTop: 10 }}>
                                            <TextField
                                                variant="outlined"
                                                type='string'
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '96%', background: 'gray' }}
                                                className={'input-cinza'}
                                                label='Latitude'
                                                value={themeStorageSelected?.faleConosco?.latitude}
                                                onChange={(e) => {
                                                    handleChangeEndereco('latitude', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5} style={{ marginTop: 10 }}>
                                            <TextField
                                                variant="outlined"
                                                type='string'
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                                InputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '96%', background: 'gray' }}
                                                className={'input-cinza'}
                                                label='Longitude'
                                                value={themeStorageSelected?.faleConosco?.longitude}
                                                onChange={(e) => {
                                                    handleChangeEndereco('longitude', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 25, marginBottom: 15 }}>
                                            <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Contato</Typography>
                                        </Grid>
                                        <Grid container style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    variant="outlined"
                                                    type='text'
                                                    InputLabelProps={{ style: { fontSize: 16 } }}
                                                    inputProps={{ style: { fontSize: 16 }, maxLength: 11 }}
                                                    style={{ width: '97%' }}
                                                    label='WhatsApp'
                                                    value={themeStorageSelected?.faleConosco?.contatoWhatsApp}
                                                    onChange={(e) => handleChangeEndereco('contatoWhatsApp', e.target.value.replace(/([^\d])+/gim, ''))}
                                                />
                                            </Grid>
                                            <Grid item xs={3} style={{ paddingRight: 6 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Cor botão Whatsapp</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="corBotaoWhastapp"
                                                        defaultValue='32f269'
                                                        value={themeStorageSelected?.faleConosco?.corBotaoWhatsapp}
                                                        label="Cor botão Whatsapp"
                                                        onChange={(e) => {
                                                            handleChangeEndereco('corBotaoWhatsapp', e.target.value);
                                                        }}
                                                        MenuProps={{
                                                            disablePortal: true,
                                                            PaperProps: {
                                                                style: {
                                                                    transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={'#32f269'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#32f269', marginRight: 10 }}></span> Verde</MenuItem>
                                                        <MenuItem value={'#000000'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#000000', marginRight: 10 }}></span> Preto</MenuItem>
                                                        <MenuItem value={'#f1416c'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#f1416c', marginRight: 10 }}></span> Rosa</MenuItem>
                                                        <MenuItem value={'#fe9e40'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#fe9e40', marginRight: 10 }}></span> Laranja</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 16 } }}
                                                    InputProps={{ style: { fontSize: 16 } }}
                                                    style={{ width: '97%' }}
                                                    label='E-mail'
                                                    value={themeStorageSelected?.faleConosco?.contatoEmail}
                                                    onChange={(e) => handleChangeEndereco('contatoEmail', e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 30, background: '#f1416d', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>8</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 320 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <AccessTimeFilledIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 16 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Horário de atendimento
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>
                                        <Grid item xs={12} style={{ textAlign: 'left', marginBottom: -20 }}>
                                            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Horários de atendimento</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<ButtonGroup variant="outlined" aria-label="outlined button group" style={{ paddingTop: 10 }}>
                                                    <Button variant={empresa?.FlgAbreSeg ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreSeg`, !empresa?.FlgAbreSeg)}>Seg</Button>
                                                    <Button variant={empresa?.FlgAbreTer ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreTer`, !empresa?.FlgAbreTer)}>Ter</Button>
                                                    <Button variant={empresa?.FlgAbreQua ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreQua`, !empresa?.FlgAbreQua)}>Qua</Button>
                                                    <Button variant={empresa?.FlgAbreQui ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreQui`, !empresa?.FlgAbreQui)}>Qui</Button>
                                                    <Button variant={empresa?.FlgAbreSex ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreSex`, !empresa?.FlgAbreSex)}>Sex</Button>
                                                    <Button variant={empresa?.FlgAbreSab ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreSab`, !empresa?.FlgAbreSab)}>Sáb</Button>
                                                    <Button variant={empresa?.FlgAbreDom ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreDom`, !empresa?.FlgAbreDom)}>Dom</Button>
                                                </ButtonGroup>}
                                                label="Dias da semana"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraInicioSemana`, valor);
                                                        }}
                                                        value={empresa?.HoraInicioSemana}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Início'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>

                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraFimSemana`, valor);
                                                        }}
                                                        value={empresa?.HoraFimSemana}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Fim'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>

                                                </Box>}
                                                label="Horários semana"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraInicioSabado`, valor);
                                                        }}
                                                        value={empresa?.HoraInicioSabado}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Início'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>

                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraFimSabado`, valor);
                                                        }}
                                                        value={empresa?.HoraFimSabado}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Fim'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>
                                                </Box>}
                                                label="Horários sábado"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraInicioDomingo`, valor);
                                                        }}
                                                        value={empresa?.HoraInicioDomingo}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Início'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>
                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraFimDomingo`, valor);
                                                        }}
                                                        value={empresa?.HoraFimDomingo}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Fim'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>
                                                </Box>}
                                                label="Horários Domingo"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraInicioFeriado`, valor);
                                                        }}
                                                        value={empresa?.HoraInicioFeriado}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Início'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>
                                                    <InputMask
                                                        mask="99:99" // Máscara para horário
                                                        disabled={!marcouDiaSemana}
                                                        maskChar={null}
                                                        alwaysShowMask={false}
                                                        onChange={(e) => {
                                                            if (!marcouDiaSemana) return;

                                                            const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                            handleChangeEmpresa(`HoraFimFeriado`, valor);
                                                        }}
                                                        value={empresa?.HoraFimFeriado}
                                                        style={{ width: '50%' }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                inputProps={{
                                                                    maxLength: 5
                                                                }}
                                                                placeholder="HH:MM"

                                                                label={'Fim'}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    </InputMask>
                                                </Box>}
                                                label="Horários Feriado"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {
                            (themeStorage?.tipoThema === 1 || themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4 || themeStorage?.tipoThema === 7 || themeStorage?.tipoThema === 10 || themeStorage?.tipoThema === 13) &&
                            <>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 32, background: themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>9</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: themeStorage?.tipoThema === 10 ? 850 : 400 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <StarIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: themeStorage?.tipoThema === 10 ? 45 : 19 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Categorias de destaque
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <ExibicaoItens />
                                </Grid>
                            </>
                        }

                        {
                            (themeStorage?.tipoThema === 7) &&
                            <>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 32, background: themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>9.1</span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 400 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <StarIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 19 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Categorias de menu
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <ExibicaoItensMenu />
                                </Grid>
                            </>
                        }


                        {
                            themeStorage && ((superUser != null && themeStorage?.tipoThema === 1) || themeStorage?.tipoThema > 1) &&
                            <>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <Timeline position="alternate">
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0', marginTop: 0 }}
                                                align="right"
                                                variant="body1"
                                                color="text.secondary"
                                            >
                                                <span style={{ fontSize: 20, borderRadius: 32, background: themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>
                                                    {(themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4 || themeStorage?.tipoThema === 7 || themeStorage?.tipoThema === 7) ? '10' : '9'}
                                                </span>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator style={{ height: 400 }}>
                                                <TimelineConnector />
                                                <TimelineDot color="primary">
                                                    <StarIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, marginTop: 20 }}>
                                                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                    Marketing (Cabeçalho)
                                                </Typography>
                                                <Typography>Informe a mensagem e caso tenha o link da propaganda</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <MensagemMarketing />
                                </Grid>

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                {
                                    themeStorage?.tipoThema !== 7 &&
                                    <>
                                        <Grid item xs={3}>
                                            <Timeline position="alternate">
                                                <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0', marginTop: 0 }}
                                                        align="right"
                                                        variant="body1"
                                                        color="text.secondary"
                                                    >
                                                        <span style={{ fontSize: 20, borderRadius: 32, background: themeStorageSelected?.aboutUs?.imagem1.url !== null ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>
                                                            {(themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4 || themeStorage?.tipoThema === 7 || themeStorage?.tipoThema === 10) ? '11' : '10'}
                                                        </span>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator style={{ height: themeStorageSelected?.aboutUs?.imagem1.url != null ? themeStorageSelected?.aboutUs?.imagem2.url !== null ? 1300 : 1100 : 900 }}>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary">
                                                            <StarIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '12px', px: 2, marginTop: themeStorageSelected?.aboutUs?.imagem1.url != null ? themeStorageSelected?.aboutUs?.imagem2.url !== null ? 74 : 65 : 45 }}>
                                                        <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                            História da Loja
                                                        </Typography>
                                                        <Typography>Informe a imsgem, vídeo e texto da história da loja</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        </Grid>
                                        <Grid item xs={9} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        title={'Título'}
                                                        style={{ margin: 10, marginLeft: -2 }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'black',
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                color: 'black',
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        value={themeStorageSelected?.aboutUs?.titulo1}
                                                        onChange={(e) => {
                                                            handleChangeAboutUs('titulo1', e.target.value);
                                                        }}
                                                        id={`historia-loja-titulo-1`}
                                                        label='Título'
                                                    />
                                                    <Editor
                                                        toolbarId='historia-loja-sobre-a-marca'
                                                        className='ql-editor2'
                                                        text={themeStorageSelected?.aboutUs?.textoExplicativo}
                                                        handleChange={(valor) => handleChangeAboutUs('textoExplicativo', valor)}
                                                    />
                                                </Grid>

                                                {
                                                    (themeStorage?.tipoThema !== 8 && themeStorage?.tipoThema !== 9 && themeStorage?.tipoThema !== 10) &&
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            title={'Título'}
                                                            style={{ margin: 10, marginLeft: -2 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected?.aboutUs?.titulo2}
                                                            onChange={(e) => {
                                                                handleChangeAboutUs('titulo2', e.target.value);
                                                            }}
                                                            id={`historia-loja-titulo-2`}
                                                            label='Título'
                                                        />
                                                        <Editor
                                                            toolbarId='historia-loja-as-criadoras'
                                                            className='ql-editor2'
                                                            text={themeStorageSelected?.aboutUs?.textoExplicativo2}
                                                            handleChange={(valor) => handleChangeAboutUs('textoExplicativo2', valor)}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        title={'Link do vídeo'}
                                                        style={{ marginTop: 2, marginLeft: -2 }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'black',
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                color: 'black',
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        value={themeStorageSelected?.aboutUs?.linkVideo}
                                                        onChange={(e) => {
                                                            handleChangeAboutUs('linkVideo', e.target.value)
                                                        }}
                                                        id="outlined-basic"
                                                        label='Digite o link do vídeo'
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Box style={{ marginTop: 10, marginBottom: 15 }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Cor do card</Typography>
                                                        <SketchPicker
                                                            color={themeStorageSelected?.aboutUs?.cor1 || '#fffff'}
                                                            onChangeComplete={(color) => {
                                                                handleChangeAboutUs('cor1', color.hex);
                                                            }}
                                                        />
                                                    </Box>
                                                    <UploadImagemDesign
                                                        dados={themeStorageSelected?.aboutUs?.imagem1}
                                                        isEditing={true}
                                                        mobile={false}
                                                        key={themeStorageSelected?.aboutUs?.imagem1.id}
                                                        id={themeStorageSelected?.aboutUs?.imagem1.id}
                                                        idComponent={`aboutUs1`}
                                                        dimensaoWidthImg={740}
                                                        dimensaoHeightImg={430}
                                                        imgData={themeStorageSelected?.aboutUs?.imagem1.url}
                                                        onChange={(value) => handleChangeAboutUs('imagem1', value)}
                                                        borderRadiusImg={0}
                                                        paddingTopImg={0}
                                                        widthImg='100%'
                                                        heightImg={250}
                                                        style={{ height: 'auto' }}
                                                        descricao={'Tamanho máximo 740 x 430'}
                                                    />
                                                </Grid>

                                                {
                                                    (themeStorage?.tipoThema !== 8 && themeStorage?.tipoThema !== 9 && themeStorage?.tipoThema !== 10) &&
                                                    <Grid item xs={12} md={6}>
                                                        <Box style={{ marginTop: 10, marginBottom: 15 }}>
                                                            <Typography style={{ fontWeight: 'bold' }}>Cor do card</Typography>
                                                            <SketchPicker
                                                                color={themeStorageSelected?.aboutUs?.cor2 || '#fffff'}
                                                                onChangeComplete={(color) => {
                                                                    handleChangeAboutUs('cor2', color.hex);
                                                                }}
                                                            />
                                                        </Box>
                                                        <UploadImagemDesign
                                                            dados={themeStorageSelected?.aboutUs?.imagem2}
                                                            isEditing={true}
                                                            mobile={false}
                                                            key={themeStorageSelected?.aboutUs?.imagem2.id}
                                                            id={themeStorageSelected?.aboutUs?.imagem2.id}
                                                            idComponent={`aboutUs2`}
                                                            dimensaoWidthImg={740}
                                                            dimensaoHeightImg={430}
                                                            imgData={themeStorageSelected?.aboutUs?.imagem2.url}
                                                            onChange={(value) => handleChangeAboutUs('imagem2', value)}
                                                            borderRadiusImg={0}
                                                            paddingTopImg={0}
                                                            widthImg='100%'
                                                            heightImg={250}
                                                            style={{ height: 'auto' }}
                                                            descricao={'Tamanho máximo 740 x 430'}
                                                        />
                                                    </Grid>
                                                }

                                            </Grid>

                                        </Grid>
                                    </>
                                }
                                {
                                    themeStorage?.tipoThema === 7 &&
                                    <>
                                        <Grid item xs={3}>
                                            <Timeline position="alternate">
                                                <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0', marginTop: 0 }}
                                                        align="right"
                                                        variant="body1"
                                                        color="text.secondary"
                                                    >
                                                        <span style={{ fontSize: 20, borderRadius: 32, background: themeStorageSelected?.aboutUs?.imagem1.url !== null ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>
                                                            11
                                                        </span>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator style={{ height: 750 }}>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary">
                                                            <StarIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '12px', px: 2, marginTop: 32 }}>
                                                        <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                            História da Loja
                                                        </Typography>
                                                        <Typography>Informe a imsgem, vídeo e texto da história da loja</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        </Grid>
                                        <Grid item xs={9} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        title={'Título'}
                                                        style={{ margin: 10, marginLeft: -2 }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'black',
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                color: 'black',
                                                                fontSize: '14px'
                                                            }
                                                        }}
                                                        value={themeStorageSelected?.aboutUs?.titulo1}
                                                        onChange={(e) => {
                                                            handleChangeAboutUs('titulo1', e.target.value);
                                                        }}
                                                        id={`historia-loja-titulo-1`}
                                                        label='Título'
                                                    />
                                                    <Editor
                                                        toolbarId='historia-loja-sobre-a-marca'
                                                        className='ql-editor2'
                                                        text={themeStorageSelected?.aboutUs?.textoExplicativo}
                                                        handleChange={(valor) => handleChangeAboutUs('textoExplicativo', valor)}
                                                    />
                                                    <Box style={{ marginTop: 10, marginBottom: 15 }}>
                                                        <Typography style={{ fontWeight: 'bold' }}>Cor do card</Typography>
                                                        <SketchPicker
                                                            color={themeStorageSelected?.aboutUs?.cor1 || '#fffff'}
                                                            onChangeComplete={(color) => {
                                                                handleChangeAboutUs('cor1', color.hex);
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <UploadImagemDesign
                                                        dados={themeStorageSelected?.aboutUs?.imagem1}
                                                        isEditing={true}
                                                        mobile={false}
                                                        key={themeStorageSelected?.aboutUs?.imagem1.id}
                                                        id={themeStorageSelected?.aboutUs?.imagem1.id}
                                                        idComponent={`aboutUs1`}
                                                        dimensaoWidthImg={930}
                                                        dimensaoHeightImg={430}
                                                        imgData={themeStorageSelected?.aboutUs?.imagem1.url}
                                                        onChange={(value) => handleChangeAboutUs('imagem1', value)}
                                                        borderRadiusImg={0}
                                                        paddingTopImg={0}
                                                        widthImg='100%'
                                                        heightImg={250}
                                                        style={{ height: 'auto' }}
                                                        descricao={'Tamanho máximo 930 x 430'}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </>

                                }

                                {
                                    themeStorage?.tipoThema === 3 &&
                                    <>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Timeline position="alternate">
                                                <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0', marginTop: 0 }}
                                                        align="right"
                                                        variant="body1"
                                                        color="text.secondary"
                                                    >
                                                        <span style={{ fontSize: 20, borderRadius: 32, background: themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length > 0 ? '#f1416d' : 'gray', padding: 7, paddingLeft: 12, paddingRight: 12, color: 'white' }}>11</span>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator style={{ height: 1150 }}>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary">
                                                            <StarIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '12px', px: 2, marginTop: 55 }}>
                                                        <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                                                            Produtos Favoritos
                                                        </Typography>
                                                        <Typography>Informe os dados de exibição dos produtos favoritos</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Grid container spacing={2}>
                                                {
                                                    themeStorageSelected?.cardsProdutoFavorito.map((prodFavorito, index) => {
                                                        return <Grid item xs={6}>
                                                            <Typography style={{ fontWeight: 'bold' }}>Produto favorito {index + 1}</Typography>
                                                            <TextField
                                                                fullWidth
                                                                key={`titulo-${prodFavorito.id}`}
                                                                title={'Título'}
                                                                style={{ margin: 10, marginLeft: -2 }}
                                                                InputLabelProps={{
                                                                    style: {
                                                                        color: 'black',
                                                                        fontSize: '14px'
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    style: {
                                                                        color: 'black',
                                                                        fontSize: '14px'
                                                                    }
                                                                }}
                                                                value={prodFavorito?.titulo}
                                                                onChange={(e) => {
                                                                    handleChangeCardProdutoFavorito(prodFavorito.id, 'titulo', e.target.value);
                                                                }}
                                                                id={`titulo-${prodFavorito.id}`}
                                                                label='Título'
                                                            />
                                                            <Editor
                                                                toolbarId={`produto-favorito-texto-${prodFavorito.id}`}
                                                                className='ql-editor2'
                                                                text={prodFavorito?.texto}
                                                                handleChange={(valor) => handleChangeCardProdutoFavorito(prodFavorito.id, 'texto', valor)}
                                                            />

                                                            <Box style={{ marginTop: 10 }}>
                                                                <Typography style={{ fontWeight: 'bold' }}>Cor do card do produto</Typography>
                                                                <SketchPicker
                                                                    color={prodFavorito?.cor}
                                                                    onChangeComplete={(color) => {
                                                                        handleChangeCardProdutoFavorito(prodFavorito?.id, 'cor', color.hex);
                                                                    }}
                                                                />
                                                            </Box>

                                                            <UploadImagemDesign
                                                                dados={prodFavorito?.imagem}
                                                                isEditing={true}
                                                                mobile={false}
                                                                key={prodFavorito?.imagem?.id}
                                                                id={prodFavorito?.imagem?.id}
                                                                idComponent={prodFavorito?.imagem?.id}
                                                                dimensaoWidthImg={400}
                                                                dimensaoHeightImg={280}
                                                                imgData={prodFavorito?.imagem?.url}
                                                                onChange={(value) => handleChangeCardProdutoFavorito(prodFavorito?.id, 'imagem', value)}
                                                                borderRadiusImg={0}
                                                                paddingTopImg={0}
                                                                widthImg='100%'
                                                                heightImg={250}
                                                                style={{ height: 'auto', marginTop: 15 }}
                                                                descricao={'Tamanho máximo 400 x 280'}
                                                            />
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider style={{ width: '90%', height: '1.8px', background: '#d4d4d4' }} />
                                </Grid>
                                <Box style={{ width: "100%", marginTop: 15, display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                                    <Button style={{ background: 'black', height: 40, width: 220, marginLeft: 30 }} onClick={() => setMostrarConfigsAvancadas(!mostrarConfigsAvancadas)} disabled={themeStorage?.tipoThema === 0 || loading} variant='contained'>
                                        Configs Avançadas
                                    </Button>
                                    {
                                        <Button disabled={themeStorage?.tipoThema === 0 || loading} variant='contained' onClick={() => handlePublish(false)}>
                                            Publicar (Salvar)
                                        </Button>
                                    }
                                </Box>

                                {
                                    mostrarConfigsAvancadas &&
                                    <Box style={{ marginTop: 20, marginLeft: 30, width: '100%', padding: 50, paddingTop: 0, paddingRight: 10, opacity: mostrarConfigsAvancadas ? 1 : 0, transition: 'opacity 1s ease-in-out' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={tabConfigAvancada} onChange={handleChange} aria-label="basic tabs example">
                                                <Tab label="Políticas" {...a11yProps(0)} />
                                                <Tab label="Imagens" {...a11yProps(1)} />
                                                <Tab label="Marketing SEO" {...a11yProps(2)} />
                                                <Tab label="Google Analitics" {...a11yProps(3)} />
                                            </Tabs>
                                        </Box>

                                        {
                                            tabConfigAvancada == 0 &&
                                            <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                {
                                                    themeStorageSelected?.politicas?.filter(a => a.id == "3" || a.id == "4" || a.id == "5").map((item, index) => {
                                                        return <Grid item xs={12} key={index} style={{ height: '100%', marginTop: index === 1 ? 20 : 0 }}>
                                                            <Typography style={{ fontWeight: 'bold', marginBottom: 2, marginTop: 10 }}>{item.titulo}</Typography>
                                                            {
                                                                index === 0 ? <Editor
                                                                    className='ql-editor2'
                                                                    toolbarId={`texto-politica-${index}`}
                                                                    text={item.texto}
                                                                    handleChange={(valor) => handleChangepoliticas(item.id, 'texto', valor)}
                                                                /> : <Editor
                                                                    style={{ marginTop: 10 }}
                                                                    toolbarId={`texto-politica-${index}`}
                                                                    className='ql-editor2'
                                                                    text={item.texto}
                                                                    handleChange={(valor) => handleChangepoliticas(item.id, 'texto', valor)}
                                                                />
                                                            }
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        }
                                        <CustomTabPanel value={tabConfigAvancada} index={1}>
                                            <Box style={{ marginTop: 10 }}>
                                                <FormControl>
                                                    <FormLabel id="demo-radio-buttons-group-label">Altura imagem</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue={themeStorageSelected.imagemProduto == null ? "auto" : themeStorageSelected.imagemProduto?.alturaContainerImagem}
                                                        name="radio-buttons-group"
                                                        onChange={(e) => {
                                                            const imagemProduto = { ...themeStorageSelected.imagemProduto, alturaContainerImagem: e.target.value, direcaoPosicaoDescricaoProduto: themeStorageSelected?.imagemProduto?.direcaoPosicaoDescricaoProduto ? themeStorageSelected.imagemProduto.direcaoPosicaoDescricaoProduto : 'rodape', comprimentoContainerImagem: "" };
                                                            dispatch({
                                                                type: TEMA_LOJA_SET_IMAGEM_PRODUTO,
                                                                themeStorageId: themeStorage.idThemeStoreSelected,
                                                                imagemProduto: imagemProduto
                                                            });
                                                        }}
                                                    >
                                                        <FormControlLabel value="auto" control={<Radio />} label="Automático" />
                                                        <FormControlLabel value="310" control={<Radio />} label="310px de altura" />
                                                        <FormControlLabel value="400" control={<Radio />} label="400px de altura" />
                                                    </RadioGroup>
                                                </FormControl>

                                                {
                                                    themeStorage?.tipoThema === 5 &&
                                                    <FormControl>
                                                        <FormLabel id="demo-radio-buttons-group-label">Posição descrição do produto</FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue={themeStorageSelected.imagemProduto == null ? "rodape" : themeStorageSelected.imagemProduto?.direcaoPosicaoDescricaoProduto}
                                                            name="radio-buttons-group"
                                                            onChange={(e) => {
                                                                const imagemProduto = { ...themeStorageSelected.imagemProduto, direcaoPosicaoDescricaoProduto: e.target.value, alturaContainerImagem: themeStorageSelected?.imagemProduto?.alturaContainerImagem ? themeStorageSelected.imagemProduto.alturaContainerImagem : 'auto', comprimentoContainerImagem: "" };
                                                                dispatch({
                                                                    type: TEMA_LOJA_SET_IMAGEM_PRODUTO,
                                                                    themeStorageId: themeStorage.idThemeStoreSelected,
                                                                    imagemProduto: imagemProduto
                                                                });
                                                            }}
                                                        >
                                                            <FormControlLabel value="centro" control={<Radio />} label="Centro" />
                                                            <FormControlLabel value="rodape" control={<Radio />} label="Rodapé" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                }

                                            </Box>
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabConfigAvancada} index={2}>
                                            <Box style={{ marginTop: 10 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            key="nome-seo"
                                                            title={'Nome da loja'}
                                                            style={{ margin: 20, marginLeft: -2 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected?.marketingSEO?.name || ''}
                                                            onChange={(e) => {
                                                                handleChangeMarketingSEO('name', e.target.value)
                                                                setTimeout(() => {
                                                                    document.getElementById("nome-seo").focus();
                                                                }, 100);
                                                            }}
                                                            inputProps={{ maxLength: 75 }}
                                                            id="nome-seo"
                                                            label='Digite o nome da loja'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            key="url-seo"
                                                            title={'URL da loja'}
                                                            style={{ margin: 20, marginLeft: -2 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected?.marketingSEO?.url}
                                                            onChange={(e) => {
                                                                handleChangeMarketingSEO('url', e.target.value)
                                                                setTimeout(() => {
                                                                    document.getElementById("url-seo").focus();
                                                                }, 100);
                                                            }}
                                                            inputProps={{ maxLength: 75 }}
                                                            id="url-seo"
                                                            label='Digite a URL da loja'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            key="titulo-seo"
                                                            title={'Título'}
                                                            style={{ margin: 20, marginLeft: -2 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected?.marketingSEO?.title}
                                                            onChange={(e) => {
                                                                handleChangeMarketingSEO('title', e.target.value);
                                                                setTimeout(() => {
                                                                    document.getElementById("titulo-seo").focus();
                                                                }, 100);
                                                            }}
                                                            inputProps={{ maxLength: 75 }}
                                                            id="titulo-seo"
                                                            label='Digite o Título'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField fullWidth
                                                            key="descricao-seo"
                                                            title={'Descrição'}
                                                            placeholder="Insira a descrição da sua loja"
                                                            style={{ margin: 20, marginLeft: -2 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            inputProps={{ maxLength: 175 }}
                                                            value={themeStorageSelected?.marketingSEO?.description}
                                                            onChange={(e) => {
                                                                handleChangeMarketingSEO('description', e.target.value);
                                                                setTimeout(() => {
                                                                    document.getElementById("descricao-seo").focus();
                                                                }, 100);
                                                            }}
                                                            id="descricao-seo"
                                                            label='Digite a Descrição'
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <UploadImagemDesign
                                                            dados={themeStorageSelected?.marketingSEO?.favicon}
                                                            isEditing={true}
                                                            key={'favicon'}
                                                            id={'favicon'}
                                                            idComponent={'favicon'}
                                                            imgData={themeStorageSelected?.marketingSEO?.favicon?.url}
                                                            onChange={(valor) => handleChangeMarketingSEO('favicon', valor)}
                                                            borderRadiusImg={0}
                                                            paddingTopImg={0}
                                                            widthImg='100%'
                                                            heightImg={140}
                                                            style={{ width: 150, height: 220 }}
                                                            descricao="200x200"
                                                            dimensaoHeightImg={200}
                                                            dimensaoWidthImg={200}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </CustomTabPanel>

                                        {
                                            tabConfigAvancada == 3 &&
                                            <Box style={{ marginTop: 10 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            minRows={4}
                                                            title={'Head'}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected.googleAnalytics.head || ''}
                                                            onChange={(e) => {
                                                                handleChangeGoogleAnalitics('head', e.target.value);
                                                            }}
                                                            placeholder="Cole o código head vindo do gerenciado de tags do Google"
                                                            label='Head'
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            minRows={4}
                                                            title={'Body'}
                                                            style={{ marginTop: 10 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected.googleAnalytics.body || ''}
                                                            onChange={(e) => {
                                                                handleChangeGoogleAnalitics('body', e.target.value);
                                                            }}
                                                            placeholder="Cole o código body vindo do gerenciado de tags do Google"
                                                            label='Body'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            minRows={4}
                                                            title={'Pós head'}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected.googleAnalytics.afterHead || ''}
                                                            onChange={(e) => {
                                                                handleChangeGoogleAnalitics('afterHead', e.target.value);
                                                            }}
                                                            placeholder="Cole o código pós head vindo do gerenciado de tags do Google"
                                                            label='Pós head'
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            title={'Meta'}
                                                            style={{ marginTop: 10 }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold'
                                                                }
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'black',
                                                                    fontSize: '14px'
                                                                }
                                                            }}
                                                            value={themeStorageSelected.googleAnalytics.meta || ''}
                                                            onChange={(e) => {
                                                                handleChangeGoogleAnalitics('meta', e.target.value);
                                                            }}
                                                            placeholder="Cole o código meta vindo do gerenciado de tags do Google"
                                                            label='Meta'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </>
                        }

                    </Grid>
                </Container >
            );
    }
    catch (err) {
        SalvarLogSentry(err, 'Erro ao executar tela de design da loja');

        navigate('/page-error', { state: { message: '1759 - Oi, houve um erro na operação que você solicitou. Tente novamente ou fale com o suporte abaixo.' } });
    }
}

export default ConfigurarEcommerce;
