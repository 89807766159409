import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, Box, DialogContentText, Typography } from '@mui/material';


const createPromise = () => {
  let resolver;
  return [new Promise((resolve, reject) => {

    resolver = resolve
  }), resolver]
}

const useDialogConfirmationAsync = () => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState({ resolver: null });

  const getConfirmation = async (text) => {
    setOpen(true);
    const [promise, resolve] = await createPromise();
    setResolver({ resolve });

    return promise;
  }

  const onClose = async (status) => {
    setOpen(false);
    resolver.resolve(status)
  }

  const Confirmation = ({
    title = 'Titulo',
    mensagem = null,
    subMensagem = null,
    btn1 = { title: 'Sim', onClick: () => { } },
    btn2 = { title: 'Não', onClick: () => { } },
    btn3 = null,
    children
  }
  ) => {

    const handleClickCancel = () => {
      btn1.onClick();
      onClose();
    }

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 250, minHeight: 250 }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContant: 'flex-end', height: 'auto', minHeight: subMensagem ? 250 : 210, width: 600 }}>
          <DialogTitle id="alert-dialog-title" style={{ textAlign: 'start' }}>
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {mensagem}
            </DialogContentText>
            {
              subMensagem !== null && <Typography style={{ fontSize: 11, marginTop: 30 }}>{subMensagem}</Typography>
            }

            {
              children
            }

          </DialogContent>
          <DialogActions>
            <Button variant={btn3 !== null ? 'contained' : null} style={{ fontSize: 13 }} onClick={() => { btn1.onClick !== null ? handleClickCancel() : onClose(); }}>{btn1.title}</Button>
            <Button variant='contained' style={{ fontSize: 13, background: 'black' }} onClick={() => { btn2.onClick(); onClose(); }} autoFocus>{btn2.title}</Button>
            {
              btn3 !== null &&
              <Button variant='contained' style={{ fontSize: 13, background: 'black' }} onClick={() => { btn3.onClick(); onClose(); }} autoFocus>{btn3.title}</Button>
            }
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  return [getConfirmation, Confirmation];

}

export default useDialogConfirmationAsync;
