import { Alert, AlertTitle, Avatar, Box, Button, CircularProgress, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import * as Styled from './styles.js';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { POST_DATA, SalvarLogSentry } from '../../services/API';
import StatusSteppers from './statusSteppers.jsx';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import * as moment from 'moment';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { CONTROLEMENSAGEM_SUCESSO } from '../../store/ControleMensagem/types.js';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO } from '../../store/ControleMensagemReducer/types.js';
import useDialogConfirmationAsync from '../../components/DialogConfirmation/DialogConfirmationAsync.jsx';

const DetalhesPedido = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [getConfirmationCancelamento, ConfirmationCancelamento] = useDialogConfirmationAsync();
    const [getConfirmationRecebimentoEntrega, ConfirmationRecebimentoEntrega] = useDialogConfirmationAsync();
    const [getConfirmationAlterarStatus, ConfirmationAlterarStatus] = useDialogConfirmationAsync();

    const [idStatus, setIdStatus] = useState(3);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [idPedido, setIdPedido] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [historicoStatusPedido, setHistoricoStatusPedido] = useState(null);
    const [pedidoDetalhe, setPedidoDetalhe] = useState(null);
    const [erroLoadingPedido, setErroLoadingPedido] = useState(null);
    const [valorProdutos, setValorPordutos] = useState(0);

    const [idIntegracaoPagamento, setIdIntegracaoPagamento] = useState(0);

    useEffect(() => {
        (async () => {
            if (location !== null) {
                setIdPedido(location.state.idPedido);
                if (location.state?.statusId)
                    setStatusId(location.state?.statusId);

                await getDetalhesPedido(location.state.idPedido);
                await getHistoricoStatusPedido(location.state.idPedido);
                const integracoes = await POST_DATA(`IntegracaoParametros/GetAllIntegracaoParametrosByIdIntegracaoTipoAndIdUsuario?IdIntegracaoTipo=3&IdUsuario=${userLoggedIn.IdUsuario}`);

                if (integracoes.length > 0) {
                    setIdIntegracaoPagamento(integracoes[0]?.IdIntegracao);
                }
            }
        })();
    }, [location]);

    const getDetalhesPedido = async (idPedido) => {
        try {
            const result = await POST_DATA(`Pedido/GetPedidoByIdPedido?idPedido=${idPedido}`);
            const valor = result.objetoRetorno?._PedidoItens.reduce((total, item) => { return total += (item._Etiqueta.mValor - (item._Etiqueta.mValor * (item._Etiqueta.mPorcentoDesconto / 100))) }, 0)
            setValorPordutos(valor);

            setPedidoDetalhe(result.objetoRetorno);
        }
        catch (err) {
            SalvarLogSentry(err);
            setErroLoadingPedido({
                name: err.name,
                message: err.message,
                status: err.response.status,
            });
        }
    };

    const getHistoricoStatusPedido = async (idPedido) => {
        const result = await POST_DATA(`Pedido/GeListPedidoStatusHistorico?idPedido=${idPedido}`);
        const historico = result.objetoRetorno.filter((item, index, array) =>
            index === array.findIndex(obj => obj._PedidoStatus.IdStatus === item._PedidoStatus.IdStatus) // Mantém apenas o primeiro elemento com o mesmo nome
        ).sort((a, b) => new Date(a.DataCadastro) - new Date(b.DataCadastro));

        setHistoricoStatusPedido(historico);
    }

    const getQuantidadeProdutos = () => {
        let quantidadeTotal = 0;
        pedidoDetalhe._PedidoItens.forEach((item) => quantidadeTotal += item.iQuantidade);
        return quantidadeTotal;
    }

    const getValorProdutos = () => {
        let totalProdutos = 0;
        pedidoDetalhe._PedidoItens.forEach((item) => totalProdutos += item.iQuantidade * item._Etiqueta.mValor);
        return totalProdutos?.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    }

    const handleClickMenuAcoes = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCancelarPedidoByVendedor = () => {
        handleClose();
        getConfirmationCancelamento();
    }

    const handleAlterarStatusPedidoEntregue = () => {
        handleClose();
        getConfirmationRecebimentoEntrega();
    }

    const handleDeclaracaoConteudo = async (idPedido) => {
        handleClose();

        try {
            const result = await POST_DATA(`MelhorEnvio/PostGeracaoEtiqueta?idPedido=${idPedido}`);
            if (result.statusRetorno == "500") {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: result.mensagem,
                });
            }
            else if (result.statusRetorno == "200") {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Sucesso na geração, verifique a página aberta',
                });

                window.open(result.objetoRetorno, '_blank');
            }
        }
        catch (err) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: err.message[0],
            });
        }
    }

    const handleChangeStatusPedido = async (idPedido, idStatus) => {
        try {
            const result = await POST_DATA(`Pedido/UpdateStatusPedido?idPedido=${idPedido}&idStatus=${idStatus}`);
            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Sucesso em alterar o status do pedido',
                });
            }
        }
        catch (err) {
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: `Ocorreu um ero ao mudar o status do pedido. \n${err.message}`,
            });
        }
    }

    const desabilitarBotoesCancelamento = pedidoDetalhe?.IdStatusPedido === 6 || pedidoDetalhe?.IdStatusPedido === 7 || pedidoDetalhe?.IdStatusPedido === 8 || pedidoDetalhe?.IdStatusPedido === 12;
    const desabilitarBotoesCancelamentoEEmPreparacao = !pedidoDetalhe?.FlgCompraPresencial && pedidoDetalhe?.IdStatusPedido < 1 || desabilitarBotoesCancelamento;
    const desabilitarBotoesReabrir = pedidoDetalhe?.IdStatusPedido < 2 || pedidoDetalhe?.IdStatusPedido === 6 || !(pedidoDetalhe?.IdStatusPedido === 7 || pedidoDetalhe?.IdStatusPedido === 8 || pedidoDetalhe?.IdStatusPedido === 12);

    return pedidoDetalhe &&
        <Styled.Container>
            <ConfirmationCancelamento title="Cancelar?" mensagem="Você tem certeza que deseja realizar o cancelamento do pedido?"
                btn1={{ title: "Não", onClick: null }}
                btn2={{
                    title: "Cancelar", onClick: async () => {
                        try {
                            await POST_DATA(`Pedido/CancelarPedidoVendedor?idPedido=${idPedido}&motivo=cancelado pelo vendedor&IdIntegracaoPagamento=${idIntegracaoPagamento}`);
                            await getDetalhesPedido(location.state.idPedido);
                            setHistoricoStatusPedido([]);
                            await getHistoricoStatusPedido(location.state.idPedido);

                            dispatch({
                                type: CONTROLEMENSAGEM_SUCESSO,
                                tipoComponente: 'alert',
                                titulo: 'Sucesso',
                                message: 'Sucesso ao cancelar o pedido',
                            });
                        } catch (error) {
                            SalvarLogSentry(error);

                            let msg = error.Message;
                            if (error?.response?.data?.mensagem)
                                msg = error.response.data.mensagem;
                            else if (error?.message)
                                msg = error.message[0];

                            dispatch({
                                type: CONTROLEMENSAGEM_ERRO,
                                tipoComponente: 'alert',
                                titulo: 'Erro',
                                message: msg,
                            });
                        }
                    }
                }}
            />

            <ConfirmationRecebimentoEntrega title="Confirmar entrega?" mensagem="Você tem certeza que deseja atualizar o status do pedido como entregue?"
                btn1={{ title: "Não", onClick: null }}
                btn2={{
                    title: "Sim", onClick: async () => {
                        try {
                            await POST_DATA(`Pedido/AlterarStatusPedido?idPedido=${idPedido}&idStatus=4`);
                            await getDetalhesPedido(location.state.idPedido);
                            await getHistoricoStatusPedido(location.state.idPedido);

                            dispatch({
                                type: CONTROLEMENSAGEM_SUCESSO,
                                tipoComponente: 'alert',
                                titulo: 'Sucesso',
                                message: 'Sucesso ao alterar o status do pedido para entregue',
                            });
                        } catch (error) {
                            SalvarLogSentry(error);
                            let msg = error.Message;

                            if (error?.response?.data?.mensagem)
                                msg = error.response.data.mensagem;
                            else if (error?.message)
                                msg = error.message[0];

                            dispatch({
                                type: CONTROLEMENSAGEM_ERRO,
                                tipoComponente: 'alert',
                                titulo: 'Erro',
                                message: msg,
                            });
                        }
                    }
                }}
            />

            <ConfirmationAlterarStatus title="Alterar status pedido?" mensagem={`Você tem certeza que deseja atualizar o status do pedido como ${(idStatus === 11 ? 'Preparando pedido' : 'Pronto para retirada')}?`}
                btn1={{ title: "Não", onClick: null }}
                btn2={{
                    title: "Sim", onClick: async () => {
                        await handleChangeStatusPedido(pedidoDetalhe.IdPedido, idStatus);
                        await getDetalhesPedido(location.state.idPedido);
                        await getHistoricoStatusPedido(location.state.idPedido);
                    }
                }}
            />

            <Box style={{ width: '100%', marginBottom: 20 }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Pedido #{idPedido}</Typography>
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: "space-between" }}>
                    <Typography></Typography>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            if (location.state?.somenteVoltar)
                                navigate(-1);
                            else
                                navigate('/pedidos', { replace: true, state: { statusId: statusId } });
                        }}
                    >
                        Fechar
                    </Button>
                </Box>
            </Box>
            <Styled.StyledCard>
                {pedidoDetalhe && historicoStatusPedido ? (
                    <Box style={{ width: '100%' }}>
                        <Box style={{ marginTop: 30 }} />
                        <Stack sx={{ padding: 2 }}>
                            <StatusSteppers
                                pedido={pedidoDetalhe}
                                historicoStatusPedido={historicoStatusPedido}
                            />
                        </Stack>
                        {!historicoStatusPedido ||
                            (historicoStatusPedido.length === 0 && (
                                <Alert severity="error" sx={{ my: 2 }}>
                                    <AlertTitle>Atenção</AlertTitle>Não foi possível recuperar o histórico de status desse pedido.
                                </Alert>
                            ))}
                    </Box>
                ) : erroLoadingPedido ? (
                    <Alert severity="error" sx={{ width: "100%", my: 2 }}>
                        <AlertTitle>Erro ao carregar pedido</AlertTitle>
                        <Stack direction="column">
                            <Typography></Typography>
                        </Stack>
                    </Alert>
                ) : (
                    <Stack
                        sx={{ width: "100%", height: "80vh", m: 2 }}
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                    >
                        <CircularProgress color="secondary" size={40} />
                        <Typography sx={{ mt: 2 }} variant="h5">
                            Carregando...
                        </Typography>
                    </Stack>
                )}
            </Styled.StyledCard>
            <Grid style={{ marginTop: 10 }} container spacing={2}>
                <Grid item xs={8}>
                    <Styled.StyledCard>
                        <Box style={{ width: '100%', padding: 12, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Produtos</Typography>
                        </Box>
                        <Box style={{ width: '100%' }}>
                            {
                                pedidoDetalhe._PedidoItens.map((item) => {
                                    return <Grid container spacing={2} style={{ marginLeft: 5, marginBottom: 12 }}>
                                        <Grid item xs={3}>
                                            <Avatar
                                                sx={{ width: 50, height: 50 }}
                                                src={item._Etiqueta._Imagem.LocalIIS}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>{item._Etiqueta._Peca.Nome}</Typography>
                                            <Typography style={{ fontSize: 10 }}>{item._EtiquetaComplemento.sTamanho}</Typography>
                                            <Typography style={{ fontSize: 10 }}>SKU: {item._Etiqueta.Sku ? item._Etiqueta.Sku : 'SEM SKU'}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span style={{ marginLeft: 10, width: '70px', padding: '6px', borderRadius: 10, color: 'white', textAlign: 'center', background: 'rgb(247, 147, 30)' }}>{item.iQuantidade} unidade(s)</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {
                                                item._Etiqueta.mPorcentoDesconto > 0 &&
                                                <Box>
                                                    <Typography style={{ textDecoration: 'line-through', fontSize: 12 }}>{(item.iQuantidade * item._Etiqueta.mValor).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                                    <Typography style={{ fontWeight: 'bold' }}>{(item.iQuantidade * (item._Etiqueta.mValor - (item._Etiqueta.mValor * (item._Etiqueta.mPorcentoDesconto / 100)))).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                                </Box>
                                            }
                                            {
                                                item._Etiqueta.mPorcentoDesconto === 0 &&
                                                <Typography style={{ fontWeight: 'bold' }}>{(item.iQuantidade * (item._Etiqueta.mValor - (item._Etiqueta.mValor * (item._Etiqueta.mPorcentoDesconto / 100)))).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                            }

                                        </Grid>
                                    </Grid>
                                })
                            }
                        </Box>
                    </Styled.StyledCard>
                </Grid>
                <Grid item xs={4}>
                    <Styled.StyledCard>
                        <Box style={{ width: '100%', padding: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 25 }}>Valor</Typography>
                            <Typography
                                style={{ fontWeight: 'bold', marginTop: 10, fontSize: 30, color: 'rgb(247, 148, 39)' }}>{pedidoDetalhe?.ValorTotal.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </Typography>
                        </Box>
                    </Styled.StyledCard>
                </Grid>
            </Grid>
            <Grid style={{ marginTop: 10 }} container spacing={2}>
                <Grid item xs={4}>
                    <Styled.StyledCard>
                        <Box style={{ width: '100%', padding: 12, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Cliente</Typography>
                            {/* <WhatsAppIcon style={{ color: 'green' }} /> */}
                        </Box>
                        <Box style={{ width: '100%', padding: 12 }}>
                            <Typography style={{ fontSize: 15 }}>{pedidoDetalhe._UsuarioComprador.Nome}</Typography>
                            <Typography style={{ fontSize: 15 }}>E-mail: {pedidoDetalhe._UsuarioComprador.Email}</Typography>
                            <Typography style={{ fontSize: 15 }}>Telefone: {pedidoDetalhe._UsuarioComprador.Celular}</Typography>
                        </Box>
                    </Styled.StyledCard>
                </Grid>
                <Grid item xs={4}>
                    <Styled.StyledCard>
                        <Box style={{ width: '100%', padding: 12, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Endereço</Typography>
                            {/* <Typography style={{ color: 'rgb(203, 107, 140)', fontWeight: 'bold' }}>Editar</Typography> */}
                        </Box>
                        <Box style={{ width: '100%', padding: 12, minHeight: 90 }}>
                            {
                                pedidoDetalhe._EnderecoUsuarioComprador &&
                                <Typography style={{ fontSize: 15 }}>{pedidoDetalhe._EnderecoUsuarioComprador?.END_Logradouro} {pedidoDetalhe._EnderecoUsuarioComprador?.END_Numero} - {pedidoDetalhe?._EnderecoUsuarioComprador?.END_Cidade} - {pedidoDetalhe?._EnderecoUsuarioComprador?.END_Estado}, {pedidoDetalhe?._EnderecoUsuarioComprador?.END_CEP}</Typography>
                            }
                            {
                                !pedidoDetalhe._EnderecoUsuarioComprador && pedidoDetalhe.FlgCompraPresencial &&
                                <Typography style={{ fontSize: 15 }}>Pedido PDV, sem dados de endereço</Typography>
                            }
                        </Box>
                    </Styled.StyledCard>
                </Grid>
                <Grid item xs={4}>
                    <Styled.StyledCard>
                        <Box style={{ width: '100%', padding: 12, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Frete</Typography>
                            <Typography style={{ color: 'rgb(203, 107, 140)', fontWeight: 'bold' }}>{pedidoDetalhe.ValorFrete.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                        </Box>
                        <Box style={{ width: '100%', padding: 12, minHeight: 90 }}>
                            <Typography style={{ fontSize: 15 }}>{pedidoDetalhe.NomeServicoEntrega ? pedidoDetalhe.NomeServicoEntrega : "Inexistente"}</Typography>
                            <Typography style={{ fontSize: 15 }}>Data de Envio: {moment(pedidoDetalhe.DataCadastro).format("DD/MM/YYYY")}</Typography>
                            <Typography style={{ fontSize: 15, color: pedidoDetalhe.DataEntregue ? 'black' : '#db402c' }}>Data de Entrega: {pedidoDetalhe.DataEntregue ? moment(pedidoDetalhe.DataEntregue).format("DD/MM/YYYY") : "-"}</Typography>
                            {
                                pedidoDetalhe?.NomeServicoEntrega !== 'À Combinar' && pedidoDetalhe.CodigoRastreio !== null &&
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button style={{ background: 'black', color: 'white', marginTop: 10 }} onClick={() => window.open(`https://app.melhorrastreio.com.br/app/correios/${pedidoDetalhe.CodigoRastreio}`, '_blank')} >Rastrear</Button>
                                    <Typography style={{ marginLeft: 10, color: 'blue', marginTop: 10 }} onClick={() => window.open(`https://app.melhorrastreio.com.br/app/correios/${pedidoDetalhe.CodigoRastreio}`, '_blank')}>{pedidoDetalhe.CodigoRastreio}</Typography>
                                </Box>
                            }
                        </Box>
                    </Styled.StyledCard>
                </Grid>
            </Grid>
            <Grid style={{ marginTop: 10 }} container spacing={2}>
                <Grid item xs={4} style={{ height: '100%' }}>
                    <Styled.StyledCard>
                        <Box style={{ padding: 10 }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontWeight: 'bold' }}>VALORES A FATURAR</Typography>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Produtos</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'gray' }}>{getQuantidadeProdutos()} un x {getValorProdutos()}</Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Frete</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'gray' }}>{pedidoDetalhe.ValorFrete?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                <Typography style={{ fontSize: 15, color: 'gray', minWidth: 230 }}>Total Descontos</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'gray' }}>{pedidoDetalhe.ValorDesconto?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                </Box>
                            </Box>
                            {
                                pedidoDetalhe._Cupom === null &&
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography style={{ fontSize: 12, color: 'gray', minWidth: 230 }}>Desconto de Produtos</Typography>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Typography style={{ fontSize: 12, color: 'gray' }}>{(pedidoDetalhe.ValorDesconto)?.toLocaleString("pt-br", { style: "currency", currency: "BRL" }).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                    </Box>
                                </Box>
                            }
                            {
                                pedidoDetalhe._Cupom !== null &&
                                <>
                                    {
                                        pedidoDetalhe._Cupom.FlgCompra &&
                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={{ fontSize: 12, color: 'gray', minWidth: 230 }}>Desconto Produtos</Typography>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                <Typography style={{ fontSize: 12, color: 'gray' }}>{(pedidoDetalhe._Cupom.FlgPorcentagem ? (valorProdutos - (valorProdutos * (pedidoDetalhe?._Cupom?.Valor || 0) / 100)) : (valorProdutos - pedidoDetalhe?._Cupom?.Valor))?.toLocaleString("pt-br", { style: "currency", currency: "BRL" }).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        !pedidoDetalhe._Cupom.FlgCompra &&
                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={{ fontSize: 12, color: 'gray', minWidth: 230 }}>Desconto de Produtos</Typography>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                <Typography style={{ fontSize: 12, color: 'gray' }}>{(pedidoDetalhe._Cupom.FlgPorcentagem ? (pedidoDetalhe.ValorDesconto - (pedidoDetalhe.ValorFrete * (pedidoDetalhe?._Cupom?.Valor || 0) / 100)) : (pedidoDetalhe.ValorDesconto - (pedidoDetalhe.ValorFrete - pedidoDetalhe?._Cupom?.Valor))?._Cupom?.Valor)?.toLocaleString("pt-br", { style: "currency", currency: "BRL" }).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                            </Box>
                                        </Box>
                                    }

                                    {
                                        !pedidoDetalhe._Cupom.FlgCompra &&
                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={{ fontSize: 12, color: 'gray', minWidth: 230 }}>Desconto de Cupom {pedidoDetalhe._Cupom !== null ? `(${pedidoDetalhe._Cupom.FlgCompra ? 'Compra' : 'Frete'})` : ""}</Typography>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                {
                                                    pedidoDetalhe._Cupom.FlgPorcentagem &&
                                                    <Typography style={{ fontSize: 12, color: 'gray' }}>{(pedidoDetalhe?._Cupom?.Valor == 100 ? pedidoDetalhe.ValorFrete : pedidoDetalhe.ValorFrete(pedidoDetalhe.ValorFrete - (pedidoDetalhe.ValorFrete * (pedidoDetalhe?._Cupom?.Valor || 0) / 100)))?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                                }
                                                {
                                                    !pedidoDetalhe._Cupom.FlgPorcentagem &&
                                                    <Typography style={{ fontSize: 12, color: 'gray' }}>{(pedidoDetalhe.ValorFrete - (pedidoDetalhe?._Cupom?.Valor || 0))?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                                }
                                            </Box>
                                        </Box>
                                    }
                                </>
                            }

                            <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray', fontWeight: 'bold' }}>Total</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'rgb(203, 107, 140)', fontWeight: 'bold' }}>{pedidoDetalhe.ValorTotal?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Styled.StyledCard>
                </Grid>

                <Grid item xs={4} style={{ height: '100%' }}>
                    <Styled.StyledCard>
                        <Box style={{ padding: 10 }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontWeight: 'bold' }}>PREVISÃO DE REPASSE</Typography>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray', minWidth: '110px' }}>Valor da venda</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'gray' }}>{pedidoDetalhe.ValorTotal?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Frete</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    {
                                        pedidoDetalhe._Cupom && !pedidoDetalhe._Cupom.FlgCompra &&
                                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography style={{ textDecoration: 'line-through', fontSize: 12, color: 'gray', fontWeight: 'bold' }}>{pedidoDetalhe.ValorFrete?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                            {
                                                pedidoDetalhe._Cupom.FlgPorcentagem &&
                                                <Typography style={{ fontSize: 14, color: 'gray' }}>{(pedidoDetalhe?._Cupom?.Valor == 100 ? 0 : pedidoDetalhe.ValorFrete(pedidoDetalhe.ValorFrete - (pedidoDetalhe.ValorFrete * (pedidoDetalhe?._Cupom?.Valor || 0) / 100)))?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                            }
                                            {
                                                !pedidoDetalhe._Cupom.FlgPorcentagem &&
                                                <Typography style={{ fontSize: 14, color: 'gray' }}>{(pedidoDetalhe.ValorFrete - (pedidoDetalhe?._Cupom?.Valor || 0))?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                            }
                                        </Box>
                                    }
                                    {
                                        pedidoDetalhe._Cupom === null &&
                                        <Typography style={{ fontSize: 15, color: 'gray' }}>{pedidoDetalhe.ValorFrete?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                    }
                                </Box>
                            </Box>
                            {/* <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Comissão</Typography> <span style={{ marginLeft: 10, width: '70px', borderRadius: 10, color: 'white', textAlign: 'center', background: 'rgb(203, 107, 140)' }}>0%</span>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'gray' }}>R$0,00</Typography>
                                </Box>
                            </Box> */}
                            <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontSize: 15, color: 'gray', fontWeight: 'bold' }}>Total</Typography>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography style={{ fontSize: 15, color: 'rgb(203, 107, 140)', fontWeight: 'bold' }}>{pedidoDetalhe.ValorTotal?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Styled.StyledCard>
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button disabled={desabilitarBotoesCancelamentoEEmPreparacao} variant='contained' style={{ maxWidth: 350 }} onClick={() => alert('Função indisponível')}>Imprimir Nota Fiscal</Button>
                    {
                        pedidoDetalhe?.NomeServicoEntrega !== 'À Combinar' &&
                        <Button disabled={desabilitarBotoesCancelamentoEEmPreparacao} variant='contained' style={{ marginTop: 5, maxWidth: 350 }} onClick={() => handleDeclaracaoConteudo(idPedido)}>Declaração de conteúdo e entrega</Button>
                    }
                    {
                        pedidoDetalhe?.NomeServicoEntrega === 'À Combinar' &&
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button disabled={desabilitarBotoesCancelamentoEEmPreparacao || pedidoDetalhe.IdStatusPedido === 11 || pedidoDetalhe.IdStatusPedido === 3} variant='contained' style={{ marginTop: 5, maxWidth: 350 }} onClick={() => {
                                setIdStatus(11);
                                getConfirmationAlterarStatus();
                            }}>Preparando pedido</Button>
                            <Button disabled={desabilitarBotoesCancelamentoEEmPreparacao || pedidoDetalhe.IdStatusPedido === 3} variant='contained' style={{ marginTop: 5, maxWidth: 350 }} onClick={() => {
                                setIdStatus(3);
                                getConfirmationAlterarStatus();
                            }}>Pronta para retirada</Button>
                        </Box>
                    }
                    <Button disabled={desabilitarBotoesCancelamentoEEmPreparacao || pedidoDetalhe.IdStatusPedido === 4} variant='contained' style={{ marginTop: 5, maxWidth: 350 }} onClick={() => handleCancelarPedidoByVendedor()}>Cancelar pedido</Button>
                    <Button disabled={desabilitarBotoesCancelamentoEEmPreparacao || pedidoDetalhe.IdStatusPedido === 4} variant='contained' style={{ marginTop: 5, maxWidth: 350 }} onClick={() => handleAlterarStatusPedidoEntregue()}>Confirmar Entrega</Button>
                    <Button disabled={desabilitarBotoesReabrir} variant='contained' style={{ marginTop: 5, maxWidth: 350 }} onClick={() => alert('Função indisponível')}>Reabrir Pedido</Button>

                    {/* <Button
                        style={{ marginRight: "1rem" }}
                        variant="outlined"
                        size="large"
                        onClick={handleClickMenuAcoes}
                        endIcon={<KeyboardArrowDown />}
                    >
                        Mais opções
                    </Button> */}
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        {
                            !pedidoDetalhe?.status?.toLowerCase().includes('cancel') &&
                            <div>
                                <MenuItem onClick={() => { handleClose(); console.log('Imprimir') }}>Imprimir</MenuItem>
                                {
                                    pedidoDetalhe?.NomeServicoEntrega !== 'À Combinar' &&
                                    <MenuItem onClick={() => handleDeclaracaoConteudo(idPedido)}>Declaração de conteúdo e entrega</MenuItem>
                                }
                                <MenuItem onClick={() => handleCancelarPedidoByVendedor()}>Cancelar pedido</MenuItem>
                                <MenuItem onClick={() => handleAlterarStatusPedidoEntregue()}>Confirmar Entrega</MenuItem>
                            </div>
                        }
                        {
                            pedidoDetalhe?.status?.includes('cancel') &&
                            <MenuItem onClick={() => { handleClose(); console.log('Reabrir pedido') }}>Reabrir pedido</MenuItem>
                        }
                    </Menu>
                </Grid>
            </Grid>
        </Styled.Container >
}

export default DetalhesPedido;
